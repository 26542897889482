import {
  Paper,
  Typography,
  TextField,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
  Collapse,
  IconButton,
  Chip,
  MenuItem,
  Select,
} from '@mui/material';

import { useState } from 'react';

import TadPageHeader from '../common/page-header/tad-page-header';

// import useNavigate from '../../../common/useNavigate';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import NewLocationAutoComplete from '../common/new-location-autocomplete';
import { useFormik } from 'formik';

const candidateSearch = () => {
  const [currentLocation, setCurrentLocation] = useState([] as any);
  const [includeCandidates, setIncludeCandidates] = useState(false);
  const [excludeCandidates, setExcludeCandidates] = useState(false);
  const [includeCandidatesCurrentSalary, setIncludeCandidatesCurrentSalary] = useState(false);
  const [currency, setCurrency] = useState('INR');
  const [showEmploymentDetails, setShowEmploymentDetails] = useState(false);
  const [showEducationDetails, setShowEducationDetails] = useState(false);
  const [showDiversityDetails, setShowDiversityDetails] = useState(false);
  const [showAdditionalDetails, setShoAdditionalDetails] = useState(false);

  const [showITSkillsOpen, setShowITSkillsOpen] = useState(false);
  const [includeExclude, setIncludeExclude] = useState(false);
  const [ppgOpen, setPpgOpen] = useState(false);
  const [excludecompanyopen, setExcludeCompanyOpen] = useState(false);
  const [, setWorkPermit] = useState([] as any);
  const [, setWorkStatus] = useState([] as any);

  const workPermitClearAll = () => {
    setWorkPermit([]); // Clear local state if you are using it for storing the selections
    formik.setFieldValue('workPermit', ''); // Clears the selected value in formik
  };
  const workStatusClearAll = () => {
    setWorkStatus([]); // Clear local state if you are using it for storing the selections
    formik.setFieldValue('workStatus', ''); // Clears the selected value in formik
  };

  const companyOptions = ['Indian MNC', 'Foreign MNC', 'Govt/PSU', 'StartUp', 'Corporate'];

  const noticePeriodOptions = [
    'Any',
    '0 - 15 Days ',
    '1 Month ',
    '2 Months ',
    '3 Months ',
    'Currently serving notice period',
  ];
  const ugQualificationOptions = [
    'AA (Associate of Arts)',
    'AS (Associate of Science)',
    'AAS (Associate of Applied Science)',
    'BA (Bachelor of Arts)',
    'BArch (Bachelor of Architecture)',
    'BBA (Bachelor of Business Administration)',
    'BCA (Bachelor of Computer Applications)',
    'BDS (Bachelor of Dental Surgery)',
    'BEd (Bachelor of Education)',
    'BE (Bachelor of Engineering)',
    'BFA (Bachelor of Fine Arts)',
    'BHM (Bachelor of Hotel Management)',
    'BMLT (Bachelor of Medical Laboratory Technology)',
    'BPharm (Bachelor of Pharmacy)',
    'BPT (Bachelor of Physiotherapy)',
    'BTech (Bachelor of Technology)',
    'BL (Bachelor of Laws)',
    'BAMS (Bachelor of Ayurvedic Medicine and Surgery)',
    'BSc (Bachelor of Science)',
    'BCom (Bachelor of Commerce)',
    'CA (Chartered Accountancy)',
    'CFA (Chartered Financial Analyst)',
    'Diploma in Engineering ',
    'Retail & E-commerce',
    'Diploma in Nursing',
    'EBBA (Executive Bachelor of Business Administration)',
    'FAD (Fashion Art and Design)',
    'GBM (Global Business Management)',
    'HBA (Honours Bachelor of Arts)',
    'HBSc (Honours Bachelor of Science)',
    'IMBA (Integrated Master of Business Administration)',
    'IIT (Indian Institutes of Technology)',
    'JMC (Journalism and Mass Communication)',
    'LLB (Bachelor of Laws)',
    'MBBS (Bachelor of Medicine, Bachelor of Surgery)',
    'BSc Nursing',
    'Pharm D (Doctor of Pharmacy)',
    'Sociology (BA Sociology)',
    'VA (Visual Arts)',
    'Other',
  ];
  const pgQualificationOptions = [
    'ACCA (Association of Chartered Certified Accountants)',
    'AIP (Advanced International Program)',
    'MBA (Master of Business Administration)',
    'MA (Master of Arts)',
    'MArch (Master of Architecture)',
    'MSc (Master of Science)',
    'MTech (Master of Technology)',
    'BEd (Bachelor of Education)',
    'CMA (Certified Management Accountant)',
    'CFA (Chartered Financial Analyst)',
    'DNB (Diplomate of National Board)',
    'DBA (Doctor of Business Administration)',
    'EDP (Executive Development Program)',
    'EMBA (Executive Master of Business Administration)',
    'FPM (Fellow Program in Management)',
    'GDBA (Graduate Diploma in Business Administration)',
    'MHA (Master of Hospital Administration)',
    'IMSc (Master of Science by Research)',
    'IMBA (Integrated Master of Business Administration)',
    'International Business Management (MBA)',
    'JD (Juris Doctor)',
    'LLM (Master of Laws)',
    'MA (Master of Arts)',
    'MCom (Master of Commerce)',
    'MFA (Master of Fine Arts)',
    'MEng (Master of Engineering)',
    'MHA (Master of Healthcare Administration)',
    'MCA (Master of Computer Applications)',
    'MIB (Master of International Business)',
    'MIM (Master in Management)',
    'MIT (Master of Information Technology)',
    'MLIS (Master of Library and Information Science)',
    'MSN (Master of Science in Nursing)',
    'Online MBA',
    'PGD (Post Graduate Diploma)',
    'PGDBM (Post Graduate Diploma in Business Management)',
    'PGDCA (Post Graduate Diploma in Computer Applications)',
    'PGDHRM (Post Graduate Diploma in Human Resource Management)',
    'PhD (Doctor of Philosophy)',
    'Pharm D (Doctor of Pharmacy)',
    'RD (Registered Dietitian)',
    'SM (Master of Science)',
    'Social Work (MSW)',
    'TFA (Master of Textile and Fashion Arts)',
    'UDM (Urban Development Management)',
    'VLSI (Very-Large-Scale Integration Design)',
    'Other',
  ];
  const ppgQualificationOptions = [
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
    'Other',
  ];
  const industryOptions = [
    'Information Technology (IT)',
    'Software Development',
    'Hardware & Networking',
    'Telecommunications',
    'Finance & Banking',
    'Healthcare & Pharmaceuticals',
    'Manufacturing',
    'Retail & E-commerce',
    'Education & Training',
    'Government & Public Sector',
    'Automobile & Engineering',
    'Energy & Utilities',
    'Media & Entertainment',
    'Construction & Real Estate',
    'Travel & Hospitality',
    'Other',
  ];
  const candidateCategoryOptions = [
    'Scheduled Caste (SC)',
    'Scheduled Tribe (ST)',
    'OBC-Creamy',
    'OBC-Non Creamy',
    'Other',
  ];
  const jobType = ['Permanent', 'Contract/Temporary'];

  const employementType = ['Any', 'Full Time', 'Part Time'];
  const workPermitOptions = ['USA', 'India'];
  const workStatusOptions = [
    'Authorized to work in US',
    'US Green Card Holder',
    'Have US H1 Visa',
    'Need US H1 Visa',
    'US TN Permit Holder',
    'US Citizen',
  ];

  const selectUgQualificationOptions = [
    'Any UG qualification',
    'Specific UG qualification',
    'No UG qualification',
  ];
  const selectPgQualificationOptions = [
    'Any PG qualification',
    'Specific PG qualification',
    'No PG qualification',
  ];
  const selectPpgQualificationOptions = [
    'Any PPG qualification',
    'Specific PPG qualification',
    'No PPG qualification',
  ];

  const selectGenderOptions = ['All Candidates', 'Female Candidates', 'Male Candidates'];

  const handleLocationSelect = (location: any | null) => {
    if (location && !currentLocation.some((loc: any) => loc === location)) {
      setCurrentLocation([...currentLocation, location]);
    }
  };

  const handleChipDelete = (locationToDelete: string) => {
    setCurrentLocation(currentLocation.filter((loc: any) => loc !== locationToDelete));
  };

  // const handleClearAll = () => {
  //   setCurrentLocation([] as any); // Reset currentLocation to empty array
  //   setIncludeCandidates(false);
  //   setExcludeCandidates(false);
  //   setIncludeCandidatesCurrentSalary(false);
  //   setCurrency('INR');
  //   setShowEmploymentDetails(false);
  //   setShowEducationDetails(false);
  //   setShowDiversityDetails(false);
  //   setShoAdditionalDetails(false);
  // };

  const formik = useFormik({
    initialValues: {
      keyword: '',
      booleanSearch: false,
      includeKeywords: '',
      excludeKeywords: '',
      minExperience: '',
      maxExperience: '',
      minSalary: '',
      maxSalary: '',
      department: '',
      companyType: [] as string[],
      companyName: '',
      booleanCompanyNameCheckbox: false,
      excludeCompany: '',
      industry: '',
      designation: '',
      selectedNoticePeriod: [] as string[],
      selectedUgQualification: '',
      specificUgQualification: '',
      selectedPgQualification: '',
      specificPgQualification: '',
      selectedPpgQualification: '',
      specificPpgQualification: '',
      gender: '',
      booleanGenderCheckbox: false,
      candidateCategory: [],
      jobType: '',
      employmentType: '',
      workPermit: '',
      workStatus: '',
    },
    onSubmit: () => {
      // setLoading(true);
      // searchCandidate(formik.values)
      //   .then((resp) => {
      //     setCandidates(resp.data);
      //     setLoading(false);
      //   })
      //   .catch((_err) => {
      //     setLoading(false);
      //   });
      // setPage(0);
    },
    validate: (_values: any) => {
      // if (
      //   values.keyword == '' &&
      //   values.booleanSearch == 0 &&
      //   values.minExperience == '' &&
      //   values.maxExperience == '' &&
      //   values.location == ''
      // ) {
      return {
        //candidateName: 'false',
      };
      //}
    },
  });

  return (
    <div className='main-div' style={{ flexDirection: 'row', gap: '2px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '0.5rem' }}>
          <TadPageHeader />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <Typography style={{ fontSize: '1.25rem', fontWeight: '600' }}>
            Candidate Search
          </Typography>
        </div>
        <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
          <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
            <div style={{ height: '100%', width: '100%' }}>
              <Paper className='paper' sx={{ width: '100%', height: '100%' }}>
                <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem' }}>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Keywords</Typography>
                      <span className='feildHeader'>
                        Enter keywords like skills, designation and company
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <TextField
                        id='keyword'
                        className='input-field'
                        fullWidth
                        value={formik.values.keyword}
                        onChange={formik.handleChange}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='booleanSearch'
                            checked={formik.values.booleanSearch}
                            onChange={formik.handleChange}
                          />
                        }
                        label='Boolean On'
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      justifyContent: 'flex-end',
                      width: '75.5%',
                    }}>
                    <Typography
                      sx={{ color: '#2D7DFE', fontWeight: 500 }}
                      onClick={() => setIncludeExclude(!includeExclude)}>
                      + Include or Exclude Keywords
                    </Typography>
                    <IconButton onClick={() => setIncludeExclude(!includeExclude)}>
                      {includeExclude ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <Collapse in={includeExclude}>
                    <div className='field-container'>
                      <div id='includeKeywords' style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Include Keywords</Typography>
                        <span className='feildHeader'>
                          Enter keywords like skills, designation and company
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          id='includeKeywords'
                          className='input-field'
                          fullWidth
                          value={formik.values.includeKeywords}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className='field-container'>
                      <div id='excludeKeywords' style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Exclude Keywords</Typography>
                        <span className='feildHeader'>
                          Enter keywords like skills, designation and company
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          id='excludeKeywords'
                          className='input-field'
                          fullWidth
                          value={formik.values.excludeKeywords}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </Collapse>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      justifyContent: 'flex-end',
                      width: '60.8%',
                      // position: 'relative',
                      // maxWidth: '100%', // Prevents it from becoming too wide
                      // minWidth: '50%',
                    }}
                    onClick={() => setShowITSkillsOpen(!showITSkillsOpen)}>
                    <Typography sx={{ color: '#2D7DFE', fontWeight: 500 }}>+ IT Skills</Typography>
                    <IconButton>{showITSkillsOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
                  </div>
                  <Collapse in={showITSkillsOpen}></Collapse>

                  <div className='field-container' style={{ marginTop: '20px' }}>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Experience Range</Typography>
                    </div>
                    <div style={{ width: '50%', display: 'flex', gap: '1rem' }}>
                      <TextField
                        id='minExperience'
                        className='input-field'
                        placeholder='Min'
                        type='number'
                        //value={minExperience}
                        //onChange={(e) => setMinExperience(e.target.value)}
                        sx={{ flex: 1 }}
                        value={formik.values.minExperience}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        id='maxExperience'
                        className='input-field'
                        placeholder='Max'
                        type='number'
                        //value={maxExperience}
                        //onChange={(e) => setMaxExperience(e.target.value)}
                        sx={{ flex: 1 }}
                        value={formik.values.maxExperience}
                        onChange={formik.handleChange}
                      />
                      <Select defaultValue='Years' sx={{ flex: 1 }}>
                        <MenuItem value='Years'>Years</MenuItem>
                        <MenuItem value='Months'>Months</MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Current Location</Typography>
                      <span className='feildHeader'>
                        Provide current location where candidate worked
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                        <NewLocationAutoComplete
                          onSelect={handleLocationSelect}
                          initialValue={currentLocation}
                          width='35.4375rem !important'
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={includeCandidates}
                                onChange={(e) => setIncludeCandidates(e.target.checked)}
                              />
                            }
                            label='Include candidates who prefers to relocate to above location.'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={excludeCandidates}
                                onChange={(e) => setExcludeCandidates(e.target.checked)}
                              />
                            }
                            label='Exclude candidates who have mentioned anywhere.'
                          />
                        </div>
                      </div>

                      {currentLocation.length > 0 &&
                        currentLocation.map((location: any, index: number) => (
                          <Chip
                            key={index}
                            label={location}
                            style={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '0.63rem',
                              marginRight: '0.5rem',
                            }}
                            onDelete={() => handleChipDelete(location)}
                          />
                        ))}
                    </div>
                  </div>

                  <div
                    className='field-container'
                    style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Salary Range</Typography>
                    </div>
                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                      <div
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          alignItems: 'center',
                          width: '100%', // Ensure container takes full width
                        }}>
                        {/* Currency Select */}
                        <Select
                          value={currency}
                          onChange={(e) => setCurrency(e.target.value)}
                          style={{
                            width: '80%', // Make it take up the full available width
                            padding: '0.15rem',
                            fontSize: '1rem',
                          }}>
                          <MenuItem value='INR'>INR</MenuItem>
                          <MenuItem value='USD'>USD</MenuItem>
                        </Select>

                        {/* Min Salary TextField */}
                        <TextField
                          id='minSalary'
                          className='input-field'
                          placeholder='Min'
                          type='number'
                          value={formik.values.minSalary}
                          onChange={formik.handleChange}
                          style={{
                            width: '100%', // Ensure same width as other inputs
                            padding: '0.35rem',
                            fontSize: '1rem',
                          }}
                        />

                        {/* Max Salary TextField */}
                        <TextField
                          id='MaxSalary'
                          className='input-field'
                          placeholder='Max'
                          type='number'
                          value={formik.values.maxSalary}
                          onChange={formik.handleChange}
                          style={{
                            width: '100%', // Ensure same width as other inputs
                            padding: '0.35rem',
                            fontSize: '1rem',
                          }}
                        />

                        {/* Frequency Select */}
                        <Select
                          defaultValue='Annually'
                          style={{
                            width: '80%', // Ensure same width as other inputs
                            padding: '0.15rem',
                            fontSize: '1rem',
                          }}>
                          <MenuItem value='Annually'>Annually</MenuItem>
                          <MenuItem value='Monthly'>Monthly</MenuItem>
                        </Select>
                      </div>

                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeCandidatesCurrentSalary}
                              onChange={(e) => setIncludeCandidatesCurrentSalary(e.target.checked)}
                            />
                          }
                          label='Include candidates who didn’t mention their current salary.'
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>

                  {/* Employment Details */}

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      // padding: '15px',
                      marginBottom: '20px',
                    }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                      Employment Details
                    </Typography>
                    <IconButton onClick={() => setShowEmploymentDetails(!showEmploymentDetails)}>
                      {showEmploymentDetails ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={showEmploymentDetails}>
                    <div className='field-container' style={{ marginTop: '20px' }}>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Department & Role</Typography>
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          id='department'
                          className='input-field'
                          fullWidth
                          value={formik.values.department}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Company Type</Typography>
                      </div>
                      <div style={{ width: '50%', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                        {companyOptions.map((option) => (
                          <button
                            id='companyType'
                            key={option}
                            onClick={() => {
                              const currentValue = formik.values.companyType || [];
                              if (currentValue.includes(option)) {
                                // If the option is already selected, deselect it
                                formik.setFieldValue(
                                  'companyType',
                                  currentValue.filter((item: any) => item !== option),
                                );
                              } else {
                                // If the option is not selected, add it
                                formik.setFieldValue('companyType', [...currentValue, option]);
                              }
                            }}
                            style={{
                              backgroundColor: formik.values.companyType?.includes(option)
                                ? '#c7e8c5' // Light green when selected
                                : '#F1F4F8', // Default color
                              color: '#333',
                              border: formik.values.companyType?.includes(option)
                                ? '1px solid #1976D2'
                                : '1px solid #868686',
                              padding: '6px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              // minWidth: '100px',
                              display: 'flex',
                              justifyContent: 'flex-start', // Change to space-between to separate the content
                              alignItems: 'center',
                              gap: '8px',
                            }}>
                            <span>{option}</span>
                            <span>{formik.values.companyType?.includes(option) ? '✔' : '+'}</span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Company Type</Typography>
                      </div>
                      <div style={{ width: '50%', display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                        {companyOptions.map((option) => (
                          <button
                            id='companyType'
                            key={option}
                            onClick={() => formik.setFieldValue('companyType', option)}
                            style={{
                              backgroundColor:
                                formik.values.companyType === option
                                  ? '#c7e8c5' // Light green when selected
                                  : '#F1F4F8', // Default color,
                              color: '#333',
                              border:
                                formik.values.companyType === option
                                  ? '1px solid #1976D2'
                                  : '1px solid #868686',
                              padding: '5px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              // fontWeight: 600,
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              minWidth: '120px',
                            }}>
                            {option}
                          </button>
                        ))}
                      </div>
                    </div> */}

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Company</Typography>
                        <span className='fieldHeader'>Enter company name</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          id='companyName'
                          className='input-field'
                          fullWidth
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='booleanCompanyNameCheckbox'
                              checked={formik.values.booleanCompanyNameCheckbox}
                              onChange={formik.handleChange}
                            />
                          }
                          label='Boolean On'
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                        width: '68%',
                        marginBottom: '15px',
                      }}>
                      <Typography
                        sx={{ color: '#2D7DFE', fontWeight: 500 }}
                        onClick={() => setExcludeCompanyOpen(!excludecompanyopen)}>
                        + Exclude Company
                      </Typography>
                      <IconButton onClick={() => setExcludeCompanyOpen(!excludecompanyopen)}>
                        {excludecompanyopen ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </div>
                    <Collapse in={excludecompanyopen}>
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>Exclude Company</Typography>
                          <span className='feildHeader'>
                            Enter keywords like skills, designation and company
                          </span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <TextField
                            id='excludeCompany'
                            className='input-field'
                            fullWidth
                            value={formik.values.excludeCompany}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </Collapse>

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Industry</Typography>
                        <span className='feildHeader'>Provide the industry name</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          name='industry'
                          id='industry'
                          style={{
                            width: '100%',
                            padding: '0.35rem',
                            fontSize: '1rem',
                            // border: '1px solid #ccc',
                            // borderRadius: '4px',
                          }}
                          value={formik.values.industry}
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}>
                          <MenuItem disabled value={''}>
                            Choose or type
                          </MenuItem>
                          {industryOptions.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Designation</Typography>
                        <span className='feildHeader'>Enter designation</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          className='input-field'
                          id='designation'
                          fullWidth
                          value={formik.values.designation}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Notice Period</Typography>
                        <span className='feildHeader'>Candidate availability to join</span>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          display: 'flex',
                          gap: '10px',
                          flexWrap: 'wrap', // Allow wrapping if necessary
                        }}>
                        {noticePeriodOptions.map((option) => (
                          <button
                            key={option}
                            onClick={() => {
                              const newSelection = formik.values.selectedNoticePeriod.includes(
                                option,
                              )
                                ? formik.values.selectedNoticePeriod.filter(
                                    (item: string) => item !== option,
                                  ) // Unselect if already selected
                                : [...formik.values.selectedNoticePeriod, option]; // Add to selection
                              formik.setFieldValue('selectedNoticePeriod', newSelection);
                            }}
                            style={{
                              backgroundColor: formik.values.selectedNoticePeriod.includes(option)
                                ? '#c7e8c5' // Light green when selected
                                : '#F1F4F8', // Default color
                              color: '#333',
                              border: formik.values.selectedNoticePeriod.includes(option)
                                ? '1px solid #1976D2'
                                : '1px solid #868686',
                              padding: '6px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              minWidth: '120px', // Prevent buttons from being too small
                              justifyContent: 'flex-start',
                              gap: '10px',
                            }}>
                            {option}
                            <span style={{ marginLeft: '10px' }}>
                              {formik.values.selectedNoticePeriod.includes(option) ? '✔' : '+'}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Notice Period</Typography>
                        <span className='feildHeader'>Candidate availability to join</span>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          display: 'flex',
                          gap: '15px',
                          flexWrap: 'wrap', // Allow wrapping if necessary
                        }}>
                        {noticePeriodOptions.map((option) => (
                          <button
                            key={option}
                            onClick={() => {
                              const newSelection = formik.values.selectedNoticePeriod.includes(
                                option,
                              )
                                ? formik.values.selectedNoticePeriod.filter(
                                    (item: string) => item !== option,
                                  ) // Unselect if already selected
                                : [...formik.values.selectedNoticePeriod, option]; // Add to selection
                              formik.setFieldValue('selectedNoticePeriod', newSelection);
                            }}
                            style={{
                              backgroundColor: formik.values.selectedNoticePeriod.includes(option)
                                ? '#c7e8c5' // Light green when selected
                                : '#F1F4F8', // Default color
                              color: '#333',
                              border: formik.values.selectedNoticePeriod.includes(option)
                                ? '1px solid #1976D2'
                                : '1px solid #868686',
                              padding: '5px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              minWidth: '120px', // Prevent buttons from being too small
                              justifyContent: 'flex-start',
                              gap: '8px',
                            }}>
                            {option}
                            {formik.values.selectedNoticePeriod.includes(option) ? '✔' : '+'}{' '}
                          </button>
                        ))}
                      </div>
                    </div> */}

                    {/* <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Notice Period</Typography>
                        <span className='feildHeader'>Candidate availability to join</span>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          display: 'flex',
                          gap: '15px',
                          flexWrap: 'wrap', // Allow wrapping if necessary
                        }}>
                        {noticePeriodOptions.map((option) => (
                          <button
                            id='selectedNoticePeriod'
                            key={option}
                            onClick={() => formik.setFieldValue('selectedNoticePeriod', option)}
                            style={{
                              backgroundColor:
                                formik.values.selectedNoticePeriod === option
                                  ? '#c7e8c5' // Light green when selected
                                  : '#F1F4F8', // Default color,
                              color: '#333',
                              border:
                                formik.values.selectedNoticePeriod === option
                                  ? '1px solid #1976D2'
                                  : '1px solid #868686',
                              padding: '5px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              minWidth: '120px', // Prevent buttons from being too small
                            }}>
                            {option}
                          </button>
                        ))}
                      </div>
                    </div> */}
                  </Collapse>

                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>

                  {/* Education Details */}

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      // padding: '15px',
                      marginBottom: '20px',
                    }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                      Education Details
                    </Typography>
                    <IconButton onClick={() => setShowEducationDetails(!showEducationDetails)}>
                      {showEducationDetails ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={showEducationDetails}>
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>UG Qualification</Typography>
                        <span>Candidate Under Graduate qualification</span>
                      </div>
                      <div style={{ width: '50%', display: 'flex', gap: '10px' }}>
                        {selectUgQualificationOptions.map((option) => (
                          <button
                            id='selectedUgQualification'
                            key={option}
                            onClick={() => formik.setFieldValue('selectedUgQualification', option)}
                            style={{
                              backgroundColor:
                                formik.values.selectedUgQualification === option
                                  ? '#c7e8c5' // Light green when selected
                                  : '#F1F4F8', // Default color
                              color: '#333',
                              border:
                                formik.values.selectedUgQualification === option
                                  ? '1px solid #1976D2'
                                  : '1px solid #868686',
                              padding: '6px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              // fontWeight: 600,
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              flex: 1,
                              textAlign: 'center',
                            }}>
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                    {formik.values.selectedUgQualification === 'Any UG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}></div>
                        <div style={{ width: '50%' }}>
                          Note:Candidates with any UG qualification will appear in the result
                        </div>
                      </div>
                    )}
                    {formik.values.selectedUgQualification === 'No UG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}></div>
                        <div style={{ width: '50%' }}>
                          Note:Candidates with no UG qualification will appear in the result
                        </div>
                      </div>
                    )}
                    {formik.values.selectedUgQualification === 'Specific UG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Specific UG qualification
                          </Typography>
                          <span className='feildHeader'>
                            Provide the specific candidate UG qualification
                          </span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Select
                            name='specificUgQualification'
                            id='specificUgQualification'
                            displayEmpty // Ensures placeholder appears when no value is selected
                            value={formik.values.specificUgQualification || ''} // Ensure value is empty if no selection
                            onChange={formik.handleChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: '190px',
                                  overflowY: 'auto',
                                },
                              },
                            }}
                            style={{
                              width: '100%',
                              padding: '0.35rem',
                              fontSize: '1rem',
                              color: formik.values.specificUgQualification ? '#000' : '#aaa', // Light color for placeholder text
                              backgroundColor: '#fff',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                            }}>
                            {/* Do not include "Select Qualification" as an option in the dropdown */}
                            <MenuItem value='' disabled style={{ display: 'none' }}>
                              Select UG Qualification
                            </MenuItem>
                            {/* Actual selectable options */}
                            {ugQualificationOptions.map((reason: any) => (
                              <MenuItem key={reason} value={reason}>
                                {reason}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    )}

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>PG Qualification</Typography>
                        <span>Candidate Post Graduate qualification</span>
                      </div>
                      <div style={{ width: '50%', display: 'flex', gap: '10px' }}>
                        {selectPgQualificationOptions.map((option) => (
                          <button
                            key={option}
                            id='selectedPgQualification'
                            onClick={() => formik.setFieldValue('selectedPgQualification', option)}
                            style={{
                              backgroundColor:
                                formik.values.selectedPgQualification === option
                                  ? '#c7e8c5' // Light green when selected
                                  : '#F1F4F8', // Default color,
                              color: '#333',
                              border:
                                formik.values.selectedPgQualification === option
                                  ? '1px solid #1976D2'
                                  : '1px solid #868686',
                              padding: '6px',
                              borderRadius: '2.3125rem',
                              textDecoration: 'none',
                              fontSize: '0.8rem',
                              // fontWeight: 600,
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                              flex: 1,
                              textAlign: 'center',
                            }}>
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                    {formik.values.selectedPgQualification === 'Any PG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}></div>
                        <div style={{ width: '50%' }}>
                          Note:Candidates with any PG qualification will appear in the result
                        </div>
                      </div>
                    )}
                    {formik.values.selectedPgQualification === 'No PG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}></div>
                        <div style={{ width: '50%' }}>
                          Note:Candidates with no PG qualification will appear in the result
                        </div>
                      </div>
                    )}
                    {formik.values.selectedPgQualification === 'Specific PG qualification' && (
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Specific PG qualification
                          </Typography>
                          <span className='feildHeader'>
                            Provide the specific candidate PG qualification
                          </span>
                        </div>
                        <div style={{ width: '50%' }}>
                          <Select
                            name='specificPgQualification'
                            id='specificPgQualification'
                            displayEmpty // Ensures placeholder appears when no value is selected
                            value={formik.values.specificPgQualification || ''} // Ensure value is empty if no selection
                            onChange={formik.handleChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: '190px',
                                  overflowY: 'auto',
                                },
                              },
                            }}
                            style={{
                              width: '100%',
                              padding: '0.35rem',
                              fontSize: '1rem',
                              color: formik.values.specificPgQualification ? '#000' : '#aaa', // Light color for placeholder text
                              backgroundColor: '#fff',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                            }}>
                            {/* Do not include "Select Qualification" as an option in the dropdown */}
                            <MenuItem value='' disabled style={{ display: 'none' }}>
                              Select PG Qualification
                            </MenuItem>
                            {/* Actual selectable options */}
                            {pgQualificationOptions.map((reason: any) => (
                              <MenuItem key={reason} value={reason}>
                                {reason}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                        width: '68%',
                      }}>
                      <Typography
                        sx={{ color: '#2D7DFE', fontWeight: 500 }}
                        onClick={() => setPpgOpen(!ppgOpen)}>
                        + PPG Qualification
                      </Typography>
                      <IconButton onClick={() => setPpgOpen(!ppgOpen)}>
                        {ppgOpen ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </div>

                    <Collapse in={ppgOpen}>
                      <div className='field-container'>
                        <div style={{ width: '50%' }}>
                          <Typography sx={{ fontWeight: 600 }}>PPG Qualification</Typography>
                          <span>Candidate Pre-Primary Graduate qualification</span>
                        </div>
                        <div style={{ width: '50%', display: 'flex', gap: '10px' }}>
                          {selectPpgQualificationOptions.map((option) => (
                            <button
                              id='selectedPpgQualification'
                              key={option}
                              onClick={() =>
                                formik.setFieldValue('selectedPpgQualification', option)
                              }
                              style={{
                                backgroundColor:
                                  formik.values.selectedPpgQualification === option
                                    ? '#c7e8c5' // Light green when selected
                                    : '#F1F4F8', // Default color,
                                color: '#333',
                                border:
                                  formik.values.selectedPpgQualification === option
                                    ? '1px solid #1976D2'
                                    : '1px solid #868686',
                                padding: '6px',
                                borderRadius: '2.3125rem',
                                textDecoration: 'none',
                                fontSize: '0.8rem',
                                // fontWeight: 600,
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                flex: 1,
                                textAlign: 'center',
                              }}>
                              {option}
                            </button>
                          ))}
                        </div>
                      </div>
                      {formik.values.selectedPpgQualification === 'Any PPG qualification' && (
                        <div className='field-container'>
                          <div style={{ width: '50%' }}></div>
                          <div style={{ width: '50%' }}>
                            Note:Candidates with any PPG qualification will appear in the result
                          </div>
                        </div>
                      )}
                      {formik.values.selectedPpgQualification === 'No PPG qualification' && (
                        <div className='field-container'>
                          <div style={{ width: '50%' }}></div>
                          <div style={{ width: '50%' }}>
                            Note:Candidates with no PPG qualification will appear in the result
                          </div>
                        </div>
                      )}
                      {formik.values.selectedPpgQualification === 'Specific PPG qualification' && (
                        <div className='field-container'>
                          <div style={{ width: '50%' }}>
                            <Typography sx={{ fontWeight: 600 }}>
                              Specific PPG qualification
                            </Typography>
                            <span className='feildHeader'>
                              Provide the specific candidate PPG qualification
                            </span>
                          </div>
                          <div style={{ width: '50%' }}>
                            <Select
                              name='specificPpgQualification'
                              id='specificPpgQualification'
                              displayEmpty // Ensures placeholder appears when no value is selected
                              value={formik.values.specificPpgQualification || ''} // Ensure value is empty if no selection
                              onChange={formik.handleChange}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '190px',
                                    overflowY: 'auto',
                                  },
                                },
                              }}
                              style={{
                                width: '100%',
                                padding: '0.35rem',
                                fontSize: '1rem',
                                color: formik.values.specificPpgQualification ? '#000' : '#aaa', // Light color for placeholder text
                                backgroundColor: '#fff',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                              }}>
                              {/* This MenuItem acts as the placeholder */}
                              <MenuItem value='' disabled style={{ display: 'none' }}>
                                Select PPG Qualification
                              </MenuItem>
                              {ppgQualificationOptions.map((reason: any) => (
                                <MenuItem key={reason} value={reason}>
                                  {reason}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      )}
                    </Collapse>
                  </Collapse>

                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      // padding: '15px',
                      marginBottom: '20px',
                    }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                      Diversity Details
                    </Typography>
                    <IconButton onClick={() => setShowDiversityDetails(!showDiversityDetails)}>
                      {showDiversityDetails ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={showDiversityDetails}>
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Gender</Typography>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <div style={{ display: 'flex', gap: '15px' }}>
                          {selectGenderOptions.map((option) => (
                            <button
                              id='gender'
                              name='gender'
                              key={option}
                              onClick={() => formik.setFieldValue('gender', option)}
                              style={{
                                backgroundColor:
                                  formik.values.gender === option
                                    ? '#c7e8c5' // Light green when selected
                                    : '#F1F4F8', // Default color,
                                color: '#333',
                                border:
                                  formik.values.gender === option
                                    ? '1px solid #1976D2'
                                    : '1px solid #868686',
                                padding: '6px',
                                borderRadius: '2.3125rem',
                                textDecoration: 'none',
                                fontSize: '0.8rem',
                                fontWeight: 400,
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                flex: 1,
                              }}>
                              {option}
                            </button>
                          ))}
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id='booleanGenderCheckbox'
                                checked={formik.values.booleanGenderCheckbox}
                                onChange={formik.handleChange}
                              />
                            }
                            label='Persons with disabilities only'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Candidate Category</Typography>
                        <span className='feildHeader'>Provide the candidate caste information</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          name='candidateCategory'
                          id='candidateCategory'
                          displayEmpty // Ensures placeholder appears when no value is selected
                          value={formik.values.candidateCategory || ''} // Ensure value is empty if no selection
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}
                          style={{
                            width: '100%',
                            padding: '0.35rem',
                            fontSize: '1rem',
                            color: formik.values.candidateCategory ? '#000' : '#aaa', // Light color for placeholder text
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}>
                          {/* Do not include "Choose or type" as an option in the dropdown */}
                          <MenuItem value='' disabled style={{ display: 'none' }}>
                            Choose Category
                          </MenuItem>

                          {/* Actual selectable options */}
                          {candidateCategoryOptions.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {/* <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Candidate Category</Typography>
                        <span className='feildHeader'>Provide the candidate caste information</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          name='candidateCategory'
                          id='candidateCategory'
                          style={{
                            width: '100%',
                            padding: '0.35rem',
                            fontSize: '1rem',
                          }}
                          value={formik.values.candidateCategory}
                          onChange={() => formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}>
                          <MenuItem disabled value={''}>
                            Choose or type
                          </MenuItem>
                          {candidateCategoryOptions.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div> */}
                  </Collapse>

                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
                      Addtional Details
                    </Typography>
                    <IconButton onClick={() => setShoAdditionalDetails(!showAdditionalDetails)}>
                      {showAdditionalDetails ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <Collapse in={showAdditionalDetails}>
                    <div
                      className='field-container'
                      style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Show Candidate seeking</Typography>
                      </div>

                      {/* First Select Field */}
                      <div style={{ width: '25%' }}>
                        <Select
                          name='jobType'
                          id='jobType'
                          displayEmpty // Ensures the placeholder text is shown when no value is selected
                          style={{
                            width: '100%', // Make it take the full width of the container
                            padding: '0.35rem',
                            fontSize: '1rem',
                            color: formik.values.jobType ? '#000' : '#aaa', // Light color for placeholder text
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}
                          value={formik.values.jobType || ''} // Ensure value is empty if no selection
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}>
                          {/* Placeholder for empty selection */}
                          <MenuItem value='' disabled>
                            Job Type
                          </MenuItem>

                          {/* Actual selectable options */}
                          {jobType.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>

                      {/* Second Select Field */}
                      <div style={{ width: '25%' }}>
                        <Select
                          name='employmentType'
                          id='employmentType'
                          displayEmpty // Ensures the placeholder text is shown when no value is selected
                          style={{
                            width: '100%', // Make it take the full width of the container
                            padding: '0.35rem',
                            fontSize: '1rem',
                            color: formik.values.employmentType ? '#000' : '#aaa', // Light color for placeholder text
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}
                          value={formik.values.employmentType || ''} // Ensure value is empty if no selection
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}>
                          {/* Placeholder for empty selection */}
                          <MenuItem value='' disabled>
                            Employement Type
                          </MenuItem>

                          {/* Actual selectable options */}
                          {employementType.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Work Permit for US</Typography>
                        <span className='feildHeader'>
                          Provide the work permit details for the country
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          name='workPermit'
                          id='workPermit'
                          displayEmpty
                          value={formik.values.workPermit || ''}
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}
                          style={{
                            width: '100%',
                            padding: '0.35rem',
                            fontSize: '1rem',
                            color: formik.values.workPermit ? '#000' : '#aaa', // Light color for placeholder text
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}>
                          <MenuItem value='' disabled style={{ display: 'none' }}>
                            Choose Category
                          </MenuItem>
                          {workPermitOptions.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                        <div
                          onClick={workPermitClearAll} // Trigger the function to clear the input
                          style={{
                            color: '#2D7DFE',
                            textDecoration: 'underline', // Underline style
                            cursor: 'pointer', // Pointer cursor to indicate it's clickable
                            marginTop: '0.5rem', // Adds space between the chips and the Clear All option
                          }}>
                          Clear All
                        </div>
                      </div>
                    </div>
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Work Status for US</Typography>
                        <span className='feildHeader'>Provide the work athorisation details</span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          name='workStatus'
                          id='workStatus'
                          displayEmpty
                          value={formik.values.workStatus || ''}
                          onChange={formik.handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '190px',
                                overflowY: 'auto',
                              },
                            },
                          }}
                          style={{
                            width: '100%',
                            padding: '0.35rem',
                            fontSize: '1rem',
                            color: formik.values.workStatus ? '#000' : '#aaa', // Light color for placeholder text
                            backgroundColor: '#fff',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                          }}>
                          <MenuItem value='' disabled style={{ display: 'none' }}>
                            Authorize to work in US,Have H1B Visa
                          </MenuItem>
                          {workStatusOptions.map((reason: any) => (
                            <MenuItem key={reason} value={reason}>
                              {reason}
                            </MenuItem>
                          ))}
                        </Select>
                        <div
                          onClick={workStatusClearAll} // Trigger the function to clear the input
                          style={{
                            color: '#2D7DFE',
                            textDecoration: 'underline', // Underline style
                            cursor: 'pointer', // Pointer cursor to indicate it's clickable
                            marginTop: '0.5rem', // Adds space between the chips and the Clear All option
                          }}>
                          Clear All
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <div
                    style={{
                      display: 'flex',
                      gap: '6.56rem',
                      alignItems: 'center',
                      marginBottom: '3.31rem',
                      justifyContent: 'flex-end',
                    }}>
                    <div style={{ gap: '20px', display: 'flex' }}>
                      <Button
                        className='button'
                        onClick={() => {
                          //  handleClearAll();
                          formik.resetForm();
                        }}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '10vw',
                            md: '7.375rem',
                          },
                          minWidth: '7.375rem',
                          height: '2.5rem',
                          borderRadius: '5px',
                          border: '1px solid #2D7DFE',
                          backgroundColor: '#FFF',
                          color: 'black',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: 400,
                        }}>
                        Clear All
                      </Button>

                      <Button
                        className='button'
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '10vw',
                            md: '10.375rem',
                          },
                          textTransform: 'none',
                          fontWeight: 400,
                          fontSize: '1rem',
                          minWidth: '7.375rem',
                          height: '2.5rem',
                          marginRight: '10px',
                          color: 'white !important',
                          background: '#2D7DFE !important',
                        }}>
                        Search Candidates
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '20%', // 20% width for Recent Searches section
          flexShrink: 0,
          // position: 'relative',
          marginTop: '98px',
        }}>
        <Paper sx={{ padding: '1.5rem', height: '95.7%', boxShadow: 3 }}>
          <Typography sx={{ fontWeight: 600, marginBottom: '1rem' }}>Recent Searches</Typography>
          <span style={{ color: '#333' }}>
            Graphic Design, Figma, Illustrator, Adobe, Photoshop |0-1 years |0.50-2.0 lacs | Andhra
            Pradesh
          </span>
          <br />
          <br />
          <span
            style={{
              color: '#1976D2',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            Candidate Search
          </span>
          <br />
          <br />
          <span style={{ color: '#333' }}>
            &ldquo;End To End Recruitment&rdquo;, &ldquo;Non It Recruiter&rdquo;|&ldquo;Non IT
            Recruitment&rdquo;| 3-7 years | 2.50-4.50 Lacs|
          </span>
          <br />
          <br />
          <span
            style={{
              color: '#1976D2',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            Candidate Search
          </span>
          <br />
          <br />
          <span style={{ color: '#333' }}>
            &ldquo;NON IT&rdquo;,Non Technical|&ldquo;Non It Recruitment&rdquo;| 3-7 years
            |&lt;=4.50 Lacs|Bengaluru
          </span>
          <br />
          <br />
          <span
            style={{
              color: '#1976D2',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            Candidate Search
          </span>
          <br />
          <br />
          <span style={{ color: '#333' }}>Sales|marketing|0-0 years|0-2.70 Lacs|Hyderabad</span>
          <br />
          <br />
          <span
            style={{
              color: '#1976D2',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            Candidate Search
          </span>
        </Paper>
      </div>
    </div>
  );
};

export default candidateSearch;
