import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import globalObject from '../../../common/global-variables';
import React, { useEffect, useState } from 'react';
import {
  getInitials,
  isPermissions,
  isUserRole,
  isUserRoleIn,
  redirectToLogin,
  toUpperFirstChar,
} from '../../../common/commonUtil';
import './app-header.scss';
import { MODULE, ROLES } from '../../../constants/common';
import useNavigate from '../../../common/useNavigate';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import NotificationBanner from '../notification-banner';
import { getNotification } from '../../../service/dashboardService';
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';

const AppHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [parentPath, setParentPath] = React.useState('');
  const [msg, setMsg] = useState('');
  const [switchTad, setSwitchTad] = useState(false);
  const [dialogOpen, setDialogOPen] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeUserMenu = () => {
    setAnchorEl(null);
  };
  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshtoken');
    redirect('/login');
  };
  const getRoles = (): string => {
    return globalObject.userObject?.roles
      ?.map((role: any): string => toUpperFirstChar(role.name))
      .join(' | ');
  };
  const [navigate, redirect] = useNavigate();

  const tabs = [
    {
      label: 'Dashboard',
      link: '/dashboard',
      module: MODULE.DASHBOARD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
    },
    {
      label: 'Job Definitions',
      link: '/source-job-definition',
      module: MODULE.SJD,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'Questionnaire',
      link: '/questionnaire',
      module: MODULE.QUESTIONNAIRE,
      roles: [ROLES.ADMIN, ROLES.LEAD],
    },
    {
      label: 'Reporting',
      link: '/reporting',
      module: MODULE.REPORTING,
      roles: [ROLES.ADMIN, ROLES.LEAD],
    },
    {
      label: 'Candidate Search',
      link: '/candidate-search',
      module: MODULE.CANDIDATE_SEARCH,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'Employee Referral',
      link: '/employee-referral',
      module: MODULE.EMPLOYEE_REFERRAL,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER, ROLES.EVALUATOR],
    },
    {
      label: 'Applicants',
      link: '/applicants',
      module: MODULE.APPLICANTS,
      roles: [ROLES.ADMIN, ROLES.LEAD, ROLES.RECRUITER],
    },
    {
      label: 'LMS',
      link: '/lms',
      module: MODULE.LMS,
      roles: [ROLES.INTERN, ROLES.MANAGER, ROLES.ADMIN],
    },
  ];

  const filteredTabs = tabs.filter((tab) => isPermissions(tab.module));
  console.log(
    filteredTabs,
    tabs.filter((tab) => tab.module),
  );

  const handleMenuClick = (tab: any) => {
    navigate(
      [
        {
          ...tab,
        },
      ],
      true,
    );
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(
        [
          isUserRoleIn([ROLES.INTERN, ROLES.MANAGER])
            ? {
                label: 'LMS',
                link: '/lms',
              }
            : {
                label: `${filteredTabs[0].label}`,
                link: `${filteredTabs[0].link}`,
              },
        ],
        true,
      );
    }
    getNotification()
      .then((resp) => {
        const response = resp.data.map((data: any) => data.message);
        setStringMsg(response);
      })
      .catch((_err) => setMsg('Something went wrong'));
  }, []);

  useEffect(() => {
    getParentPath(location.pathname);
  }, [location.pathname]);

  function getParentPath(path: string) {
    const parent = path.slice(1).split('/')[0];
    setParentPath((_prev) => `/${parent}`);
  }

  const setStringMsg = (text: any[]) => {
    setMsg(
      text
        .map((message: any) => {
          return `${message}${'&nbsp;'.repeat(20)}`;
        })
        .join(''),
    );
  };

  const handleNotification = () => {
    setMsg('');
  };

  const handleSwitch = (val: any) => {
    localStorage.setItem('switch', val);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      redirectToLogin();
    }, 100);
  };

  const allowedUsers = [
    { email: 'harshini.doddipelly@peopletech.com' },
    { email: 'ramakrishna.pothuraju@peopletech.com' },
    { email: 'naresh.jenne@peopletech.com' },
    { email: 'vidyasree.b@peopletech.com' },
    { email: 'ganesh.gandam@peopletech.com' },
    { email: 'priyanka.erla@peopletech.com' },
    { email: 'renuka.bogi@peopletech.com' },
    { email: 'vidyalakshmi.tedlapu@peopletech.com' },
    { email: 'sravanthi.gogula@peopletech.com' },
    { email: 'bhagavathi.sharma@peopletech.com' },
    { email: 'raviteja.yenagandula@peopletech.com' },
    { email: 'namrutha.pilli@peopletech.com' },
    { email: 'raushan.k@peopletech.com' },
    { email: 'kiranmai.koppisetti@peopletech.com' },
    { email: 'annie.sheeba@peopletech.com' },
    { email: 'paladi.bharath@peopletech.com' },
    { email: 'samad.abdul@peopletech.com' },
    { email: 'geetha.chalamalla@peopletech.com' },
    { email: 'pathan.jani@peopletech.com' },
    { email: 'manideep.mathurthi@peopletech.com' },
    { email: 'deepika.sharma@peopletech.com' },
    { email: 'hari.chandana@peopletech.com' },
    { email: 'kusuma.kumari@peopletech.com' },
    { email: 'prasanna.sama@peopletech.com' },
    { email: 'lavanya.elupula@peopletech.com' },
    { email: 'surajkumar.prasad@peopletech.com' },
    { email: 'akshaya.kukatla@peopletech.com' },
    { email: 'vyshnavi.moota@peopletech.com' },
    { email: 'vijay.kogilathota@peopletech.com' },
    { email: 'gajanan.choudhary@peopletech.com' },
    { email: 'karan.chilka@peopletech.com' },
    { email: 'avinash.cheppala@peopletech.com' },
    { email: 'priyanka.guduri@peopletech.com' },
    { email: 'divya.madduri@peopletech.com' },
    { email: 'koudi.srihari@peopletech.com' },
    { email: 'chandra.thanniru@peopletech.com' },
  ];

  const isUserAllowed = allowedUsers.some((user) => user.email === globalObject.userObject.email);

  return (
    <>
      {msg !== '' && msg !== undefined && !isUserRole(ROLES.EVALUATOR, true) && (
        <div style={{ display: 'flex', height: '19px' }}>
          <div style={{ width: '100%' }}>
            <NotificationBanner msg={msg}></NotificationBanner>
          </div>
          <div>
            <CloseIcon
              fontSize='small'
              sx={{
                backgroundColor: 'rgb(0,0,0,.7)',
                color: 'white',
                height: '19px',
                cursor: 'pointer',
              }}
              onClick={handleNotification}
            />
          </div>
        </div>
      )}
      <div className='nav-bar' id='nav-bar'>
        <div className='left-div'>
          <a href={`${filteredTabs[0].link}`} className='logo-anchor'>
            <div className='logo'>
              {/* <img className='icon' src={require('../../../assets/logo.png')} alt='logo-img' /> */}
              TAD
            </div>
          </a>
          {filteredTabs.map(
            (tab, index) =>
              // (!tab.roles || isUserRoleIn(tab.roles)) && (
              isPermissions(tab.module) && (
                <div
                  key={index}
                  className={
                    parentPath == tab.link
                      ? 'nav-menu-item nav-menu-item-selected'
                      : 'nav-menu-item'
                  }
                  onClick={() => handleMenuClick(tab)}>
                  <a>{tab.label}</a>
                </div>
              ),
          )}
        </div>
        <div className='right-div'>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            {isUserAllowed && (
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 'small', color: 'white' }}>
                    Try the new TAD
                  </Typography>
                  <Switch
                    size='small'
                    className='switch'
                    checked={switchTad}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={() => {
                      setDialogOPen(true);
                    }}
                    icon={<ToggleOff />}
                    checkedIcon={<ToggleOn />}
                  />

                  <Dialog
                    open={dialogOpen}
                    fullWidth
                    maxWidth='sm'
                    sx={{
                      '& .MuiDialog-paper': {
                        width: '29.375rem',
                        height: '13.25rem',
                        borderRadius: '0.3125rem',
                        border: '1px solid var(--Primary-Dark-grey-500, #333)',
                        background: 'var(--Secondary-colors-White, #FFF)',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                      },
                    }}>
                    <div style={{ margin: '1.25rem 1.25rem 1.87rem 1.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '1.37rem',
                          justifyContent: 'space-between',
                        }}>
                        <InfoIcon />
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: 'var(--Font-size-P, 1rem)',
                            width: '23.125rem',
                          }}>
                          {`By selecting "Try the new TAD," you will be logged out of the current version.
                You'll need to log in again to access the new version, and you can switch back to
                the previous version at any time.`}
                        </span>
                        <CloseIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => setDialogOPen(false)}
                        />
                      </div>
                      <div style={{ gap: '20px', display: 'flex', justifyContent: 'center' }}>
                        <Button
                          className='button'
                          onClick={() => {
                            setDialogOPen(false);
                            setSwitchTad(false);
                          }}
                          sx={{
                            width: '7.375rem',
                            height: '2.5rem',
                            borderRadius: '5px',
                            border: '1px solid #DC3857',
                            backgroundColor: '#FFF',
                            color: 'black',
                            textTransform: 'none',
                            fontSize: '1rem',
                            fontWeight: 400,
                          }}>
                          Cancel
                        </Button>
                        <Button
                          className='button'
                          onClick={() => {
                            setDialogOPen(false);
                            setSwitchTad(true);
                            handleSwitch(true);
                          }}
                          sx={{
                            textTransform: 'none',
                            fontWeight: 400,
                            fontSize: '1rem',
                            width: '7.375rem',
                            height: '2.5rem',
                            marginRight: '10px',
                            color: 'white !important',
                            background: '#DC3857 !important',
                          }}>
                          Switch
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            )}
            {loading && (
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}>
                <CircularProgress color='inherit' />
              </Backdrop>
            )}
            <div className='user-name'>
              <p>
                {globalObject.userObject.firstName} {globalObject.userObject.lastName}
              </p>
              <p className='role'>{getRoles()}</p>
            </div>
          </div>
          <div className='avatar'>
            <div className='user-icon' onClick={handleUserMenu}>
              {getInitials(globalObject.userObject)}
            </div>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={closeUserMenu}
              disableAutoFocusItem>
              <MenuItem
                onClick={() => {
                  redirect('/settings');
                  closeUserMenu();
                }}>
                <SettingsIcon sx={{ paddingRight: '10px' }} />
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <PowerSettingsNewIcon sx={{ paddingRight: '10px' }} />
                Sign out
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
