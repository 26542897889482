import { InputLabel, Avatar, Button, Link, Chip } from '@mui/material';
import { useMemo, useState } from 'react';
import { getNameFromId, parseLocation } from '../../common/commonUtil';
import { LookupTypes } from '../../../common/lookupTypes';
import globalObject from '../../../common/global-variables';
import ContactCard from '../common/contact-card';
import { lightBlue } from '@mui/material/colors';
import { getInitials } from '../../../common/commonUtil';
import useNavigate from '../../../common/useNavigate';

interface Props {
  interview: any;
}

const NotificationBar = ({ interview }: Props) => {
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState<any>(null);
  const [navigate] = useNavigate();

  const formatTime = (timestamp: number): string =>
    new Date(timestamp).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

  const getUser = (id: any) => globalObject.users.find((obj: any) => obj.id === id) || {};

  const hiringManager = useMemo(
    () => getUser(interview?.hiringManager),
    [interview?.hiringManager],
  );
  const evaluator = useMemo(() => getUser(interview?.evaluatorId), [interview?.evaluatorId]);
  const recruiter = useMemo(() => getUser(interview?.createdById), [interview?.createdById]);

  return (
    <div className='paper'>
      <div style={{ padding: '1.5rem 1rem' }}>
        <div className='notification-header'>
          <span style={{ fontWeight: 600 }}>Upcoming Interviews</span>
          <Chip className='notification-chip' label={interview.interviewLevel} />
        </div>
        <div className='notification-container'>
          <div className='notification-field'>
            <Link
              onClick={() => {
                navigate([
                  {
                    label: interview.candidateName,
                    link: `/candidate/${btoa(interview.sjdId)}/${btoa(interview.candidateId)}`,
                  },
                ]);
              }}
              className='notification-field-label'
              sx={{ cursor: 'pointer', color: '#1976d2 !important' }}>
              {interview?.candidateName || '-'}
            </Link>
            <span>Developer</span>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Time</InputLabel>
            <span>
              {interview?.startDate
                ? `${formatTime(interview.startDate)} - ${formatTime(interview.endDate)}`
                : '-'}
            </span>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Company</InputLabel>
            <span>{getNameFromId(interview?.company, LookupTypes.TAD_CLIENT)}</span>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Job Location</InputLabel>
            <span>{parseLocation(interview?.jobLocation)?.join(', ')}</span>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Hiring Manager</InputLabel>
            <Avatar
              sx={{ bgcolor: lightBlue[700] }}
              className='notification-Avatar'
              onClick={(e) => {
                setContact(hiringManager);
                setContactAnchor(e.currentTarget);
              }}
              src={hiringManager?.profilePicture || undefined}>
              <span className='notification-Avatar-text'>
                {!hiringManager?.profilePicture && getInitials(recruiter)}
              </span>
            </Avatar>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Hiring for</InputLabel>
            <Link
              onClick={() => {
                navigate([
                  {
                    label: interview.sjdName,
                    link: `/job-definition-details/${btoa(interview.sjdId)}/${btoa('false')}`,
                  },
                ]);
              }}
              sx={{ cursor: 'pointer', color: '#1976d2 !important' }}>
              {interview.sjdName}
            </Link>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Mode of Interview</InputLabel>
            <span>
              {interview?.interviewType === true
                ? 'Online(Teams)'
                : interview?.interviewType === false
                ? 'In Person'
                : '-'}
            </span>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Interviewer</InputLabel>
            <Avatar
              sx={{ bgcolor: lightBlue[700] }}
              className='notification-Avatar'
              onClick={(e) => {
                setContact(evaluator);
                setContactAnchor(e.currentTarget);
              }}
              src={evaluator?.profilePicture || undefined}>
              <span className='notification-Avatar-text'>
                {!evaluator?.profilePicture && getInitials(evaluator)}
              </span>
            </Avatar>
          </div>
          <div className='notification-field'>
            <InputLabel className='notification-field-label'>Recruiters</InputLabel>
            <Avatar
              sx={{ bgcolor: lightBlue[700] }}
              className='notification-Avatar'
              onClick={(e) => {
                setContact(recruiter);
                setContactAnchor(e.currentTarget);
              }}
              src={recruiter?.profilePicture || undefined}>
              <span className='notification-Avatar-text'>
                {!recruiter?.profilePicture && getInitials(recruiter)}
              </span>
            </Avatar>
          </div>
          <div className='notification-button-group'>
            <Button
              className='left-button'
              onClick={() => {
                navigate([
                  {
                    label: interview.candidateName,
                    link: `/candidate/${btoa(interview.sjdId)}/${btoa(interview.candidateId)}`,
                  },
                ]);
              }}>
              View Profile
            </Button>
            <Button
              className='right-button'
              onClick={() => {
                window.open(interview.meetingLink);
              }}>
              Join Interview
            </Button>
          </div>
        </div>
      </div>
      <ContactCard
        contact={contact}
        anchorEl={contactAnchor}
        onClose={() => {
          setContactAnchor(null);
          setTimeout(() => setContact(null), 400);
        }}
      />
    </div>
  );
};

export default NotificationBar;
