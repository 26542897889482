import React, { useEffect, useRef, useState } from 'react';
// import PageHeader from '../../../../components/common/page-header/page-header';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as DoneIcon } from './../../../assets/common-assets/Vector.svg';
import UploadResume from './upload-resume';
import { BreadCrumbConfig, getNameFromId, mapSkills } from '../../../../common/commonUtil';
import { parseResume } from '../../../../service/candidateService';
import Notify from '../../../common/notify';
import { getSkills } from '../../../../service/lookupService';
import globalObject from '../../../../common/global-variables';
import { LookupTypes } from '../../../../common/lookupTypes';
import CandidateDetails from './candidate-details';
import EmploymentDetails from './employment-details';
import EducationCertificationDetails from './education-certification-details';
import AdditionalDetails from './additional-details';
import { TadCandidateDetailsModel } from '../../../models/tad-candidate-form-model';
import TadPageHeader from '../../common/page-header/tad-page-header';
import { CANDIDATE_SOURCE } from '../../../../constants/common';
import { tadAddCandidate } from '../../../service/tadCandidateService';
import useNavigate from '../../../../common/useNavigate';
// import { ReactComponent as CloudUploadIcon } from '../../../assets/candidate-assets/cloud_upload.svg';
import { ReactComponent as GroupPeople } from '../../../assets/candidate-assets/Group_People.svg';
import { ReactComponent as Single } from '../../../assets/candidate-assets/Single.svg';
import { ReactComponent as Group } from '../../../assets/candidate-assets/Group_People1.svg';
import { ReactComponent as SingleIcon } from '../../../assets/candidate-assets/Single1.svg';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import axiosClient from '../../../../common/axiosClient';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import TadScreenLoader from '../../common/tad-screen-loader';

const AddCandidates = () => {
  const [candidateDetails, setCandidateDetails] = useState({
    operations: globalObject.userObject.operations,
  } as TadCandidateDetailsModel);
  const [skills, setSkills] = useState([] as any[]);
  const [activeStep, setActiveStep] = useState(1);
  const [file, setFile] = useState<File | null>(null);
  type AlertColor = 'success' | 'info' | 'warning' | 'error';
  const [showOverlay, setShowOverlay] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [finalData, setFinalData] = useState({});
  const [showFeedbackAlert, setShowFeedbackAlert] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [navigate] = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState('Candidate Resume'); // Default selection
  const [uploadMessage, setUploadMessage] = useState({
    title: 'Upload Resume',
    description: 'You can upload single resume at a time.',
  });
  useEffect(() => {
    getSkills()
      .then((resp) => setSkills(resp.data))
      .catch((_err) => setSkills([]));
  }, []);

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  // const handleMenuClick = (event: any) => {
  //   setAnchorEl(event.target.value);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  const handleMenuSelect = (option: string) => {
    if (option == 'Bulk Upload') {
      setUploadMessage({
        title: 'Bulk Upload',
        description: 'You can upload between 1 to 10 resumes at a time',
      });
    } else {
      setUploadMessage({
        title: 'Upload Resume',
        description: 'You can upload single resume at a time',
      });
    }
    setSelectedOption(option);
    // handleMenuClose();
    setAnchorEl(null);
    // setBulk(option === 'Bulk Upload');
  };

  const updatedCandidateDetails = (value: TadCandidateDetailsModel) => {
    setCandidateDetails({
      ...candidateDetails,
      ...value,
    });
  };

  const save = (value: TadCandidateDetailsModel) => {
    setFinalData(value);
    if (
      globalObject.properties.show_autopopulate_feedback == 'true' &&
      getNameFromId(value.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL) !==
        CANDIDATE_SOURCE.REFERRAL
    ) {
      setShowFeedbackAlert(true);
    } else {
      saveCandidate();
    }
  };

  const saveCandidate = (aiCheckbox?: boolean) => {
    const newObj = {
      ...candidateDetails,
      ...finalData,
      aiCheckbox,
    };
    setCandidateDetails(newObj);
    setShowOverlay(true);
    getNameFromId(newObj.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL) !=
      CANDIDATE_SOURCE.REFERRAL &&
      tadAddCandidate(file, newObj)
        .then((_resp) => {
          setToastMsg({
            severity: 'success',
            msg: 'Candidate added successfully',
          });
          setShowToast(true);
          setTimeout(() => {
            setShowOverlay(false);
            const existingConfig = localStorage.getItem('breadcrumb-config');
            let existing: BreadCrumbConfig[] = [];
            if (existingConfig) {
              existing = JSON.parse(existingConfig);
              existing.splice(existing.length - 1, 1);
              navigate(existing, true);
            } else {
              navigate(
                [
                  {
                    label: 'Candidate',
                    link: '/candidate',
                  },
                ],
                true,
              );
            }
          }, 3000);
        })
        .catch((_err) => {
          setShowOverlay(false);
          setToastMsg({
            severity: 'error',
            msg: 'Something went wrong. Please try later!',
          });
          setShowToast(true);
        });
  };

  // const formik = useFormik({
  //   initialValues: {
  //     resume: null,
  //     bulkResumes: [],
  //   },
  //   validationSchema: Yup.object({
  //     resume: Yup.mixed().required('A resume file is required'),
  //     bulkResumes: Yup.array().min(1, 'At least one file is required for bulk upload'),
  //   }),
  //   onSubmit: (values) => {
  //     console.log('Form values:', values);
  //   },
  // });
  const handleResumeUpload = async (file: File) => {
    try {
      setFile(file);
      setShowOverlay(true);

      const response = await parseResume(file);
      const parsedData: TadCandidateDetailsModel = response.data;

      parsedData.skills = mapSkills(parsedData.resumeSkills, skills);

      setCandidateDetails({
        ...candidateDetails,
        ...parsedData,
      });

      setShowOverlay(false);

      if (parsedData.message && parsedData.message !== '') {
        setToastMsg({
          severity: 'error',
          msg: parsedData.message,
        });
        setShowToast(true);
      } else {
        setActiveStep(activeStep + 1);
      }
    } catch (error) {
      console.error('Error parsing resume:', error);
      setShowOverlay(false);
      setToastMsg({
        severity: 'error',
        msg: 'Something went wrong. Please try later!',
      });
      setShowToast(true);
    }
  };

  const [uploadProgress, setUploadProgress] = useState<
    {
      progress: number;
      timeTaken: number;
      resumeName: string;
      resumeSize: number;
      resumes: any[];
      resumeFile: any;
      isDuplicate: boolean;
      duplicateResumes: any[];
    }[]
  >([]);

  const handleBulkResumeUpload = (files: File[]) => {
    const filesProgress = files.map(() => ({
      progress: 0,
      timeTaken: 0,
      resumeName: '',
      resumeSize: 0,
      resumes: [],
      resumeFile: '',
      isDuplicate: false,
      duplicateResumes: [],
    }));
    setUploadProgress(filesProgress);

    setToastMsg({
      severity: 'info',
      msg: 'Upload in progress...',
    });
    setShowToast(true);

    const uploadPromises = files.map((file, index) => {
      let simulatedProgress = 0;
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          const updated = [...prev];
          if (simulatedProgress < 99) {
            simulatedProgress += 1;
            updated[index].progress = simulatedProgress;
            updated[index].resumeName = file.name;
            updated[index].resumeSize = file.size;
            updated[index].resumeFile = file;
            return updated;
          }
          return updated;
        });
      }, 150);

      const formData = new FormData();
      formData.append('resumes', file);

      return axiosClient
        .post('/tad-candidate/resumes', formData)
        .then((res) => {
          clearInterval(interval);
          setUploadProgress((prev) => {
            const updated = [...prev];
            updated[index].progress = 100;
            const resumedata: any[] = res.data?.result;
            if (resumedata.length > 1) {
              updated[index].isDuplicate = true;
              updated[index].duplicateResumes = resumedata;
            } else {
              updated[index].resumes = resumedata;
            }
            return updated;
          });

          setToastMsg({
            severity: 'success',
            msg: `Resume uploaded: ${file.name}`,
          });
          setShowToast(true);
        })
        .catch((error) => {
          clearInterval(interval);
          console.error('Error during upload', error);

          setToastMsg({
            severity: 'error',
            msg: `Error uploading ${file.name}. Please try again.`,
          });
          setShowToast(true);
        });
    });

    Promise.all(uploadPromises)
      .then(() => {
        setToastMsg({
          severity: 'success',
          msg: 'All candidates uploaded successfully!',
        });
        setShowToast(true);
      })
      .catch(() => {
        setToastMsg({
          severity: 'error',
          msg: 'Some uploads failed. Please try again.',
        });
        setShowToast(true);
      })
      .finally(() => {
        if (fileInputRef.current) fileInputRef.current.value = '';
      });
  };

  const StepperList = () => {
    const steps =
      selectedOption === 'Candidate Resume'
        ? [
            { number: 1, title: 'Upload Resume' },
            { number: 2, title: 'Candidate Details' },
            { number: 3, title: 'Employment Details' },
            { number: 4, title: 'Education & Certifications' },
            { number: 5, title: 'Additional Details' },
          ]
        : [{ number: 1, title: 'Upload Resume' }];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '18.5625rem',
          height: selectedOption === 'Candidate Resume' ? '21.3125rem' : 'auto',
          borderRight: '0.0625rem solid #C1C0C0',
          overflowY: selectedOption === 'Candidate Resume' ? 'auto' : 'hidden',
        }}>
        {steps.map((step) => (
          <div
            key={step.number}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              backgroundColor: '#fff',
              borderBottom: '0.0625rem solid #C1C0C0',
              borderLeft:
                activeStep === step.number
                  ? ' 0.1875rem solid #DC3857'
                  : activeStep && activeStep > step.number
                  ? '0.1875rem solid #1976D2'
                  : '0.1875rem solid #fff',
              boxSizing: 'border-box',
              justifyContent: 'space-between',
              flex: 1,
              minHeight: '3.75rem',
            }}>
            <div style={{ display: 'flex', flex: 1, padding: '10px' }}>
              <div
                style={{
                  width: '1.875rem',
                  height: '1.875rem',
                  borderRadius: '50%',
                  backgroundColor:
                    activeStep === step.number
                      ? 'var(--Status-Red-500, #DC3857)'
                      : activeStep && activeStep > step.number
                      ? 'var(--Primary-Blue-500, #1976D2)'
                      : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '1.25rem',
                  color:
                    activeStep === step.number
                      ? 'var(--Secondary-colors-White, #FFF)'
                      : 'var(--Primary-Dark-grey-500, #333)',
                }}>
                <Typography variant='body2' style={{ display: 'flex', fontWeight: 'bold' }}>
                  {activeStep && activeStep > step.number ? <DoneIcon /> : step.number}
                </Typography>
              </div>
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: '400',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {step.title}
                </Typography>
              </div>
              {activeStep === step.number && (
                <div>
                  <ArrowForwardIosIcon
                    style={{ width: '1rem', height: '1rem', marginTop: '5px' }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // const handleBulkFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   if (files) {
  //     const fileArray = Array.from(files);

  //     formik.setFieldValue('resumes', fileArray);

  //     handleBulkResumeUpload(fileArray);
  //   }
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className='main-div' style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <TadPageHeader></TadPageHeader>
      </div>
      <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
        <div className='paper' style={{ height: '4rem' }}>
          <Paper sx={{ width: '18.5625rem' }}>
            <StepperList />
          </Paper>
        </div>
        <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
          {activeStep == 1 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                      {uploadMessage.title}
                    </span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      {uploadMessage.description}
                    </span>
                  </div>
                  <div>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleMenuClick}
                      endIcon={<ArrowDropDownIcon />}
                      style={{
                        width: '17.8125rem',
                        height: '3.125rem',
                        flexShrink: 0,
                        borderRadius: '0.3125rem',
                        background: 'var(--Primary-1, #1976D2)',
                        marginBottom: '1rem',
                        textTransform: 'capitalize',
                        display: 'flex',
                        marginRight: '3.05rem',
                        alignItems: 'center',
                        gap: '2.31rem',
                      }}>
                      {selectedOption === 'Bulk Upload' ? <GroupPeople /> : <Single />}
                      {selectedOption}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}>
                      <MenuItem onClick={() => handleMenuSelect('Candidate Resume')}>
                        <label
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                          }}>
                          <SingleIcon style={{ marginRight: '0.5rem' }} />
                          Candidate Resume
                          {/* <input
                            type='file'
                            accept='.pdf, .docx, .jpg, .png'
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              const file = e.target.files?.[0];
                              if (file) {
                                handleResumeUpload(file);
                                formik.setFieldValue('resume', file);
                                handleMenuSelect('Single Upload');
                              }
                            }}
                          /> */}
                        </label>
                      </MenuItem>

                      <MenuItem onClick={() => handleMenuSelect('Bulk Upload')}>
                        <label
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            cursor: 'pointer',
                          }}>
                          <Group style={{ color: '#1976D2', marginRight: '0.5rem' }} />
                          Bulk Upload
                          {/* <input
                            type='file'
                            accept='.pdf, .docx'
                            multiple
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files) {
                                handleBulkFileChange(e);
                                handleMenuSelect('Bulk Upload');
                              }
                            }}
                          /> */}
                        </label>
                      </MenuItem>
                    </Menu>
                  </div>
                </Paper>
              </div>

              <UploadResume
                selectedOption={selectedOption}
                progress={uploadProgress}
                handleResumeUpload={handleResumeUpload}
                handleBulkResumeUpload={(files) => {
                  handleBulkResumeUpload(files);
                }}
              />
            </>
          )}

          {activeStep === 2 && (
            <>
              <div className='paper' style={{ height: '8.8125rem', width: '100%' }}>
                <Paper className='new-job-requisition' sx={{ width: '100%', height: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Candidate Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Comprehensive Overview of Candidate Information and Qualifications
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginRight: '3.05rem',
                      alignItems: 'center',
                      gap: '2.31rem',
                    }}></div>
                </Paper>
              </div>
              <CandidateDetails
                previous={() => {
                  setActiveStep(activeStep - 1);
                }}
                candidateSkills={skills}
                values={candidateDetails}
                next={(val) => {
                  updatedCandidateDetails(val);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 3 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Employment Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Overview of the Candidates Employment History
                    </span>
                  </div>
                </Paper>
              </div>

              <EmploymentDetails
                previous={() => setActiveStep(activeStep - 1)}
                values={candidateDetails}
                next={(val) => {
                  updatedCandidateDetails(val);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 4 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>
                      Education & Certifications
                    </span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Overview of Educational Background and Certifications
                    </span>
                  </div>
                </Paper>
              </div>

              <EducationCertificationDetails
                values={candidateDetails}
                previous={() => setActiveStep(activeStep - 1)}
                next={(val) => {
                  updatedCandidateDetails(val);
                  setActiveStep(activeStep + 1);
                }}
              />
            </>
          )}

          {activeStep == 5 && (
            <>
              <div
                className='paper'
                style={{
                  height: '8.8125rem',
                  width: '100%',
                }}>
                <Paper
                  className='new-job-requisition'
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: '1.88rem',
                      gap: '7px',
                    }}>
                    <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>Additional Details</span>
                    <span
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                      }}>
                      Capture any additional details which are important for the job
                    </span>
                  </div>
                </Paper>
              </div>

              <AdditionalDetails
                values={candidateDetails}
                previous={() => setActiveStep(activeStep - 1)}
                next={(val) => {
                  save(val);
                }}
              />
            </>
          )}
        </div>
      </div>
      <TadScreenLoader loading={showOverlay} />
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
      {showFeedbackAlert && (
        <Dialog open={showFeedbackAlert} fullWidth={true} maxWidth='md'>
          <DialogTitle>Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>Is all data autopopulated correctly?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className='button'
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '8.25rem',
                height: '2.5rem',
                marginRight: '2rem',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}
              onClick={() => {
                saveCandidate(true);
                setShowFeedbackAlert(false);
              }}>
              Yes
            </Button>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}
              onClick={() => {
                saveCandidate(true);
                setShowFeedbackAlert(false);
              }}
              autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AddCandidates;
