import * as React from 'react';
import TadTimeLine from '../common/timeline/tad-timeline';
import { Paper } from '@mui/material';

interface Props {
  _value: any;
}

const InterviewProcessSteps = ({ _value }: Props) => {
  const sortStagesAndItems = (stages?: any[]) => {
    if (!Array.isArray(stages)) return [];
    return stages
      .slice()
      .sort((a, b) => (a.position || 0) - (b.position || 0))
      .map((stage) => ({
        ...stage,
        item: (stage.item || [])
          .slice()
          .sort((a: any, b: any) => (a.position || 0) - (b.position || 0)),
      }));
  };
  const sortedValue = React.useMemo(() => sortStagesAndItems(_value), [_value]);

  return (
    <Paper className='paper'>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Interview Process</span>
        </div>
      </div>
      <TadTimeLine process={sortedValue} parent='interview' />
    </Paper>
  );
};

export default InterviewProcessSteps;
