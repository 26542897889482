import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Chip,
  // Avatar,
  Link,
  Avatar,
  AvatarGroup,
  Typography,
} from '@mui/material';
// import { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { LookupTypes } from '../../../../common/lookupTypes';
import { getNameFromId } from '../../../common/commonUtil';
import { getInitials } from '../../../../common/commonUtil';
import ContactCard from '../../common/contact-card';
import useNavigate from '../../../../common/useNavigate';
import { lightBlue } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { getTadSjd } from '../../../service/tadSjdService';
import TadScreenLoader from '../../common/tad-screen-loader';

// import { tagCandidateToSjd } from '../../../service/tadSjdService';
// import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';

// interface HiringTeam {
//   firstName: string;
//   lastName: string;
// }

interface CandidateValues {
  candidate: any;
  isEvaluator: boolean;
  // other fields...
}

const CandidateJobDefinition: React.FC<{ values: CandidateValues }> = ({ values }) => {
  let { sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  const [expanded, setExpanded] = useState(false);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [sjd, setSjd] = useState({} as any);
  const [navigate] = useNavigate();

  useEffect(() => {
    setLoading(true);
    getTadSjd(sjdId)
      .then((res) => {
        setLoading(false);
        setSjd(res.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  // const [sjdlist, setSjdList] = useState([]);
  // useEffect(() => {
  //   if (values) {
  //     setSjdList(values);
  //   }
  // }, [values]);

  // const data = [
  //   {
  //     sjd: { id: 'TAD1490', name: 'Product Manager' },
  //     value: '01',
  //     recruiters: '',
  //     status: 'In Progress',
  //   },
  //   {
  //     sjd: { id: 'TAD1490', name: 'PHP Developer' },
  //     value: '04',
  //     recruiters: '',
  //     status: 'In Progress',
  //   },
  // ];

  // const redirectToCandidatedDetailsPage = (candidateId: any) => {
  //   navigate(
  //     [
  //       {
  //         label: 'CandidateDetailsPage',
  //         link: `/candidate/${btoa(candidateId)}`,
  //       },
  //     ],
  //     true,
  //   );
  // };

  const truncateHtml = (html: string, maxLength: number) => {
    if (html?.length <= maxLength) return html;

    let truncated = html?.slice(0, maxLength);

    // Ensure no broken tags
    const lastOpeningTag = truncated?.lastIndexOf('<');
    const lastClosingTag = truncated?.lastIndexOf('>');

    if (lastOpeningTag > lastClosingTag) {
      truncated = truncated?.slice(0, lastOpeningTag);
    }

    return truncated + '...';
  };

  return (
    <>
      <Paper style={{ width: '100%' }}>
        <div
          style={{
            minHeight: '61px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
          }}>
          <div style={{ display: 'flex' }}>
            <span
              className='heading'
              style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
              {!values.isEvaluator
                ? `Job Definitions (${values?.candidate.sjdCandidateInfo?.length})`
                : 'Job Definitions'}
            </span>
          </div>
        </div>
        {!values.isEvaluator ? (
          <div
            className='qc-table'
            style={{
              marginLeft: '2.02rem',
              marginRight: '1.25rem',
              overflowY: 'scroll',
              maxHeight: '348px',
            }}>
            <Table>
              <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
                <TableRow>
                  <TableCell>Job Definition</TableCell>
                  <TableCell>Candidates</TableCell>
                  <TableCell>Recruiters</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values?.candidate.sjdCandidateInfo?.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    <TableRow style={{ height: '0.62rem', padding: 0 }}>
                      <TableCell colSpan={4} style={{ padding: 0, border: 'none' }} />
                    </TableRow>
                    <TableRow
                      style={{
                        width: '43.875rem',
                        height: '4.875rem',
                        //   background: '#F1F4F8',
                        padding: 0,
                      }}>
                      <TableCell>
                        <span
                          style={{
                            color: '#868686',
                            fontSize: '0.875rem',
                            display: 'flex',
                            fontWeight: '400',
                          }}>
                          {`TAD${item.sjdId}`}
                        </span>
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate([
                              {
                                label: item.sjdName,
                                link: `/job-definition-details/${btoa(
                                  item.sjdId.toString(),
                                )}/${btoa('false')}`,
                              },
                            ]);
                          }}
                          style={{
                            textDecoration: 'underline',
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#1976D2',
                          }}>
                          {item.sjdName}
                        </Link>
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '1rem',
                          fontWeight: 400,
                          // textDecoration: 'underline',
                          color: 'rgba(0, 0, 0, 0.87)',
                        }}>
                        {item.totalCandidates}
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex' }}>
                          <AvatarGroup
                            spacing={'medium'}
                            max={4}
                            sx={{
                              justifyContent: 'flex-end',
                              '& .MuiAvatar-root': {
                                width: 30,
                                height: 30,
                                fontSize: 'small',
                              },
                            }}>
                            {item.hiringTeam?.map((recruiter: any, index: any) =>
                              recruiter.profilePicture && recruiter.profilePicture?.length > 0 ? (
                                <Avatar
                                  sx={{
                                    bgcolor: lightBlue[700],
                                    height: 24,
                                    width: 24,
                                    cursor: 'pointer',
                                  }}
                                  onClick={(e) => {
                                    setContact(recruiter);
                                    setContactAnchor(e.currentTarget);
                                  }}
                                  src={recruiter.profilePicture}
                                  key={index}></Avatar>
                              ) : (
                                <Avatar
                                  sx={{
                                    bgcolor: lightBlue[700],
                                    height: 24,
                                    width: 24,
                                    cursor: 'pointer',
                                  }}
                                  onClick={(e) => {
                                    setContact(recruiter);
                                    setContactAnchor(e.currentTarget);
                                  }}
                                  key={index}>
                                  {recruiter && getInitials(recruiter)}
                                </Avatar>
                              ),
                            )}
                          </AvatarGroup>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          padding: 0,
                          marginTop: '1.69rem',
                          marginBottom: '1.31rem',
                          marginRight: '0.78rem',
                        }}>
                        <Chip
                          label={getNameFromId(item.sjdStatus, LookupTypes.TAD_SJD_STATUS)}
                          style={{
                            backgroundColor: '#F1D87E',
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            height: '1.875rem',
                            width: 'auto',
                            padding: '0 8px',
                            borderRadius: '0.3125rem',
                            textOverflow: 'clip',
                            overflow: 'visible',
                            whiteSpace: 'nowrap',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <ContactCard
              contact={contact}
              anchorEl={contactAnchor}
              onClose={() => {
                setContactAnchor(null);
                setContact(null);
              }}
            />
          </div>
        ) : (
          <div style={{ padding: '2.69rem 3rem 2.19rem 3rem' }}>
            <div className='field-container'>
              <div style={{ width: '40%' }}>
                <Typography sx={{ fontWeight: 600 }}>ID: </Typography>
              </div>
              <span className='text-label'>{sjd.id}</span>
            </div>
            <div className='field-container'>
              <div style={{ width: '40%' }}>
                <Typography sx={{ fontWeight: 600 }}>Job Title: </Typography>
              </div>
              <span className='text-label'>{sjd.name}</span>
            </div>
            <div className='field-container'>
              <div style={{ width: '40%' }}>
                <Typography sx={{ fontWeight: 600 }}>Skills: </Typography>
              </div>
              <span className='text-label'>
                {sjd.primarySkills?.map((s: any) => s.skill.name).join(', ')},&nbsp;
                {sjd.secondarySkills?.map((s: any) => s.skill.name).join(', ')}
              </span>
            </div>
            <div className='field-container'>
              <div style={{ width: '40%' }}>
                <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities: </Typography>
              </div>
              <span className='text-label' style={{ width: '60%' }}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: expanded ? sjd.description : truncateHtml(sjd.description, 550),
                  }}></span>

                {sjd.description?.length > 550 && (
                  <button
                    onClick={() => setExpanded((prev) => !prev)}
                    style={{
                      color: '#1976d2',
                      border: 'none',
                      background: 'none',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}>
                    {expanded ? 'See Less' : 'See More'}
                  </button>
                )}
              </span>
            </div>
          </div>
        )}
      </Paper>
      <TadScreenLoader loading={loading} />
    </>
  );
};
export default CandidateJobDefinition;
