import { Done, Add } from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  Typography,
  InputBase,
  Divider,
  IconButton,
  AlertColor,
  Link,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Search } from '../../assets/candidate-assets/search.svg';
import { tadGetAllCandidate } from '../../service/tadCandidateService';
import { tadUntagCandidateApi, tagCandidateToSjd } from '../../service/tadSjdService';
import { useParams } from 'react-router-dom';
import { handleCandidateInitials } from '../../common/commonUtil';
import Notify from '../../common/notify';
import CloseIcon from '../../assets/sjd/close.svg';
type Candidate = {
  id: number;
  firstName: string;
  lastName: string;
};

interface Props {
  candidate: any;
  setCandidateTagged: (value: boolean) => void;
  candidateTagged: any;
  open: (value: boolean) => void;
}

function AddCandidateDrawer({ candidate, setCandidateTagged, candidateTagged, open }: Props) {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([] as any[]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  let { sjdId } = useParams();
  const [loading, setLoading] = useState(false);
  if (sjdId) sjdId = atob(sjdId);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [selectedUsers, setSelectedUsers] = useState<Candidate[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const loadCandidates = async (searchValue = search, nextPage = 0) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await tadGetAllCandidate(false, nextPage, 100, searchValue);
      setData((prevData) => (nextPage === 0 ? res.data.items : [...prevData, ...res.data.items]));
      setPage(nextPage);
      setHasMore(res.data.items.length + data.length < res.data.total);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && hasMore) {
      loadCandidates(undefined, page);
    }
  }, [page]);

  const lastElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setPage((prevPage) => prevPage + 1);
          }
        },
        { root: null, rootMargin: '20px', threshold: 1.0 },
      );

      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    if (candidate) {
      setSelectedUsers(candidate);
    }
  }, [candidate]);

  const handleTagCandidate = (candidate: any, toast: string) => {
    const request = {
      sjdId: sjdId,
      candidateId: candidate.id,
      resumeId: candidate.resumeId,
    };
    setLoading(true);
    if (toast === 'untagged') {
      tadUntagCandidateApi({
        sjdId: sjdId,
        candidateId: candidate.id,
      })
        .then((_res) => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({ severity: 'success', msg: 'Candidate untagged Successfully' });
          setCandidateTagged(!candidateTagged);
        })
        .catch((_err) => {
          setLoading(false);
          setShowToast(true);
          setToastMsg({ severity: 'error', msg: 'something went wrong!' });
        });
    } else {
      tagCandidateToSjd(request)
        .then((_response) => {
          tadGetAllCandidate(false, 0, 100, search)
            .then((res: any) => {
              setLoading(false);
              setData(res.data.items);
              setShowToast(true);
              setToastMsg({ severity: 'success', msg: `candidate ${toast} successfully!` });
              setCandidateTagged(!candidateTagged);
            })
            .catch((error) => {
              console.error('Error fetching candidates:', error);
              setLoading(false);
            });
        })
        .catch((error) => {
          console.error('Error tagging candidate:', error);
        });
    }
  };

  const filteredData = data.filter(
    (candidate) =>
      candidate.name?.toLowerCase().includes(search.toLowerCase()) ||
      (candidate.email && candidate.email?.toLowerCase().includes(search.toLowerCase())),
  );

  const handleAddUser = (candidate: Candidate) => {
    if (!selectedUsers.some((user) => user.id === candidate.id)) {
      setSelectedUsers([...selectedUsers, candidate]);
      handleTagCandidate(candidate, 'tagged');
    } else {
      setSelectedUsers((previous) => previous.filter((user) => user.id !== candidate.id));
      handleTagCandidate(candidate, 'untagged');
    }
  };

  const handleDrawerClose = () => {
    open(false);
  };

  return (
    <Box role='presentation' ref={drawerRef} sx={{ width: '43rem' }}>
      <List sx={{ padding: '3.19rem 1.5rem' }}>
        <ListItem disablePadding sx={{ marginBottom: '1.19rem', fontWeight: 400 }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                gap: '18.12rem',
                alignItems: 'center',
              }}>
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Candidates</Typography>
              <IconButton onClick={handleDrawerClose}>
                <img src={CloseIcon} alt='Close' style={{ width: '24px' }} />
              </IconButton>
            </Box>
          </div>
        </ListItem>
        <div
          style={{
            gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '0.8rem',
          }}>
          <hr style={{ background: '#868686' }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5%', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.8rem',
                marginLeft: '1.06rem',
              }}>
              <Search />
              <InputBase
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  loadCandidates(e.target.value);
                }}
                sx={{ ml: '10px', width: '100%' }}
                placeholder='Search'
                inputProps={{ 'aria-label': 'Search a keyword' }}
              />
            </div>
            <div>
              <Divider sx={{ display: 'flex' }} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '2.12rem', marginBottom: '1.25rem' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.25rem', color: '#000000' }}>
            Recently Created Candidates
          </Typography>
        </div>
        <div style={{ height: '50%', overflowY: 'auto' }}>
          {filteredData.map((candidate, index) => (
            <div
              ref={index === data.length - 1 ? lastElementRef : null}
              key={candidate.id}
              style={{
                marginBottom: '0.63rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#F1F4F8',
                height: '5.4375rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginLeft: '1.25rem',
                  alignItems: 'center',
                }}>
                <div
                  className='user-icon'
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white',
                    marginBottom: 0,
                    height: '3rem',
                    width: '3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                  }}>
                  {handleCandidateInitials(candidate.name)}
                </div>
                <Link style={{ cursor: 'pointer' }}>{candidate.name}</Link>
              </div>
              <div style={{ marginRight: '1.25rem' }}>
                <IconButton onClick={() => handleAddUser(candidate)}>
                  {selectedUsers.some((user) => user.id === candidate.id) ? <Done /> : <Add />}
                </IconButton>
              </div>
            </div>
          ))}
          <div style={{ textAlign: 'center' }}>
            {loading && <span>Loading data...</span>}
            {!hasMore && <span>No more data to load</span>}
          </div>
        </div>
      </List>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </Box>
  );
}

export default AddCandidateDrawer;
