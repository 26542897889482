import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { Search } from '@mui/icons-material';
import CloseIcon from '../../../assets/sjd/close.svg';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SkillModel } from '../../../models/tad-candidate-form-model';
import { getTadSkills } from '../../../../service/lookupService';
import { tadUpdateCandidate } from '../../../service/tadCandidateService';
import { TADMODULE } from '../../../../constants/common';
import { isTadPermissions } from '../../../common/commonUtil';
// import { TADMODULE } from '../../../../constants/common';
// import { isTadPermissions } from '../../../common/commonUtil';

interface CandidatePrimarySkillsProps {
  candidate: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
  isEditPermission: boolean;
}

const CandidatePrimarySkills: React.FC<CandidatePrimarySkillsProps> = ({
  candidate,
  setLoading,
  reloadCandidate,
  alert,
  isEditPermission,
}) => {
  const [selectedSkills, setSelectedSkills] = useState<SkillModel[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [allSkills, setAllSkills] = useState<SkillModel[]>([]);

  useEffect(() => {
    if (candidate.skills) {
      setSelectedSkills(candidate.skills);
    }
  }, [candidate.skills]);

  useEffect(() => {
    getTadSkills()
      .then((res) => setAllSkills(res.data))
      .catch((_err) => {});
  }, []);

  // Handle deletion of a selected skill
  const handleDelete = (skillToDelete: SkillModel) => {
    setSelectedSkills((prevSkills) => {
      // let updated = false;
      const updatedSkills = prevSkills;
      candidate.skills = updatedSkills.filter((skill) => skill.id !== skillToDelete.id);
      tadUpdateCandidate(candidate, false)
        .then((_res) => {
          // updated = true;
          reloadCandidate();
          alert('Candidate skills updated successfully!', true);
        })
        .catch(() => {
          alert('Something went wrong!', false);
        })
        .finally(() => setLoading(false));
      return selectedSkills;
    });
  };

  // Handle adding skills to the list
  const handleAddClick = () => {
    setMenuOpen(true);
  };

  // Close the drawer
  const handleDrawerClose = () => {
    setMenuOpen(false);
  };

  const handleSkillClick = (skill: SkillModel) => {
    const filteredSkills = (prevSelected: any[]) => {
      const isSelected = prevSelected.some((user) => user.id === skill.id);
      if (isSelected) {
        candidate.skills = prevSelected.filter((user) => user.id !== skill.id);
        setLoading(true);
        setMenuOpen(false);
        tadUpdateCandidate(candidate, false)
          .then((_res) => {
            setMenuOpen(false);
            reloadCandidate();
            alert('Candidate skills updated successfully!', true);
          })
          .finally(() => setLoading(false));
        return prevSelected.filter((user) => user.id !== skill.id);
      } else {
        candidate.skills = [...prevSelected, skill];
        setLoading(true);
        setMenuOpen(false);
        tadUpdateCandidate(candidate, false)
          .then((_res) => {
            setMenuOpen(false);
            reloadCandidate();
            alert('Candidate skills updated successfully!', true);
          })
          .finally(() => setLoading(false));
        return [...prevSelected, skill];
      }
    };
    setSelectedSkills((previous) => filteredSkills(previous));
  };

  // Search functionality to filter skills
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const DrawerList = (
    <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
      <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
        <ListItem
          disablePadding
          sx={{ marginBottom: '1.19rem', cursor: 'pointer', fontWeight: 400 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              gap: '18.12rem',
            }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Add Skills</Typography>
            <IconButton onClick={handleDrawerClose}>
              <img src={CloseIcon} alt='Close' style={{ width: '24px' }} />
            </IconButton>
          </Box>
        </ListItem>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0.8rem',
            padding: '0 1.06rem',
          }}>
          <Search />
          <InputBase
            sx={{ ml: '10px', width: '100%' }}
            placeholder='Search for a skill'
            inputProps={{ 'aria-label': 'Search a skill' }}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Box>
        <Divider />
        <Typography sx={{ padding: '1rem 1.06rem', fontWeight: 600 }}>
          Recently added skills
        </Typography>
        <List>
          {allSkills.map((skill) => (
            <ListItem
              key={skill.id}
              sx={{
                height: '2.68rem',
                marginBottom: '0.5rem',
                background: '#F1F4F8',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 1rem',
              }}>
              <Typography sx={{ fontSize: '1rem', color: '#333' }}>{skill.name}</Typography>
              <IconButton>
                {selectedSkills.some((selectedSkill) => selectedSkill.id === skill.id) ? (
                  <CheckIcon
                    onClick={() => {
                      handleSkillClick(skill);
                    }}
                  />
                ) : (
                  <AddIcon
                    onClick={() => {
                      handleSkillClick(skill);
                    }}
                  />
                )}
              </IconButton>
            </ListItem>
          ))}
        </List>
      </List>
    </Box>
  );

  return (
    <Paper sx={{ minHeight: '14.5rem', width: '100%' }}>
      <Box
        sx={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#E7E7E7',
          paddingLeft: '1.81rem',
        }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Primary Skills</Typography>
        <Box>
          {isTadPermissions(TADMODULE.CANDIDATES, 'write') && (
            <IconButton disabled={!isEditPermission} onClick={handleAddClick}>
              <AddIcon />
            </IconButton>
          )}
          {/* <IconButton>
            <MoreVertIcon />
          </IconButton> */}
        </Box>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '8px',
          width: '100%',
          padding: '1rem 0rem 1rem 1rem',
        }}>
        {selectedSkills.map((skill) => (
          <Grid item key={skill.id}>
            <Chip
              disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
              label={skill.name}
              onDelete={() => handleDelete(skill)}
              variant='outlined'
              sx={{
                fontSize: '0.875rem',
                backgroundColor: '#E1E1E1',
                color: 'black',
                borderRadius: '10px',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Drawer anchor='right' open={menuOpen} onClose={handleDrawerClose} sx={{ zIndex: 9999 }}>
        {DrawerList}
      </Drawer>
    </Paper>
  );
};

export default CandidatePrimarySkills;
