import {
  AlertColor,
  Autocomplete,
  // Box,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  // List,
  // ListItem,
  ListItemText,
  Menu,
  // ListItemIcon,
  // ListItemText,
  // ListItemIcon,
  // ListItemText,
  // Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
// import PageHeader from '../../../components/common/page-header/page-header';
import { useEffect, useState } from 'react';
import './new-source-job.scss';
import NewLocationAutoComplete from '../common/new-location-autocomplete';
import { DatePicker } from '@mui/x-date-pickers';
// import './tad-source-job-definition.scss';
import * as Yup from 'yup';

import CountryCodes from '../common/country-codes';
import { ReactComponent as CopyIcon } from '../../assets/sjd/content_copy.svg';
import { ReactComponent as ThumbIcon } from '../../assets/sjd/thumb_down_alt.svg';
import { ReactComponent as RefreshIcon } from '../../assets/sjd/refresh.svg';
import { ReactComponent as FantacyIcon } from '../../assets/sjd/Group 343.svg';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import { ReactComponent as DoneIcon } from '../../assets/sjd/done.svg';
import { ReactComponent as ArrowIcon } from '../../assets/sjd/chevron_right.svg';
import { ReactComponent as CheckIcon } from '../../assets/sjd/checkIcon.svg';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TextEditorField from '../common/text-editor-field';
import { FormikProps, useFormik } from 'formik';
import dayjs from 'dayjs';
import dateFormat from 'dateformat';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { getTadSkills } from '../../../service/lookupService';
import {
  generateJobDescription,
  getClientsByProjectTypeId,
  tadAddSjd,
} from '../../service/tadSjdService';
import useNavigate from '../../../common/useNavigate';
import TadPageHeader from '../common/page-header/tad-page-header';
import { getAllUsers } from '../../../service/userService';
import { TADROLES } from '../../../constants/common';
import Notify from '../../common/notify';
import { BreadCrumbConfig, getIdFromName } from '../../../common/commonUtil';
import TadLoader from '../../common/tad-loader';
import TadScreenLoader from '../common/tad-screen-loader';
import { getprojectsByClientId, tadgetAllApprovals } from '../../service/tadApprovalService';

interface OptionType {
  id: number;
  name: string;
}

const TadSourceJobRequisition = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allSkills, setAllSkills] = useState([] as any[]);
  const [aiValidation, setAiValidation] = useState<boolean | null>(null);
  const [generatedJobDescription, setGeneratedJobDescription] = useState({} as any);
  const [selectedFullTimeFlag, setSelectedFullTimeFlag] = useState('IN');
  const [selectedConsultantFlag, setSelectedConsultantFlag] = useState('IN');
  const [selectedInternshipFlag, setSelectedInternshipFlag] = useState('IN');
  const [showToast, setShowToast] = useState(false);
  const [accountManagers, setAccountManagers] = useState([] as any[]);
  const [recruitingManagers, setRecruitingManagers] = useState([] as any[]);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    actionButtons: [],
    title: '',
    message: '',
    position: '',
  } as {
    severity: AlertColor;
    actionButtons?: any[];
    title?: any;
    message?: string;
    position?: string;
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [instructions, setInstructions] = useState('');
  const [clients, setClients] = useState([] as any[]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [askAiForm, setASkAiForm] = useState(false);
  const [isClientBillable, setIsClientBillable] = useState(false);
  const [projects, setProjects] = useState([] as any[]);

  const [navigate] = useNavigate();

  const handleLocationSelect = (locations: string[] = []) => {
    formik.setFieldValue('jobLocation', locations);
  };

  useEffect(() => {
    getTadSkills().then((res) => setAllSkills(res.data));
  }, []);

  const requisitionSchema = Yup.object().shape({
    name: Yup.string()
      .max(1000, 'Name should not exceed 1000 characters')
      .required('Job Title is required'),

    description: Yup.string().test(
      'description-check',
      'Description is required',
      (value) => value !== '<p><br></p>',
    ),

    jobLocation: Yup.array()
      .of(Yup.string().max(1000, 'Location should not exceed 1000 characters'))
      .min(1, 'Job Location are required'),

    domainId: Yup.number().nullable().required('Domain is required'),

    minExperienceLevel: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .min(0, 'Value must be more than 0')
      .max(49, 'Value must be less than or equal to 49')
      .lessThan(Yup.ref('maxExperienceLevel'), 'Min experience must be less than max experience'),

    maxExperienceLevel: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .min(1, 'Value must be positive')
      .max(50, 'Value must be less than or equal to 50'),

    priorityId: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .nullable(),

    operationId: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .nullable()
      .required('Operations is required'),

    accountManager: Yup.number().nullable(),

    recruitingManagerId: Yup.object().required('Recruiting manager is required'),

    jobTypeId: Yup.array().of(Yup.object()).min(1, 'Employment Type are required'),

    workingModelId: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .nullable(),

    primarySkills: Yup.array().of(Yup.object()).min(1, 'Primary skills are required'),

    secondarySkills: Yup.array().of(Yup.object()).min(1, 'Secondary skills are required'),

    openPositions: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .moreThan(-1, 'The value must be positive')
      .nullable(),

    projectTypeId: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .required('Project billing type is required'),

    clientId: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .required('Client is required'),

    projectName: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .nullable(),

    endDate: Yup.string().nullable().required('Target date is required'),

    visaType: Yup.string().max(100, 'Visa Type should not exceed 100 characters').nullable(),

    email: isClientBillable
      ? Yup.string()
      : Yup.string().required('JD Approval Authority is required'),
  });

  const aiRequisitionSchema = Yup.object().shape({
    name: Yup.string()
      .max(1000, 'Name should not exceed 1000 characters')
      .required('Job Title is required'),

    minExperienceLevel: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .min(0, 'Value must be more than 0')
      .max(49, 'Value must be less than or equal to 49')
      .required('Min experience is required')
      .lessThan(Yup.ref('maxExperienceLevel'), 'Min experience must be less than max experience'),

    maxExperienceLevel: Yup.number()
      .integer('The value must be a whole number')
      .typeError('The value must be a number')
      .min(1, 'Value must be positive')
      .max(50, 'Value must be less than or equal to 50')
      .required('Max experience is required'),

    primarySkills: Yup.array()
      .of(Yup.object())
      .min(1, 'Primary skills are required')
      .required('Primary skills are required'),

    secondarySkills: Yup.array()
      .of(Yup.object())
      .min(1, 'Secondary skills are required')
      .required('Secondary skills are required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      jobLocation: [],
      domainId: undefined,
      minExperienceLevel: undefined,
      maxExperienceLevel: undefined,
      experienceType: 1,
      priorityId: undefined,
      operationId: undefined,
      accountManager: [],
      recruitingManagerId: undefined,
      jobTypeId: [],
      workingModelId: undefined,
      qualificationId: undefined,
      payFullTimeRate: '',
      payConsultantRate: '',
      payInternshipRate: '',
      primarySkills: [],
      secondarySkills: [],
      openPositions: undefined,
      projectTypeId: undefined,
      clientId: undefined,
      projectName: undefined,
      endDate: undefined,
      sponsorshipRequired: false,
      visaType: undefined,
      email: undefined,
    },
    validationSchema: !aiValidation ? requisitionSchema : aiRequisitionSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (_values) => {
      AddSjd();
    },
  });

  const handleAiGeneration = () => {
    generateJobDescription({
      JobTitle: formik.values.name,
      ExperienceRequired: `${formik.values.minExperienceLevel}-${formik.values.maxExperienceLevel}`,
      MustHaveSkills: formik.values.primarySkills
        .map((primarySkill: any) => primarySkill.name)
        .join(','),
      GoodToHaveSkills: formik.values.secondarySkills
        .map((secoundarySkill: any) => secoundarySkill.name)
        .join(','),
      Instructions: instructions.length > 0 ? instructions : null,
    }).then((res) => {
      setLoading(false);
      setGeneratedJobDescription(res.data);
    });
  };

  const renderKeyValue = (obj: Record<string, any>) => {
    return Object.keys(obj).map((key, index) => {
      const value = obj[key];

      if (typeof value === 'string') {
        if (typeof value === 'number') {
          <div>
            <Typography sx={{ fontSize: '1rem' }}>{key}:</Typography> <span>{value}</span>
          </div>;
        }
        return (
          <div key={index} style={{ marginBottom: '0.5rem' }}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#000' }}>
              {key}:
            </Typography>{' '}
            {!value.includes('.') ? (
              <span>{value}</span>
            ) : (
              <ul>
                {value.split('. ').map((role, index) => (
                  <li key={index}>{`${role}.`}</li>
                ))}
              </ul>
            )}
          </div>
        );
      } else if (typeof value === 'object') {
        return (
          <div key={index}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 400, color: '#000' }}>
              {key}:
            </Typography>
            <ul>{renderKeyValue(value)}</ul>
          </div>
        );
      }
      return null;
    });
  };

  useEffect(() => {
    const validateAndHandleAI = async () => {
      if (aiValidation) {
        const errors = await formik.validateForm();

        if (Object.keys(errors).length === 0) {
          setASkAiForm(true);
          setLoading(true);
          handleAiGeneration();
        } else {
          setShowToast(true);
          setToastMsg({
            severity: 'warning',
            title: 'Mandatory fields required!',
            message:
              'Please fill in all the mandatory fields to initiate the AI Job Requisition. The following fields are required:<b>Title, Experience Range, Primary Skills, and Secondary Skills.</b>',
            actionButtons: [
              {
                label: 'Cancel',
                onClick: () => setShowToast(false),
                variant: 'outlined',
              },
              {
                label: 'OK',
                onClick: () => setShowToast(false),
                variant: 'contained',
              },
            ],
          });
        }
      } else {
        formik.handleSubmit();
      }
    };

    aiValidation != null && validateAndHandleAI();
  }, [aiValidation, isButtonClicked]);

  const handleAskAISubmit = () => {
    formik.setFieldValue(
      'description',
      `
      Job Summary:
      <ul>
      ${generatedJobDescription?.Job_Summary?.split('. ')
        .filter((part: any) => part.trim() !== '')
        .map((part: any) => `<li>${part.trim()}.</li>`)
        .join('')}
      </ul>
      Roles And Responsibilities:
      <ul>
      ${(generatedJobDescription?.Roles_And_Responsibilities || '')
        .split('. ')
        .filter((part: any) => part.trim() !== '')
        .map((part: any) => `<li>${part.trim()}.</li>`)
        .join('')}
      </ul>      
      `,
    );
    setMenuOpen(false);
    setShowToast(true);
    setToastMsg({
      severity: 'success',
      title: 'Success',
      message: 'Your AI-generated job requisition has been successfully transferred.',
    });
  };

  const DrawerList = (
    <div role='presentation' style={{ width: '33.75rem', height: '100%' }}>
      <div
        style={{
          fontWeight: 400,
          margin: '1.5rem 1.5rem 0rem 1.5rem ',
          borderBottom: '1px solid #868686',
        }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '1rem',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {askAiForm && (
              <div
                style={{
                  display: 'flex',
                  width: '2.5rem',
                  height: '2.5rem',
                  borderRadius: '3rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <IconButton onClick={() => setASkAiForm(false)}>
                  <ArrowIcon style={{ rotate: '90deg' }} />
                </IconButton>
              </div>
            )}
            <div style={{ marginLeft: '1.25rem' }}>
              <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>Ask AI</Typography>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <Select
                  sx={{
                    width: '13.375rem',
                    height: 'var(--Font-size-h2, 2.5rem)',
                    marginRight: '1.37rem',
                  }}
                  size='small'>
                  <MenuItem key={0}>All Job Definitions</MenuItem>
                  <MenuItem value={1}>Select</MenuItem>
                  <MenuItem value={2}>Select</MenuItem>
                </Select> */}
            <div>
              <CloseIcon onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '1.5rem ',
          height: '81%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        {askAiForm ? (
          <div style={{ padding: '0rem 1rem', overflowY: 'auto' }}>
            {!loading ? (
              <div className='text-chat-box'>
                <div>{renderKeyValue(generatedJobDescription)}</div>
                <div
                  style={{
                    display: 'flex',
                    borderRadius: '0.625rem',
                    width: '6.5rem',
                    height: 'var(--Font-size-h3, 2.0625rem)',
                    justifyContent: 'center',
                    border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
                    alignItems: 'center',
                  }}>
                  <div style={{ display: 'flex', marginBottom: '3px' }}>
                    <IconButton>
                      <CopyIcon className='my-svg-icon' />{' '}
                    </IconButton>
                    <IconButton>
                      {' '}
                      <ThumbIcon className='my-svg-icon' />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setLoading(true);
                        handleAiGeneration();
                      }}>
                      <RefreshIcon className='my-svg-icon' />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', height: '50vh' }}>
                <TadLoader />
              </div>
            )}
          </div>
        ) : (
          <div style={{ padding: '0rem 1rem', overflowY: 'auto' }}>
            <div className='field-container' style={{ marginBottom: '0.5rem' }}>
              <div style={{ width: '25%' }}>
                <Typography sx={{ fontWeight: 600 }}>Job Title :</Typography>
              </div>
              <div style={{ width: '75%' }}>
                <TextField
                  className='input-field'
                  fullWidth
                  value={formik.values.name}
                  placeholder='Enter Job Title...'
                  onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  error={Boolean(formik.errors.name && formik.touched.name)}
                />
                <div className='mt-0 form-err-message'>{getError(formik, 'name')}</div>
              </div>
            </div>
            <div className='field-container' style={{ marginBottom: '0.5rem' }}>
              <div style={{ width: '25%' }}>
                <Typography sx={{ fontWeight: 600 }}>Experience Range :</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '75%',
                }}>
                <div style={{ width: '35%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.minExperienceLevel || ''}
                      onChange={(e) =>
                        formik.setFieldValue('minExperienceLevel', parseInt(e.target.value))
                      }
                      error={Boolean(
                        formik.errors.minExperienceLevel && formik.touched.minExperienceLevel,
                      )}
                      placeholder='Min'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'minExperienceLevel')}
                  </div>
                </div>
                <div style={{ width: '10%' }}>
                  <Typography
                    style={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    to
                  </Typography>
                </div>
                <div style={{ width: '35%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.maxExperienceLevel || ''}
                      onChange={(e) =>
                        formik.setFieldValue('maxExperienceLevel', parseInt(e.target.value))
                      }
                      error={Boolean(
                        formik.errors.maxExperienceLevel && formik.touched.maxExperienceLevel,
                      )}
                      placeholder='Max'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'maxExperienceLevel')}
                  </div>
                </div>
                <div
                  style={{
                    width: '20%',
                    display: 'flex',
                    alignItems: 'center',
                    height: 'var(--Font-size-h2, 40px)',
                  }}>
                  <Select
                    fullWidth
                    className='input-field'
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginLeft: '0.5rem',
                    }}
                    value={formik.values.experienceType || ''}
                    defaultValue={2}
                    onChange={(e) => formik.setFieldValue('experienceType', e.target.value)}
                    error={Boolean(formik.errors.experienceType && formik.touched.experienceType)}
                    size='small'>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE].map(
                      (experience: any, index: number) => (
                        <MenuItem key={index} value={experience.id}>
                          {experience.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              </div>
            </div>
            <div
              className='field-container'
              style={{ marginBottom: '0.5rem', justifyContent: 'space-between' }}>
              <div style={{ width: '22%' }}>
                <Typography sx={{ fontWeight: 600 }}>Primary Skills:</Typography>
              </div>
              <div style={{ width: '75%' }}>
                <div style={{ marginBottom: '0.62rem' }}>
                  <Autocomplete
                    id='combo-box-demo'
                    options={allSkills}
                    value={formik.values.primarySkills}
                    size='small'
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    ListboxProps={{
                      style: {
                        maxHeight: '150px',
                        overflowY: 'auto',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Enter a skill'
                        sx={{ minHeight: '2.5rem' }}
                        {...params}
                        error={Boolean(formik.errors.primarySkills && formik.touched.primarySkills)}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('primarySkills', val ? val : undefined);
                    }}
                    multiple
                  />
                </div>
                <div className='mt-0 form-err-message'>{getError(formik, 'primarySkills')}</div>
                <div style={{ width: '100%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                  {allSkills.slice(0, 7).map((skill) => (
                    <Chip
                      key={skill.id}
                      label={skill.name}
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '0.63rem',
                        marginRight: '0.63rem',
                        marginBottom: '0.63rem',
                      }}
                      avatar={
                        formik.values.primarySkills.some((s: any) => s.id === skill.id) ? (
                          <DoneIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      onClick={() => handleSkillToggle('primarySkills', skill)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className='field-container' style={{ marginBottom: '0.5rem' }}>
              <div style={{ width: '25%' }}>
                <Typography sx={{ fontWeight: 600 }}>Secondary Skills:</Typography>
              </div>
              <div style={{ width: '75%' }}>
                <div style={{ marginBottom: '0.62rem' }}>
                  <Autocomplete
                    id='combo-box-demo'
                    options={allSkills}
                    value={formik.values.secondarySkills}
                    size='small'
                    getOptionLabel={(option: any) => option.name}
                    filterSelectedOptions
                    ListboxProps={{
                      style: {
                        maxHeight: '150px',
                        overflowY: 'auto',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder='Enter a skill'
                        {...params}
                        error={Boolean(
                          formik.errors.secondarySkills && formik.touched.secondarySkills,
                        )}
                        label=''
                      />
                    )}
                    onChange={(_e, val) => {
                      formik.setFieldValue('secondarySkills', val ? val : undefined);
                    }}
                    multiple
                  />
                </div>
                <div className='mt-0 form-err-message'>{getError(formik, 'secondarySkills')}</div>
                <div style={{ width: '100%' }}>
                  <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                  {(allSkills.length < 7 ? allSkills : allSkills.slice(-7)).map((skill) => (
                    <Chip
                      key={skill.id}
                      label={skill.name}
                      style={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '0.63rem',
                        marginRight: '0.63rem',
                        marginBottom: '0.63rem',
                      }}
                      avatar={
                        formik.values.secondarySkills.some((s: any) => s.id === skill.id) ? (
                          <DoneIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      onClick={() => handleSkillToggle('secondarySkills', skill)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: '0.5rem' }}>
          {askAiForm && (
            <>
              <div
                style={{
                  marginBottom: '0.5rem',
                }}>
                <OutlinedInput
                  fullWidth
                  placeholder='Add your instructions if any'
                  endAdornment={
                    <InputAdornment position='end' sx={{ marginRight: '5px' }}>
                      <IconButton
                        disabled={loading}
                        edge='end'
                        sx={{ backgroundColor: '#D9D9D9' }}
                        onClick={() => {
                          setLoading(true);
                          handleAiGeneration();
                        }}>
                        <ArrowUpwardIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: '0.3125rem',
                    border: '1px solid var(--Secondary-colors-Grey, #868686)',
                  }}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: '0.5rem' }}>
                <span className='feildHeader'>
                  E.g. “Minimum of 5 years in sales operations management”
                </span>
              </div>
            </>
          )}
          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1rem',
            }}>
            <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
          </div>
          <div style={{ gap: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className='button'
              onClick={() => {
                setMenuOpen(false);
              }}
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}>
              Cancel
            </Button>
            <Button
              className='button'
              onClick={() => {
                if (askAiForm) {
                  handleAskAISubmit();
                } else {
                  setAiValidation(true);
                  setIsButtonClicked(!isButtonClicked);
                  setSubmitted(true);
                }
              }}
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '7.375rem',
                height: '2.5rem',
                marginRight: '10px',
                color: 'white !important',
                background: '#2D7DFE !important',
              }}>
              {askAiForm && (
                <FantacyIcon
                  className='fantacy-icon'
                  style={{ marginRight: '7px', marginLeft: '5px' }}
                />
              )}
              {askAiForm ? 'Confirm' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const getPayTypeId = (jobType: any) => {
    const name = jobType.name.toLowerCase();
    if (name === 'full time') return 2;
    if (name === 'consultant') return 1;
    if (name === 'internship') return 3;
  };

  const getPayRate = (jobType: any) => {
    const name = jobType.name.toLowerCase();
    if (name === 'full time') return `${selectedFullTimeFlag} ${formik.values.payFullTimeRate}`;
    if (name === 'consultant')
      return `${selectedConsultantFlag} ${formik.values.payConsultantRate}`;
    if (name === 'internship')
      return `${selectedInternshipFlag} ${formik.values.payInternshipRate}`;
  };

  const handleSkillToggle = (type: string, skill: any) => {
    let currentSkills;

    if (type === 'primarySkills') {
      currentSkills = formik.values.primarySkills;
    } else {
      currentSkills = formik.values.secondarySkills;
    }
    const isSelected = currentSkills.some((s: any) => s.id === skill.id);
    if (isSelected) {
      formik.setFieldValue(
        type,
        currentSkills.filter((s: any) => s !== skill),
      );
    } else {
      formik.setFieldValue(type, [...currentSkills, skill]);
    }
  };

  const jdApprovalAuthorityEamilOptions = [
    { value: 'mandar.diwan@peopletech.com', label: 'Mandar Diwan' },
    { value: 'ajay.dohray@peopletech.com', label: 'Ajay Dohray' },
    { value: 'pranav.prasad@peopletech.com', label: 'Pranav Prasad' },
    { value: 'bob.duffy@peopletech.com', label: 'Bob Duffy' },
    { value: 'ravi.akella@peopletech.com', label: 'Ravi Akella' },
    { value: 'priyanka.erla@peopletech.com', label: 'Priyanka Erla' },
    { value: 'vidyasree.b@peopletech.com', label: 'Bheemavarapu Vidya Sree' },
    { value: 'vandana.prasad@peopletech.com', label: 'Vandana Prasad' },
    { value: 'surendra.kalahasti@rampgroup.com', label: 'Surendra Kalahasti' },
    { value: 'karan.chilka@peopletech.com', label: 'Karan Chilka' },
    { value: 'lakshmana.nandupilli@peopletech.com', label: 'Lakshman Rao' },
    { value: 'vijay.kogilathota@peopletech.com', label: 'Vijay Reddy' },
    { value: 'gajanan.choudhary@peopletech.com', label: 'Gajanan Choudhary' },
  ];

  const AddSjd = () => {
    setLoading(true);
    const formattedJobLocation = formik.values.jobLocation && formik.values.jobLocation.join('][');
    const accountManager = accountManagers.find((e) => e.id === formik.values.accountManager);
    tadAddSjd({
      name: formik.values.name,
      description: formik.values.description,
      jobLocation: `[${formattedJobLocation}]`,
      domainId: formik.values.domainId,
      priorityId: formik.values.priorityId,
      operationId: formik.values.operationId,
      accountManager: accountManager ? [accountManager] : [],
      recruitingManager: formik.values.recruitingManagerId,
      workingModelId: formik.values.workingModelId,
      qualificationId: formik.values.qualificationId,
      minExperienceLevel: formik.values.minExperienceLevel,
      maxExperienceLevel: formik.values.maxExperienceLevel,
      experienceType: formik.values.experienceType,
      jobTypePayType: formik.values.jobTypeId.map((job: any) => ({
        jobTypeId: job.id,
        payTypeId: getPayTypeId(job),
        payRate: getPayRate(job),
      })),
      primarySkills: formik.values.primarySkills.map((skill: any) => ({
        skill: {
          id: skill.id,
          name: skill.name,
        },
      })),
      secondarySkills: formik.values.secondarySkills.map((skill: any) => ({
        skill: {
          id: skill.id,
          name: skill.name,
        },
      })),
      openPositions: formik.values.openPositions && parseInt(formik.values.openPositions),
      projectTypeId: formik.values.projectTypeId,
      clientId: formik.values.clientId,
      projectName: projects.find((project: any) => project.projectId === formik.values.projectName)
        .projectName,
      endDate: formik.values.endDate,
      sponsorshipRequired: formik.values.sponsorshipRequired,
      visaType: formik.values.visaType,
      email: formik.values.projectTypeId == 1 ? '' : formik.values.email,
    })
      .then((_res) => {
        setShowToast(true);
        setLoading(false);
        setToastMsg({
          severity: 'success',
          message: 'Source job requisition created successfully!',
        });
        setTimeout(() => {
          const existingConfig = localStorage.getItem('breadcrumb-config');
          let existing: BreadCrumbConfig[] = [];
          if (existingConfig) {
            existing = JSON.parse(existingConfig);
            existing.splice(existing.length - 1, 1);
            navigate(existing, true);
          } else {
            navigate(
              [
                {
                  label: 'JOB DEFINITIONS',
                  link: '/source-job-definition',
                },
              ],
              true,
            );
          }
        }, 3000);
      })
      .catch(() => {
        setShowToast(true);
        setLoading(false);
        setToastMsg({
          severity: 'warning',
          title: 'Job Requisition Creation Failed!',
          message:
            '<b>Error:</b> Unable to create the job requisition. Please check the details and try again.',
        });
      });
  };

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const handleChipDelete = (type: any, array: any[], chipToDelete: any) => {
    const newValue = array.filter((chip: any) => chip !== chipToDelete);
    formik.setFieldValue(type, newValue);
  };

  useEffect(() => {
    const currentUser = globalObject.userObject;
    setLoading(true);
    const handleRoleBasedUsers = (role: any, setter: any) => {
      return getAllUsers(getIdFromName(role, LookupTypes.TAD_ROLE))
        .then((res) => {
          setter(res.data);
          formik.setFieldValue('accountManager', currentUser.id);
        })
        .catch(() => {
          console.log(`Error fetching ${role}`);
        });
    };

    const accountManagersPromise = handleRoleBasedUsers(
      TADROLES.ACCOUNT_MANAGER,
      setAccountManagers,
    );
    const recruitingManagersPromise = handleRoleBasedUsers(
      TADROLES.RECRUITER_MANAGER,
      setRecruitingManagers,
    );

    const approvalsPromise = tadgetAllApprovals(currentUser.id)
      .then((res) => {
        const approval = res.data;
        if (approval.length) {
          const firstObj = approval[0];
          const uniqueClients = firstObj.clientId;
          const projectTypeId = firstObj.projectTypeId?.id || '';
          const projectsPromise = uniqueClients.id
            ? getprojectsByClientId(uniqueClients.id).then((res) => {
                setProjects(res.data);
                return res.data;
              })
            : Promise.resolve([]);

          const clientsPromise = getClientsByProjectTypeId(projectTypeId).then((res) => {
            setClients(res.data);
            return res.data;
          });

          return Promise.all([projectsPromise, clientsPromise]).then(([projectsData]) => {
            const projectName = projectsData[0]?.projectId || '';
            formik.setValues({
              ...formik.values,
              projectTypeId,
              clientId: uniqueClients.id,
              projectName,
            });

            setIsClientBillable(projectTypeId === 1);
          });
        }
      })
      .catch(() => {
        console.log('Error fetching approvals');
      });

    Promise.all([accountManagersPromise, recruitingManagersPromise, approvalsPromise])
      .then(() => setLoading(false))
      .catch(() => {
        console.log('Error in one or more API calls');
        setLoading(false);
      });
  }, []);

  return (
    <div className='main-div'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '0.5rem' }}>
          <TadPageHeader />
        </div>
        <div style={{ gap: '1.94rem', display: 'flex', flexWrap: 'nowrap' }}>
          <div className='paper' style={{ height: '12.8125rem' }}>
            <Paper sx={{ height: '100%', maxWidth: '17.5625rem' }}>
              <div
                style={{
                  paddingTop: '1.25rem',
                  paddingLeft: '1.12rem',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}>
                <div
                  style={{
                    width: '1.7rem',
                    height: '1.5rem',
                    marginRight: '1.56rem',
                    borderRadius: '11px',
                    backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                  }}>
                  <CheckIcon />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <span style={{ fontSize: '1rem', fontWeight: 600 }}>New Job Requisition</span>

                  <span style={{ fontSize: '0.875rem', fontWeight: 400 }}>
                    {`Outline the specific need for a new hire, detailing the role,
                        responsibilities, justification and how it fits into the company's strategic
                        goals.`}
                  </span>
                </div>
                <ArrowIcon
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    width: '7.5rem',
                    height: '1.5rem',
                    rotate: '270deg',
                  }}
                />
                {/* <img
                  src={require('../../assets/sjd/chevron_right.svg')}
                  style={{ marginLeft: '20px', marginRight: '10px' }}
                /> */}
              </div>
            </Paper>
          </div>
          <div style={{ width: '100%', display: 'flex', gap: '1.63rem', flexDirection: 'column' }}>
            <div
              className='paper'
              style={{
                height: '8.8125rem',
                width: '100%',
              }}>
              <Paper
                className='new-job-requisition'
                sx={{
                  width: '100%',
                  height: '100%',
                }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '1.88rem',
                    gap: '7px',
                  }}>
                  <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>New Job Requisition</span>
                  <span
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: 400,
                      color: 'var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686))',
                    }}>
                    Creating a job allows you to add candidates and advertise it on your career page
                    and job boards.
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginRight: '2.5rem',
                    alignItems: 'center',
                    gap: '2.31rem',
                  }}>
                  <div
                    style={{
                      paddingRight: '0.5rem',
                      height: 'var(--Font-size-h2, 2.5rem)',
                      borderRadius: '0.3125rem',
                      border: '1px solid #2D7DFE',
                      background: 'var(--Secondary-colors-White, #FFF)',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={async () => {
                      // setAiValidation(true);
                      setMenuOpen(true);
                      // setIsButtonClicked(!isButtonClicked);
                      // setSubmitted(true);
                    }}>
                    <FantacyIcon style={{ marginRight: '7px', marginLeft: '5px' }} />
                    <span style={{ fontWeight: 400, fontSize: '1rem' }}>
                      Ask AI to write a job requisition
                    </span>
                  </div>
                  {/* <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <DotsIcon />
                  </IconButton> */}
                  <Menu
                    anchorEl={anchorEl}
                    sx={{
                      border: '1px solid var(--Secondary-Grey-2, #E7E7E7)',
                      boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}>
                    <MenuItem>
                      <ListItemText primary={'Share'} />
                    </MenuItem>
                    <MenuItem>
                      <ListItemText primary={'Download as PDF'} />
                    </MenuItem>
                  </Menu>
                </div>
                <Drawer
                  sx={{ width: '15.0625rem', height: '64rem', flexShrink: 0 }}
                  anchor='right'
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}>
                  {DrawerList}
                </Drawer>
              </Paper>
            </div>
            <div style={{ height: '100%', width: '100%' }}>
              <Paper className='paper' sx={{ width: '100%', height: '100%' }}>
                <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem' }}>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Job Title *</Typography>
                      <span className='feildHeader'>
                        A concise label that summarizes the primary role
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <TextField
                        className='input-field'
                        fullWidth
                        value={formik.values.name}
                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                        error={Boolean(formik.errors.name && formik.touched.name)}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'name')}</div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Roles & Responsibilities</Typography>
                      <span className='feildHeader'>
                        Role Synopsis, Key Responsibilities & Qualifications
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <TextEditorField
                        value={formik.values.description}
                        selectedValue={(value) => {
                          // setDescription(value?.replace(/<[^>]+>/g, ''));
                          formik.setFieldValue('description', value);
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'description')}</div>
                      <div></div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Job Location *</Typography>
                      <span className='feildHeader'>Pick one or multiple options</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      {' '}
                      <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                        <NewLocationAutoComplete
                          onSelect={handleLocationSelect}
                          initialValue={formik.values.jobLocation}
                          width='35.4375rem !important'
                          error={Boolean(formik.errors.jobLocation && formik.touched.jobLocation)}
                        />
                      </div>
                      <div className='form-err-message'>{getError(formik, 'jobLocation')}</div>
                      {formik.values.jobLocation?.map((location, index) => (
                        <Chip
                          key={index}
                          label={location}
                          style={{
                            backgroundColor: '#D9D9D9',
                            borderRadius: '0.63rem',
                            marginRight: '0.5rem',
                          }}
                          onDelete={() =>
                            handleChipDelete('jobLocation', formik.values.jobLocation, location)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Domain *</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={globalObject.lookupvalues[LookupTypes.TAD_DOMAIN] as OptionType[]}
                        value={
                          formik.values.domainId
                            ? globalObject.lookupvalues[LookupTypes.TAD_DOMAIN].find(
                                (option: OptionType) => option.id === formik.values.domainId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        ListboxProps={{
                          style: {
                            maxHeight: '150px',
                            overflowY: 'auto',
                          },
                        }}
                        onBlur={() => formik.setFieldTouched('domainId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(formik.errors.domainId && formik.touched.domainId)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('domainId', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'domainId')}</div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Experience Range </Typography>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '50%',
                      }}>
                      <div style={{ width: '35%' }}>
                        <div className='input-field'>
                          <TextField
                            fullWidth
                            value={formik.values.minExperienceLevel || ''}
                            onChange={(e) =>
                              formik.setFieldValue('minExperienceLevel', parseInt(e.target.value))
                            }
                            error={Boolean(
                              formik.errors.minExperienceLevel && formik.touched.minExperienceLevel,
                            )}
                            placeholder='Min'
                            style={{
                              height: 'var(--Font-size-h2, 2.5rem)',
                            }}
                          />
                        </div>
                        <div className='mt-0 form-err-message'>
                          {getError(formik, 'minExperienceLevel')}
                        </div>
                      </div>
                      <div style={{ width: '10%' }}>
                        <Typography
                          style={{
                            height: 'var(--Font-size-h2, 2.5rem)',
                            display: 'flex',
                            justifyContent: 'center',
                          }}>
                          to
                        </Typography>
                      </div>
                      <div style={{ width: '35%' }}>
                        <div className='input-field'>
                          <TextField
                            fullWidth
                            value={formik.values.maxExperienceLevel || ''}
                            onChange={(e) =>
                              formik.setFieldValue('maxExperienceLevel', parseInt(e.target.value))
                            }
                            error={Boolean(
                              formik.errors.maxExperienceLevel && formik.touched.maxExperienceLevel,
                            )}
                            placeholder='Max'
                            style={{
                              height: 'var(--Font-size-h2, 2.5rem)',
                            }}
                          />
                        </div>
                        <div className='mt-0 form-err-message'>
                          {getError(formik, 'maxExperienceLevel')}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '20%',
                          display: 'flex',
                          alignItems: 'center',
                          height: 'var(--Font-size-h2, 40px)',
                        }}>
                        <Select
                          fullWidth
                          className='input-field'
                          sx={{
                            height: 'var(--Font-size-h2, 2.5rem)',
                            marginLeft: '0.5rem',
                          }}
                          value={formik.values.experienceType || ''}
                          defaultValue={2}
                          onChange={(e) => formik.setFieldValue('experienceType', e.target.value)}
                          error={Boolean(
                            formik.errors.experienceType && formik.touched.experienceType,
                          )}
                          size='small'>
                          <MenuItem value='' disabled>
                            Choose...
                          </MenuItem>
                          {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE].map(
                            (experience: any, index: number) => (
                              <MenuItem key={index} value={experience.id}>
                                {experience.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Priority</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[LookupTypes.TAD_PRIORITY] as OptionType[]
                        }
                        value={
                          formik.values.priorityId
                            ? globalObject.lookupvalues[LookupTypes.TAD_PRIORITY].find(
                                (option: OptionType) => option.id === formik.values.priorityId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('priorityId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            // error={Boolean(formik.errors.priorityId && formik.touched.priorityId)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('priorityId', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      {/* <div className='mt-0 form-err-message'>{getError(formik, 'priorityId')}</div> */}
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Operations *</Typography>
                      <span className='feildHeader'>Choose India or US</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[LookupTypes.TAD_OPERATION] as OptionType[]
                        }
                        value={
                          formik.values.operationId
                            ? globalObject.lookupvalues[LookupTypes.TAD_OPERATION].find(
                                (option: OptionType) => option.id === formik.values.operationId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('operationId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(formik.errors.operationId && formik.touched.operationId)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('operationId', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'operationId')}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>
                  {globalObject.userObject.roles[0].id ===
                    getIdFromName('account manager', LookupTypes.TAD_ROLE) && (
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Account Manager</Typography>
                      </div>
                      <div style={{ width: '50%' }}>
                        <div className='input-field'>
                          <Autocomplete
                            id='combo-box-demo'
                            options={accountManagers || []}
                            value={
                              accountManagers.find((e) => e.id === formik.values.accountManager) ||
                              null
                            }
                            size='small'
                            limitTags={1}
                            getOptionLabel={(option: any) =>
                              `${option.firstName} ${option.lastName}`
                            }
                            filterSelectedOptions
                            ListboxProps={{
                              style: {
                                maxHeight: '150px',
                                overflowY: 'auto',
                              },
                            }}
                            onBlur={() => formik.setFieldTouched('accountManager', true, true)}
                            renderInput={(params) => (
                              <TextField
                                placeholder='Choose...'
                                {...params}
                                // error={Boolean(
                                //   formik.errors.hiringManagers && formik.touched.hiringManagers,
                                // )}
                                label=''
                              />
                            )}
                            onChange={(_e, val) => {
                              formik.setFieldValue('accountManager', val ? val.id : undefined);
                            }}
                            classes={{
                              popper: 'custom-popper',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Recruiting Manager *</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div className='input-field'>
                        <Autocomplete
                          id='combo-box-demo'
                          options={recruitingManagers}
                          value={formik.values.recruitingManagerId}
                          size='small'
                          limitTags={1}
                          getOptionLabel={(option: any) => `${option.firstName} ${option.lastName}`}
                          filterSelectedOptions
                          ListboxProps={{
                            style: {
                              maxHeight: '150px',
                              overflowY: 'auto',
                            },
                          }}
                          onBlur={() => formik.setFieldTouched('recruitingManagerId', true, true)}
                          renderInput={(params) => (
                            <TextField
                              placeholder='Choose...'
                              {...params}
                              error={Boolean(
                                formik.errors.recruitingManagerId &&
                                  formik.touched.recruitingManagerId,
                              )}
                              label=''
                            />
                          )}
                          onChange={(_e, val) => {
                            formik.setFieldValue('recruitingManagerId', val ? val : undefined);
                          }}
                          classes={{
                            popper: 'custom-popper',
                          }}
                        />
                      </div>
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'recruitingManagerId')}
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Employment Type *</Typography>
                      <span className='feildHeader'>Pick one or multiple options</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={globalObject.lookupvalues[LookupTypes.TAD_JOB_TYPE]}
                        value={formik.values.jobTypeId}
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('jobTypeId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(formik.errors.jobTypeId && formik.touched.jobTypeId)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('jobTypeId', val ? val : undefined);
                        }}
                        multiple
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'jobTypeId')}</div>
                      {formik.values.jobTypeId?.map((jobType: any) => (
                        <Chip
                          key={jobType.id}
                          label={jobType.name}
                          style={{
                            backgroundColor: '#D9D9D9',
                            borderRadius: '0.63rem',
                            marginRight: '0.63rem',
                            marginTop: '0.5rem',
                          }}
                          onDelete={() =>
                            handleChipDelete('jobTypeId', formik.values.jobTypeId, jobType)
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Working Model</Typography>
                      <span className='feildHeader'>Choose Remote or Hybrid or Onsite</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[LookupTypes.TAD_WORKING_MODEL] as OptionType[]
                        }
                        value={
                          formik.values.workingModelId
                            ? globalObject.lookupvalues[LookupTypes.TAD_WORKING_MODEL].find(
                                (option: OptionType) => option.id === formik.values.workingModelId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('workingModelId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            // error={Boolean(
                            //   formik.errors.workingModelId && formik.touched.workingModelId,
                            // )}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('workingModelId', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      {/* <div className='mt-0 form-err-message'>
                        {getError(formik, 'workingModelId')}
                      </div> */}
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Preferred Qualification</Typography>
                      <span className='feildHeader'>
                        Choose required qualification of the candidate
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION] as OptionType[]
                        }
                        value={
                          formik.values.qualificationId
                            ? globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION].find(
                                (option: OptionType) => option.id === formik.values.qualificationId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        ListboxProps={{
                          style: {
                            maxHeight: '150px',
                            overflowY: 'auto',
                          },
                        }}
                        onBlur={() => formik.setFieldTouched('qualificationId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            // error={Boolean(
                            //   formik.errors.qualificationId && formik.touched.qualificationId,
                            // )}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('qualificationId', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                    </div>
                  </div>
                  {formik.values.jobTypeId?.some(
                    (job: any) => job.name.toLowerCase() === 'full time',
                  ) && (
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Salary (Full-time)</Typography>
                        <span className='feildHeader'>
                          Choose how you prefer to pay for this job
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <CountryCodes
                            selectedCountry={selectedFullTimeFlag}
                            setSelectedCountry={(value) => setSelectedFullTimeFlag(value)}
                            code={false}
                          />
                          <TextField
                            className='salaryType'
                            style={{ borderRadius: '0px 0px 0px 0px' }}
                            inputProps={{ maxLength: 10 }}
                            sx={{ width: '68%' }}
                            value={formik.values.payFullTimeRate?.replace(/\D/g, '') || ''}
                            onChange={(e) =>
                              formik.setFieldValue('payFullTimeRate', e.target.value)
                            }
                            fullWidth
                            id='phoneNumber'
                            placeholder='0'
                            size='small'
                            name='phoneNumber'
                          />
                          <TextField
                            className='salaryContact'
                            sx={{ width: '17%', color: 'black' }}
                            value={'Annually'}
                            disabled
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                          />
                        </div>
                        <div className='mt-0 form-err-message'>
                          {getError(formik, 'payFullTimeRate')}
                        </div>
                      </div>
                    </div>
                  )}
                  {formik.values.jobTypeId?.some(
                    (job: any) => job.name.toLowerCase() === 'consultant',
                  ) && (
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Salary (Consultant)</Typography>
                        <span className='feildHeader'>
                          Choose how you prefer to pay for this job
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                          <CountryCodes
                            selectedCountry={selectedConsultantFlag}
                            setSelectedCountry={(value) => setSelectedConsultantFlag(value)}
                            code={false}
                          />
                          <TextField
                            className='salaryType'
                            placeholder='0'
                            style={{ borderRadius: '0px 0px 0px 0px' }}
                            sx={{ width: '68%' }}
                            inputProps={{ maxLength: 5 }}
                            value={formik.values.payConsultantRate?.replace(/\D/g, '') || ''}
                            onChange={(e) =>
                              formik.setFieldValue('payConsultantRate', e.target.value)
                            }
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                          />
                          <TextField
                            className='salaryContact'
                            sx={{ width: '17%', color: 'black' }}
                            value={'Hourly'}
                            onChange={(e) => formik.setFieldValue('payTypeId', e.target.value)}
                            disabled
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {formik.values.jobTypeId?.some(
                    (job: any) => job.name.toLowerCase() === 'internship',
                  ) && (
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Salary (Internship)</Typography>
                        <span className='feildHeader'>
                          Choose how you prefer to pay for this job
                        </span>
                      </div>
                      <div style={{ width: '50%' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                          <CountryCodes
                            selectedCountry={selectedInternshipFlag}
                            setSelectedCountry={(value) => setSelectedInternshipFlag(value)}
                            code={false}
                          />
                          <TextField
                            className='salaryType'
                            placeholder='0'
                            style={{ borderRadius: '0px 0px 0px 0px' }}
                            sx={{ width: '68%' }}
                            inputProps={{ maxLength: 7 }}
                            value={formik.values.payInternshipRate?.replace(/\D/g, '') || ''}
                            onChange={(e) =>
                              formik.setFieldValue('payInternshipRate', e.target.value)
                            }
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                          />
                          <TextField
                            className='salaryContact'
                            sx={{ width: '17%', color: 'black' }}
                            value={'Monthly'}
                            onChange={(e) => formik.setFieldValue('payTypeId', e.target.value)}
                            disabled
                            fullWidth
                            id='phoneNumber'
                            size='small'
                            name='phoneNumber'
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Primary Skills *</Typography>
                      <span className='feildHeader'>Add skills suggested by AI</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div style={{ marginBottom: '0.62rem' }}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={allSkills}
                          value={formik.values.primarySkills}
                          size='small'
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          ListboxProps={{
                            style: {
                              maxHeight: '150px',
                              overflowY: 'auto',
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              placeholder='Enter a skill'
                              sx={{ minHeight: '2.5rem' }}
                              {...params}
                              error={Boolean(
                                formik.errors.primarySkills && formik.touched.primarySkills,
                              )}
                              label=''
                            />
                          )}
                          onChange={(_e, val) => {
                            formik.setFieldValue('primarySkills', val ? val : undefined);
                          }}
                          multiple
                          classes={{
                            popper: 'custom-popper',
                          }}
                        />
                      </div>
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'primarySkills')}
                      </div>
                      <div style={{ width: '100%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                        {allSkills.slice(0, 7).map((skill) => (
                          <Chip
                            key={skill.id}
                            label={skill.name}
                            style={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '0.63rem',
                              marginRight: '0.63rem',
                              marginBottom: '0.63rem',
                            }}
                            avatar={
                              formik.values.primarySkills.some((s: any) => s.id === skill.id) ? (
                                <DoneIcon />
                              ) : (
                                <AddIcon />
                              )
                            }
                            onClick={() => handleSkillToggle('primarySkills', skill)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Secondary Skills *</Typography>
                      <span className='feildHeader'>Add skills suggested by AI</span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div style={{ marginBottom: '0.62rem' }}>
                        <Autocomplete
                          id='combo-box-demo'
                          options={allSkills}
                          value={formik.values.secondarySkills}
                          size='small'
                          getOptionLabel={(option: any) => option.name}
                          filterSelectedOptions
                          ListboxProps={{
                            style: {
                              maxHeight: '150px',
                              overflowY: 'auto',
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              placeholder='Enter a skill'
                              {...params}
                              error={Boolean(
                                formik.errors.secondarySkills && formik.touched.secondarySkills,
                              )}
                              label=''
                            />
                          )}
                          onChange={(_e, val) => {
                            formik.setFieldValue('secondarySkills', val ? val : undefined);
                          }}
                          multiple
                          classes={{
                            popper: 'custom-popper',
                          }}
                        />
                      </div>
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'secondarySkills')}
                      </div>
                      <div style={{ width: '100%' }}>
                        <Typography sx={{ fontWeight: 600 }}>Skill Suggestions</Typography>
                        {(allSkills.length < 7 ? allSkills : allSkills.slice(-7)).map((skill) => (
                          <Chip
                            key={skill.id}
                            label={skill.name}
                            style={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '0.63rem',
                              marginRight: '0.63rem',
                              marginBottom: '0.63rem',
                            }}
                            avatar={
                              formik.values.secondarySkills.some((s: any) => s.id === skill.id) ? (
                                <DoneIcon />
                              ) : (
                                <AddIcon />
                              )
                            }
                            onClick={() => handleSkillToggle('secondarySkills', skill)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Open Positions</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <TextField
                        type='number'
                        name='openPositions'
                        className='input-field'
                        fullWidth
                        value={formik.values.openPositions || ''}
                        onChange={(e) => formik.setFieldValue('openPositions', e.target.value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.openPositions && Boolean(formik.errors.openPositions)}
                        helperText={
                          formik.touched.openPositions && formik.errors.openPositions
                        }></TextField>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '6.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Project Billing Types *</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[
                            LookupTypes.TAD_PROJECT_BILLING_TYPE
                          ] as OptionType[]
                        }
                        value={
                          formik.values.projectTypeId
                            ? globalObject.lookupvalues[LookupTypes.TAD_PROJECT_BILLING_TYPE].find(
                                (option: OptionType) => option.id === formik.values.projectTypeId,
                              )
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('projectTypeId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(
                              formik.errors.projectTypeId && formik.touched.projectTypeId,
                            )}
                            label=''
                          />
                        )}
                        onChange={(e, val) => {
                          if (val === null) {
                            formik.setFieldValue('projectTypeId', null);
                            formik.setFieldValue('clientId', null);
                            formik.setFieldValue('email', '');
                            setClients([]);
                            formik.setFieldValue('projectName', undefined);
                            setProjects([]);
                          } else {
                            const isClientBillable = val?.id === 1;
                            setIsClientBillable(isClientBillable);
                            isClientBillable && formik.setFieldValue('email', '');
                            getClientsByProjectTypeId(val ? val.id : undefined).then((res) => {
                              setClients(res.data);
                              setProjects([]);
                            });
                            formik.setFieldValue('projectTypeId', val ? val.id : undefined);
                            formik.setFieldValue('clientId', null);
                          }
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'projectTypeId')}
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Client *</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='clientId'
                        disabled={!formik.values.projectTypeId}
                        options={clients}
                        value={
                          formik.values.clientId
                            ? clients.find((client: any) => client.id === formik.values.clientId) ||
                              null
                            : null
                        }
                        size='small'
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        ListboxProps={{
                          style: {
                            maxHeight: '150px',
                            overflowY: 'auto',
                          },
                        }}
                        onBlur={() => formik.setFieldTouched('clientId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            // error={Boolean(formik.errors.clientId && formik.touched.clientId)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('clientId', val ? val.id : undefined);
                          formik.setFieldValue('projectName', undefined);
                          if (val) {
                            getprojectsByClientId(val.id).then((res) => {
                              setProjects(res.data);
                            });
                          }
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'clientId')}</div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Project Name</Typography>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='projectName'
                        options={projects}
                        value={
                          formik.values.projectName
                            ? projects.find(
                                (project: any) => project.projectId === formik.values.projectName,
                              ) || null
                            : null
                        }
                        size='small'
                        limitTags={1}
                        disabled={!formik.values.clientId}
                        getOptionLabel={(option: any) => option.projectName}
                        filterSelectedOptions
                        ListboxProps={{
                          style: {
                            maxHeight: '150px',
                            overflowY: 'auto',
                          },
                        }}
                        // onBlur={() => formik.setFieldTouched('projectName', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(formik.errors.projectName && formik.touched.projectName)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('projectName', val ? val.projectId : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                      <div className='mt-0 form-err-message'>{getError(formik, 'projectName')}</div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Target Date *</Typography>
                    </div>
                    <div
                      className='input-field'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                        width: '50%',
                        padding: '9px 0px 0px 0px',
                      }}>
                      <DatePicker
                        className='datepickers'
                        value={formik.values.endDate ? dayjs(formik.values.endDate) : ''}
                        onChange={(value) => {
                          formik.setFieldValue(
                            'endDate',
                            dateFormat(value?.toString(), 'isoDateTime'),
                            true,
                          );
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(formik.errors.endDate && formik.touched.endDate),
                            sx: {
                              width: '100%',
                              height: 'var(--Font-size-h2, 2.5rem)',
                            },
                          },
                        }}
                        sx={{
                          width: '100%',
                          height: 'var(--Font-size-h2, 2.5rem)',
                        }}></DatePicker>
                      <div className='mt-0 form-err-message'>{getError(formik, 'endDate')}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Sponsorship Required?</Typography>
                    </div>
                    <div style={{ height: 'var(--Font-size-h2, 2.5rem)', width: '50%' }}>
                      <RadioGroup
                        row
                        aria-labelledby='demo-controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={formik.values.sponsorshipRequired}
                        onChange={(e) =>
                          formik.setFieldValue('sponsorshipRequired', e.target.value === 'true')
                        }>
                        <FormControlLabel value={false} control={<Radio />} label='No' />
                        <FormControlLabel value={true} control={<Radio />} label='Yes' />
                      </RadioGroup>
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'sponsorshipRequired')}
                      </div>
                    </div>
                  </div>
                  <div className='field-container'>
                    <div style={{ width: '50%' }}>
                      <Typography sx={{ fontWeight: 600 }}>Visa Type</Typography>
                      <span className='feildHeader'>
                        Check for Right to work with specific visa type
                      </span>
                    </div>
                    <div style={{ width: '50%' }}>
                      <Autocomplete
                        className='input-field'
                        id='combo-box-demo'
                        options={
                          globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE] as OptionType[]
                        }
                        value={
                          formik.values.visaType
                            ? globalObject.lookupvalues[LookupTypes.TAD_VISA_TYPE].find(
                                (option: OptionType) => option.id === formik.values.visaType,
                              )
                            : null
                        }
                        disabled={!formik.values.sponsorshipRequired}
                        sx={{
                          width: '50%',
                          background: formik.values.sponsorshipRequired
                            ? '#fff'
                            : 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                        }}
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        ListboxProps={{
                          style: {
                            maxHeight: '150px',
                            overflowY: 'auto',
                          },
                        }}
                        onBlur={() => formik.setFieldTouched('visaType', true, true)}
                        renderInput={(params) => (
                          <TextField
                            placeholder='Choose...'
                            {...params}
                            // error={Boolean(formik.errors.visaType && formik.touched.visaType)}
                            label=''
                          />
                        )}
                        onChange={(_e, val) => {
                          formik.setFieldValue('visaType', val ? val.id : undefined);
                        }}
                        classes={{
                          popper: 'custom-popper',
                        }}
                      />
                    </div>
                  </div>
                  {!isClientBillable && formik.values.projectTypeId && (
                    <div className='field-container'>
                      <div style={{ width: '50%' }}>
                        <Typography sx={{ fontWeight: 600 }}>JD Approval Authority *</Typography>
                      </div>
                      <div style={{ width: '50%' }}>
                        <Select
                          className='input-field'
                          name='email'
                          id='email'
                          fullWidth
                          placeholder='Choose...'
                          value={formik.values.email || ''}
                          onChange={(e) => formik.setFieldValue('email', e.target.value)}
                          onBlur={formik.handleBlur}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: '150px',
                                overflowY: 'auto',
                              },
                            },
                          }}>
                          <MenuItem disabled value={''}>
                            Choose or type
                          </MenuItem>
                          {jdApprovalAuthorityEamilOptions.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.email && formik.errors.email && (
                          <div className='form-err-message'>{formik.errors.email}</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      gap: '24.56rem',
                      alignItems: 'center',
                      marginBottom: '1.87rem',
                    }}>
                    <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '6.56rem',
                      alignItems: 'center',
                      marginBottom: '3.31rem',
                      justifyContent: 'flex-end',
                    }}>
                    <div style={{ gap: '20px', display: 'flex' }}>
                      <Button
                        className='button'
                        onClick={() => {
                          navigate([
                            {
                              label: 'JOB DEFINITIONS',
                              link: '/source-job-definition',
                            },
                          ]);
                        }}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '10vw',
                            md: '7.375rem',
                          },
                          minWidth: '7.375rem',
                          height: '2.5rem',
                          borderRadius: '5px',
                          border: '1px solid #2D7DFE',
                          backgroundColor: '#FFF',
                          color: 'black',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: 400,
                        }}>
                        Cancel
                      </Button>
                      <Button
                        className='button'
                        onClick={() => {
                          setSubmitted(true);
                          setAiValidation(false);
                          setIsButtonClicked(!isButtonClicked);
                        }}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '10vw',
                            md: '7.375rem',
                          },
                          textTransform: 'none',
                          fontWeight: 400,
                          fontSize: '1rem',
                          minWidth: '7.375rem',
                          height: '2.5rem',
                          marginRight: '10px',
                          color: 'white !important',
                          background: '#2D7DFE !important',
                        }}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
              <TadScreenLoader loading={loading} />
              <Notify
                sx={{ width: '29.375rem', height: '15.0625rem' }}
                open={showToast}
                onClose={() => setShowToast(false)}
                severity={toastMsg.severity}
                title={toastMsg.title}
                message={toastMsg.message}
                actionButtons={toastMsg.actionButtons}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TadSourceJobRequisition;
