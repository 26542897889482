import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Select,
  Chip,
  MenuItem,
  Button,
  IconButton,
} from '@mui/material';

import { parseLocation, scrollToTop } from '../../common/commonUtil';
import { FormikProps, useFormik } from 'formik';
import { TadSourcingStrategy } from '../../models/tad-sjd-form-model';
import * as Yup from 'yup';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import { ReactComponent as DoneIcon } from '../../assets/sjd/done.svg';
import { ReactComponent as MoreIcon } from '../../assets/dashboard-assets/more_vert.svg';
import { getNameFromId } from '../../../common/commonUtil';
import useNavigate from '../../../common/useNavigate';

interface SourcingStrategyProps {
  previous?: () => void;
  sourcingDetails: TadSourcingStrategy | undefined;
  save?: (values: TadSourcingStrategy) => Promise<boolean>;
  mode?: 'edit-page' | 'details-page';
}

interface OptionType {
  id: number;
  name: string;
}

const SourcingStrategy: React.FC<SourcingStrategyProps> = ({
  previous = () => {},
  sourcingDetails,
  save,
  mode = 'edit-page',
}: SourcingStrategyProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [edit, setEdit] = useState(mode === 'edit-page');
  const [navigate] = useNavigate();

  const sourcingStrategySchema = Yup.object().shape({
    sourcingChannels: Yup.array()
      .min(1, 'sourcing channels is required')
      .required('sourcing channels is required'),
    minSalaryRange: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .max(9999999, 'Max salary range cannot exceed 1 crore')
      .required('Min salary range is required')
      .lessThan(Yup.ref('maxSalaryRange'), 'Min salary range should be less than max salary range'),
    maxSalaryRange: Yup.number()
      .typeError('The value must be a number')
      .min(1, 'Value must be positive')
      .max(10000000, 'Max salary range cannot exceed 1 crore')
      .required('max salary range is required')
      .moreThan(Yup.ref('minSalaryRange'), 'Max salary range should be more than min salary range'),
    minExperienceLevel: Yup.number()
      .integer('The value must be a number')
      .typeError('The value must be a number')
      .required('min experience is required')
      .lessThan(Yup.ref('maxExperienceLevel'), 'Min experience should be less than max experience'),
    maxExperienceLevel: Yup.number()
      .typeError('The value must be a number')
      .min(0, 'Value must be positive')
      .required('max experience is required')
      .moreThan(Yup.ref('minExperienceLevel'), 'Max experience should be more than min experience'),
    keywords: Yup.string().required('keywords is required'),
    targetCompanies: Yup.array().of(Yup.object()).min(1, 'target companies is required').required(),
    noticePeriod: Yup.number()
      .typeError('Notice period must be a number')
      .nullable()
      .max(999, 'Notice period must be less than 3 digits'),
  });
  const formik = useFormik({
    initialValues: {
      sourcingChannels: sourcingDetails?.sourcingChannels || [],
      minSalaryRange: sourcingDetails?.minSalaryRange,
      maxSalaryRange: sourcingDetails?.maxSalaryRange,
      minExperienceLevel: sourcingDetails?.minExperienceLevel,
      maxExperienceLevel: sourcingDetails?.maxExperienceLevel,
      salaryRangeType: sourcingDetails?.salaryRangeType || 2,
      experienceType: sourcingDetails?.experienceType || 1,
      keywords: sourcingDetails?.keywords,
      targetCompanies: sourcingDetails?.targetCompanies || [],
      qualificationId: sourcingDetails?.qualificationId,
      noticePeriod: sourcingDetails?.noticePeriod,
    },
    onSubmit: (values) => {
      save !== undefined && save(values);
    },
    validationSchema: sourcingStrategySchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const handleChipDelete = (type: any, array: any[], chipToDelete: any) => {
    const newValue = array.filter((chip: any) => chip !== chipToDelete);
    formik.setFieldValue(type, newValue);
  };

  const handleKeyWordsToggle = (type: string, keyword: any) => {
    const keywords = formik.values.keywords ? parseLocation(formik.values.keywords) : [];

    const isSelected = keywords.some((s: any) => s === keyword);
    if (isSelected) {
      formik.setFieldValue(type, `[${keywords.filter((s: any) => s !== keyword).join('][')}]`);
    } else {
      const list = [...keywords, keyword];
      formik.setFieldValue(type, `[${list.join('][')}]`);
    }
  };

  const keywords = [
    'Account Management',
    'User Experience',
    'User Interface',
    'Information Security',
    'Azure DevOps',
    'Advanced Java',
  ];

  return (
    <Paper className='paper' sx={{ width: '100%' }}>
      {mode === 'details-page' && (
        <div className='header-container'>
          <div className='header-content-title'>
            <span>Sourcing Strategy</span>
          </div>
          <div className='header-content-icon'>
            <IconButton
              onClick={() => {
                edit == false ? setEdit(true) : setEdit(false);
              }}>
              <MoreIcon />
            </IconButton>
          </div>
        </div>
      )}
      <div style={{ padding: '2.69rem 1.94rem 2.19rem 1.88rem' }}>
        <div
          style={{
            display: 'flex',
            // gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Sourcing Channel *</Typography>
              <span className='feildHeader'>Pick one or multiple options</span>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Sourcing Channel : </Typography>
            </div>
          )}
          {edit ? (
            <div style={{ width: '50%' }}>
              {' '}
              <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                <Autocomplete
                  fullWidth
                  id='combo-box-demo'
                  options={globalObject.lookupvalues[LookupTypes.TAD_SOURCE_CHANNEL]}
                  value={formik.values.sourcingChannels}
                  size='small'
                  limitTags={1}
                  getOptionLabel={(option: any) => option.name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.sourcingChannels)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('sourcingChannels', val ? val : undefined);
                  }}
                  multiple
                />
              </div>
              <div className='mt-0 form-err-message'>{getError(formik, 'sourcingChannels')}</div>
            </div>
          ) : (
            <span className='text-label'>
              {formik.values.sourcingChannels.map((e) => e.name).join(',')}
            </span>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '1.87rem' }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Salary Range *</Typography>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Salary Range :</Typography>
            </div>
          )}
          {edit ? (
            <div
              style={{
                display: 'flex',
                width: '50%',
                alignItems: 'center',
                marginBottom: '1.94rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}>
                <div style={{ width: '30%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.minSalaryRange || ''}
                      onChange={(e) =>
                        formik.setFieldValue('minSalaryRange', parseInt(e.target.value))
                      }
                      error={Boolean(formik.errors.minSalaryRange && formik.touched.minSalaryRange)}
                      placeholder='Min'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'minSalaryRange')}</div>
                </div>
                <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    style={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '0.5rem',
                      marginLeft: '0.5rem',
                    }}>
                    to
                  </Typography>
                </div>
                <div style={{ width: '30%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.maxSalaryRange || ''}
                      onChange={(e) =>
                        formik.setFieldValue('maxSalaryRange', parseInt(e.target.value))
                      }
                      error={Boolean(formik.errors.maxSalaryRange && formik.touched.maxSalaryRange)}
                      placeholder='Max'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>{getError(formik, 'maxSalaryRange')}</div>
                </div>
                <div
                  style={{
                    width: '30%',
                    marginLeft: '0.5rem',
                  }}>
                  <Select
                    fullWidth
                    className='input-field'
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '10px',
                    }}
                    size='small'
                    value={formik.values.salaryRangeType || ''}
                    defaultValue={2}
                    onChange={(e) => formik.setFieldValue('salaryRangeType', e.target.value)}
                    error={Boolean(
                      formik.errors.salaryRangeType && formik.touched.salaryRangeType,
                    )}>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_PAY_TYPE].map(
                      (payType: any, index: number) => (
                        <MenuItem key={index} value={payType.id}>
                          {payType.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <span className='text-label'>{`${formik.values.minSalaryRange} to ${formik.values.maxSalaryRange}`}</span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Experience Range *</Typography>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Experience Range: </Typography>
            </div>
          )}

          {edit ? (
            <div
              style={{
                display: 'flex',
                width: '50%',
                alignItems: 'center',
                marginBottom: '1.94rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}>
                <div style={{ width: '30%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.minExperienceLevel || ''}
                      onChange={(e) =>
                        formik.setFieldValue('minExperienceLevel', parseInt(e.target.value))
                      }
                      error={Boolean(
                        formik.errors.minExperienceLevel && formik.touched.minExperienceLevel,
                      )}
                      placeholder='Min'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'minExperienceLevel')}
                  </div>
                </div>
                <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    style={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginRight: '0.5rem',
                      marginLeft: '0.5rem',
                    }}>
                    to
                  </Typography>
                </div>
                <div style={{ width: '30%' }}>
                  <div className='input-field'>
                    <TextField
                      fullWidth
                      value={formik.values.maxExperienceLevel || ''}
                      onChange={(e) =>
                        formik.setFieldValue('maxExperienceLevel', parseInt(e.target.value))
                      }
                      error={Boolean(
                        formik.errors.maxExperienceLevel && formik.touched.maxExperienceLevel,
                      )}
                      placeholder='Max'
                      style={{
                        height: 'var(--Font-size-h2, 2.5rem)',
                      }}
                    />
                  </div>
                  <div className='mt-0 form-err-message'>
                    {getError(formik, 'maxExperienceLevel')}
                  </div>
                </div>
                <div
                  style={{
                    width: '30%',
                    height: 'var(--Font-size-h2, 40px)',
                  }}>
                  <Select
                    fullWidth
                    className='input-field'
                    sx={{
                      height: 'var(--Font-size-h2, 2.5rem)',
                      marginLeft: '0.5rem',
                    }}
                    value={formik.values.experienceType || ''}
                    defaultValue={2}
                    onChange={(e) => formik.setFieldValue('experienceType', e.target.value)}
                    error={Boolean(formik.errors.experienceType && formik.touched.experienceType)}
                    size='small'>
                    <MenuItem value='' disabled>
                      Choose...
                    </MenuItem>
                    {globalObject.lookupvalues[LookupTypes.TAD_EXPERIENCE_TYPE].map(
                      (experience: any, index: number) => (
                        <MenuItem key={index} value={experience.id}>
                          {experience.name}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <span className='text-label'>
              {`${formik.values.minExperienceLevel} to ${formik.values.maxExperienceLevel}`}
            </span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            // gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Notice Period</Typography>
              <span className='feildHeader'>Choose in days</span>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Notice Period :</Typography>
            </div>
          )}
          {edit ? (
            <div className='input-field' style={{ width: '50%' }}>
              <TextField
                fullWidth
                value={formik.values.noticePeriod || ''}
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : null;
                  formik.setFieldValue('noticePeriod', value);
                }}
                // style={{
                //   height: 'var(--Font-size-h2, 2.5rem)',
                // }}
                error={!!formik.errors.noticePeriod}
                helperText={formik.errors.noticePeriod || ''}
              />
            </div>
          ) : (
            <span className='text-label'>{formik.values.noticePeriod}</span>
          )}
        </div>
        {mode === 'edit-page' ? (
          <div
            style={{
              // gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.87rem',
            }}>
            <hr style={{ background: '#868686' }} />
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            // gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Qualification</Typography>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Qualification :</Typography>
            </div>
          )}
          {edit ? (
            <div style={{ width: '50%' }}>
              <Autocomplete
                fullWidth
                className='input-field'
                id='combo-box-demo'
                options={globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION] as OptionType[]}
                value={
                  formik.values.qualificationId
                    ? globalObject.lookupvalues[LookupTypes.TAD_QUALIFICATION].find(
                        (option: OptionType) => option.id === formik.values.qualificationId,
                      )
                    : null
                }
                size='small'
                limitTags={1}
                getOptionLabel={(option: any) => option.name}
                filterSelectedOptions
                onBlur={() => formik.setFieldTouched('qualificationId', true, true)}
                renderInput={(params) => (
                  <TextField
                    placeholder='Choose...'
                    {...params}
                    error={Boolean(formik.errors.qualificationId && formik.touched.qualificationId)}
                    label=''
                  />
                )}
                onChange={(_e, val) => {
                  formik.setFieldValue('qualificationId', val ? val.id : undefined);
                }}
              />
            </div>
          ) : (
            <span className='text-label'>
              {getNameFromId(formik.values.qualificationId, LookupTypes.TAD_QUALIFICATION)}
            </span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            // gap: '24.56rem',
            alignItems: 'center',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Target Companies *</Typography>
              <span className='feildHeader'>Pick one or multiple options</span>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Target Companies :</Typography>
            </div>
          )}
          {edit ? (
            <div style={{ width: '50%' }}>
              <Autocomplete
                fullWidth
                className='input-field'
                id='combo-box-demo'
                options={globalObject.lookupvalues[LookupTypes.TAD_TARGET_COMPANIES]}
                value={formik.values.targetCompanies}
                size='small'
                sx={{ marginBottom: '0.62rem' }}
                limitTags={1}
                getOptionLabel={(option: any) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    placeholder='Choose...'
                    {...params}
                    error={Boolean(formik.errors.targetCompanies)}
                    label=''
                  />
                )}
                onChange={(_e, val) => {
                  formik.setFieldValue('targetCompanies', val ? val : undefined);
                }}
                multiple
              />
              <div className='mt-0 form-err-message'>{getError(formik, 'targetCompanies')}</div>
              {formik.values.targetCompanies?.map((targetCompany) => (
                <Chip
                  key={targetCompany.id}
                  label='IBM'
                  style={{
                    backgroundColor: '#D9D9D9',
                    borderRadius: '0.63rem',
                    marginRight: '0.5rem',
                  }}
                  onDelete={() =>
                    handleChipDelete(
                      'targetCompanies',
                      formik.values.targetCompanies,
                      targetCompany,
                    )
                  }
                />
              ))}
            </div>
          ) : (
            <span className='text-label'>
              {formik.values.targetCompanies.map((targetCompany) => targetCompany.name).join(', ')}
            </span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            // gap: '24.56rem',
            alignItems: 'flex-start',
            marginBottom: '1.87rem',
          }}>
          {mode === 'edit-page' ? (
            <div style={{ width: '50%' }}>
              <Typography sx={{ fontWeight: 600 }}>Keywords *</Typography>
              <span className='feildHeader'>Add keywords</span>
            </div>
          ) : (
            <div style={{ width: '35%' }}>
              <Typography sx={{ fontWeight: 600 }}>Keywords :</Typography>
            </div>
          )}
          {edit ? (
            <div style={{ width: '50%' }}>
              <div className='input-field' style={{ marginBottom: '0.62rem' }}>
                <Autocomplete
                  fullWidth
                  id='combo-box-demo'
                  options={keywords}
                  value={formik.values.keywords ? parseLocation(formik.values.keywords) : []}
                  size='small'
                  limitTags={1}
                  getOptionLabel={(option: any) => option}
                  filterSelectedOptions
                  onBlur={() => formik.setFieldTouched('keywords', true, true)}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Choose...'
                      {...params}
                      error={Boolean(formik.errors.keywords && formik.touched.keywords)}
                      label=''
                    />
                  )}
                  onChange={(_e, val) => {
                    formik.setFieldValue('keywords', val ? `[${val.join('][')}]` : undefined);
                  }}
                  multiple
                />
              </div>
              <div className='mt-0 form-err-message'>{getError(formik, 'keywords')}</div>

              <div style={{ width: '100%' }}>
                <Typography sx={{ fontWeight: 600 }}>Keywords Suggestions</Typography>
                {keywords.map((keyword) => (
                  <Chip
                    key={keyword}
                    label={keyword}
                    style={{
                      backgroundColor: '#D9D9D9',
                      borderRadius: '0.63rem',
                      marginRight: '0.63rem',
                      marginBottom: '0.63rem',
                    }}
                    avatar={
                      formik.values?.keywords &&
                      parseLocation(formik.values?.keywords)?.some((s: any) => s === keyword) ? (
                        <DoneIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    onClick={() => handleKeyWordsToggle('keywords', keyword)}
                  />
                ))}
              </div>
            </div>
          ) : (
            <span className='text-label'>
              {formik.values.keywords && parseLocation(formik.values.keywords).join(', ')}
            </span>
          )}
        </div>
      </div>
      {mode === 'edit-page' ? (
        <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Button
              onClick={() => {
                navigate([
                  {
                    label: 'JOB DEFINITIONS',
                    link: '/source-job-definition',
                  },
                ]);
              }}
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
              }}>
              Cancel
            </Button>

            <div style={{ display: 'flex', gap: '15px' }}>
              <Button
                className='button'
                onClick={() => {
                  previous();
                  // scrollToTop();
                }}
                sx={{
                  width: '7.375rem',
                  height: '2.5rem',
                  borderRadius: '5px',
                  border: '1px solid #2D7DFE',
                  backgroundColor: '#FFF',
                  color: 'black',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 400,
                }}>
                Previous
              </Button>
              <Button
                className='button'
                onClick={() => {
                  setSubmitted(true);
                  formik.handleSubmit();
                  scrollToTop();
                }}
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  fontSize: '1rem',
                  width: '7.375rem',
                  height: '2.5rem',
                  color: 'white !important',
                  background: '#2D7DFE !important',
                }}>
                Next
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </Paper>
  );
};

export default SourcingStrategy;
