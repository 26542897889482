import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import successImg from '../../assets/sjd/successImg.gif';
import { JobDefinitionApproval } from '../../service/tadSjdService';
import { AlertColor, MenuItem, Select, TextareaAutosize } from '@mui/material';
import Notify from '../../common/notify';

const TadJobRequisitionSuccessPage: React.FC = () => {
  const { approval, email, sjdId, token } = useParams();
  const [declinedSubmit, setDeclineSubmit] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isError, setIsError] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    actionButtons: [],
    title: '',
    message: '',
    position: '',
  } as {
    severity: AlertColor;
    actionButtons?: any[];
    title?: any;
    message?: string;
    position?: string;
  });

  useEffect(() => {
    token && localStorage.setItem('token', token);

    if (approval == 'true') {
      handleApproval();
    }
  }, []);

  const validationReasonSchema = Yup.object().shape({
    reason: Yup.string().required('Please select a reason.'),
    message: Yup.string().max(500, 'Message cannot exceed 500 characters.'),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
      message: '',
    },
    onSubmit: () => {
      handleApproval();
    },
    validationSchema: validationReasonSchema,
  });

  const decodeMail = email ? atob(email) : '';
  const decodeSjdId = sjdId ? atob(sjdId) : '';

  const handleApproval = () => {
    JobDefinitionApproval({
      approval: approval === 'true',
      email: decodeMail,
      sjdId: decodeSjdId ? parseInt(decodeSjdId, 10) : 0,
      reason: formik.values.reason,
      message: formik.values.message,
    })
      .then((_resp) => {
        setIsError(false);
        formik.resetForm();
        approval === 'true' ? setDeclineSubmit(false) : setDeclineSubmit(true);
      })
      .catch((err) => {
        setIsError(true);
        if (
          err.response.data.message ==
          'Job definition has already been Submitted. Please contact the owner for changes.'
        ) {
          setShowToast(true);
          setToastMsg({
            severity: 'warning',
            title: 'Job definition response already submitted',
            message: err.response.data.message,
          });
        } else {
          setShowToast(true);
          setToastMsg({
            severity: 'error',
            title: 'Something went wrong...',
            message: 'Error: Please check the details and try again.',
          });
        }
      });
  };

  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      {!isError && declinedSubmit ? (
        <div>
          <h1>Job Requisition Declined Successful</h1>
          <img src={successImg} alt='Success' style={{ width: '300px', margin: '1rem 0' }} />
        </div>
      ) : (
        <>
          {!isError && approval == 'true' && (
            <div>
              <h1>Job Requisition Approval Successful</h1>
              <img src={successImg} alt='Success' style={{ width: '300px', margin: '1rem 0' }} />
            </div>
          )}
          {!isError && approval == 'false' && (
            <div
              style={{
                maxWidth: '500px',
                margin: '0 auto',
                padding: '1.5rem',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
              }}>
              <h2>We’re sorry to hear that you would like to decline.</h2>
              <p>Please help us understand your reasons below:</p>

              <form onSubmit={formik.handleSubmit}>
                <div style={{ marginBottom: '1rem' }}>
                  <label
                    htmlFor='reason'
                    style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' }}>
                    Select Reason *
                  </label>
                  <Select
                    name='reason'
                    id='reason'
                    style={{
                      width: '100%',
                      padding: '0.35rem',
                      fontSize: '1rem',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                    value={formik.values.reason}
                    onChange={formik.handleChange}
                    // label='Select Reason'
                    onBlur={formik.handleBlur}>
                    <option value='' disabled>
                      Choose or type
                    </option>
                    <MenuItem value='Budget Constraints'>Budget Constraints</MenuItem>
                    <MenuItem value='Lack of Strategic Alignment'>
                      Lack of Strategic Alignment
                    </MenuItem>
                    <MenuItem value='Role Duplication'>Role Duplication</MenuItem>
                    <MenuItem value='Unclear ROI'>Unclear ROI</MenuItem>
                    <MenuItem value='Market or Economic Conditions'>
                      Market or Economic Conditions
                    </MenuItem>
                    <MenuItem value='Performance Concerns in Existing Departments'>
                      Performance Concerns in Existing Departments
                    </MenuItem>
                    <MenuItem value='Overlapping Responsibilities with Other Roles'>
                      Overlapping Responsibilities with Other Roles
                    </MenuItem>
                    <MenuItem value='Short-Term Focus'>Short-Term Focus</MenuItem>
                    <MenuItem value='Reputation and Cultural Fit'>
                      Reputation and Cultural Fit
                    </MenuItem>
                    <MenuItem value='Other'>Other</MenuItem>
                  </Select>
                  {formik.touched.reason && formik.errors.reason ? (
                    <div className='err-msg'>{formik.errors.reason}</div>
                  ) : null}
                </div>

                <div style={{ marginBottom: '1rem' }}>
                  <label
                    htmlFor='message'
                    style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' }}>
                    Add Message (Optional)
                  </label>
                  <TextareaAutosize
                    name='message'
                    id='message'
                    placeholder='Add your message here...'
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minRows={5}
                    style={{
                      width: '100%',
                      fontSize: '1rem',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      resize: 'vertical',
                    }}></TextareaAutosize>
                  {formik.touched.message && formik.errors.message ? (
                    <div className='err-msg'>{formik.errors.message}</div>
                  ) : null}
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                  {/* <button
                    type='button'
                    onClick={() => window.history.back()}
                    style={{
                      padding: '0.75rem 1.5rem',
                      fontSize: '1rem',
                      backgroundColor: '#f0f0f0',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}>
                    Cancel
                  </button> */}
                  <button
                    type='submit'
                    style={{
                      padding: '0.75rem 1.5rem',
                      fontSize: '1rem',
                      backgroundColor: '#1976D2',
                      color: '#fff',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </>
      )}
      <Notify
        sx={{ width: '29.375rem', height: '15.0625rem' }}
        open={showToast}
        onClose={() => setShowToast(false)}
        severity={toastMsg.severity}
        title={toastMsg.title}
        message={toastMsg.message}
        actionButtons={toastMsg.actionButtons}
      />
    </div>
  );
};

export default TadJobRequisitionSuccessPage;
