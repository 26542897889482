import React, { useEffect, useState } from 'react';
import './../source-job-definition/interview-process.scss';
import {
  AlertColor,
  Button,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import CloseIcon from './../../assets/sjd/close.svg';
import { ReactComponent as ArrowIcons } from './../../assets/sjd/chevron_right.svg';
import { ReactComponent as Add } from './../../assets/sjd/add.svg';
import { ReactComponent as CheckIcon } from './../../assets/sjd/checkIcon.svg';
import { ReactComponent as DragIcon } from './../../assets/sjd/drag_indicator.svg';
import { ReactComponent as LockIcon } from './../../assets/sjd/lock_close.svg';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { TadInterviewProcessDetails } from '../../models/tad-sjd-form-model';
import { getNameFromId } from '../../common/commonUtil';
import { tadGetAllInterviewSubStages } from '../../service/tadCandidateService';
import useNavigate from '../../../common/useNavigate';
import TadScreenLoader from '../common/tad-screen-loader';
import { KeyboardArrowDown } from '@mui/icons-material';
import Notify from '../../common/notify';

interface InterViewProcessProps {
  previous?: () => void;
  interviewProcessDetails: TadInterviewProcessDetails | undefined;
  save?: (values: TadInterviewProcessDetails) => Promise<boolean>;
}

type Item = {
  id?: number;
  name: number;
  type: string;
  note: string;
  position: number;
};

export interface Stage {
  id?: number;
  stageName: number;
  isLocked: boolean;
  position: number;
  item?: Item[];
  color: string;
}

interface DropdownListProps {
  stageName: number;
  initialItems: Item[];
  parentColor: string;
  onItemsChange: (items: Item[]) => void;
}

const InterViewProcess: React.FC<InterViewProcessProps> = ({
  previous = () => {},
  interviewProcessDetails,
  save,
}) => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [filteredStages, setFilteredStages] = useState<any[]>([]);
  const [addNew, setAddNew] = useState(false);
  const [newStageName, setNewStageName] = useState(1);
  const [expandedStage, setExpandedStage] = useState<number | null>(null);
  const [subStages, setSubStages] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    actionButtons: [],
    title: '',
    message: '',
    position: '',
  } as {
    severity: AlertColor;
    actionButtons?: any[];
    title?: any;
    message?: string;
    position?: string;
  });
  const [isNotNull, setIsNotNull] = useState(true);
  const [navigate] = useNavigate();

  const handleSave = () => {
    if (save !== undefined && stages.length > 0) {
      const allValid = stages.every((stage, stageIndex) => {
        const isStageNameValid = stage.stageName !== undefined && stage.stageName !== null;
        const isItemsValid =
          stage.item &&
          stage.item.length > 0 &&
          stage.item.every((item) => {
            return (
              item.name !== undefined &&
              item.name !== null &&
              item.type !== undefined &&
              item.type !== null &&
              item.type !== ''
            );
          });
        if (!isStageNameValid) {
          setToastMsg({
            severity: 'error',
            title: 'Invalid Stage Name',
            message: `Error: Stage #${stageIndex + 1} is missing a valid stage name.`,
          });
          return false;
        }
        if (!isItemsValid) {
          setToastMsg({
            severity: 'error',
            title: 'Invalid Item Data',
            message: `Error: Stage #${getNameFromId(
              stage.stageName,
              LookupTypes.TAD_INTERVIEW_MAIN_STAGE,
            )} contains invalid items. Ensure each item has a valid name and type.`,
          });
          return false;
        }

        return true;
      });

      if (allValid) {
        save({
          interviewProcess: stages,
        });
      } else {
        setShowToast(true);
        console.error('Some stages or items have invalid data.');
      }
    }
  };

  useEffect(() => {
    if (!interviewProcessDetails?.interviewProcess) return;
    const arr = ['#ACDDA9', '#FDDA95', '#F9BB98', '#C0C0C0'];
    const updatedStages = interviewProcessDetails.interviewProcess.map((stage, index) => ({
      ...stage,
      color: arr[index % arr.length],
    }));
    const isSame = JSON.stringify(updatedStages) === JSON.stringify(stages);
    if (!isSame) {
      setStages(updatedStages);
    }
  }, [interviewProcessDetails?.interviewProcess]);

  useEffect(() => {
    const nullStages = stages.filter((stage) => {
      const isStageNameInvalid = stage.stageName === 0;
      const areItemsInvalid = stage.item?.some(
        (item) =>
          item.name === undefined ||
          item.name === null ||
          item.type === undefined ||
          item.type === null ||
          item.type === '',
      );
      return isStageNameInvalid || areItemsInvalid;
    });
    if (nullStages.length > 0) {
      setIsNotNull(false);
    } else {
      setIsNotNull(true);
    }
    if (!stages) return;
    const stageNamesSet = new Set(stages.map((i) => i.stageName) || []);
    const filterStages =
      globalObject?.lookupvalues?.[LookupTypes.TAD_INTERVIEW_MAIN_STAGE]?.filter(
        (e: any) => !stageNamesSet.has(e.id),
      ) || [];
    setFilteredStages(filterStages);
  }, [stages]);

  const addStage = () => {
    if (filteredStages.length === 0) {
      setShowToast(true);
      setToastMsg({
        severity: 'error',
        title: 'No Available Stages',
        message: 'All stages have already been added. Please select a different stage.',
      });
      return;
    }
    const arr = ['#ACDDA9', '#FDDA95', '#F9BB98', '#C0C0C0'];
    const { id } = filteredStages[0];
    const insertIndex = stages.length;
    const color = arr[insertIndex % arr.length];
    const maxPosition = stages.length > 0 ? Math.max(...stages.map((stage) => stage.position)) : 0;
    const newStage: Stage = {
      stageName: id,
      position: maxPosition + 1,
      isLocked: false,
      item: [],
      color: color,
    };
    setStages([...stages, newStage]);
    setExpandedStage(insertIndex);
    setAddNew(true);
    setNewStageName(id);
  };

  const updateStagePositions = (stages: Stage[]) => {
    return stages.map((stage, index) => ({ ...stage, position: index + 1 }));
  };

  const updateStageName = (index: number, newStageName: number) => {
    setNewStageName(newStageName);
    const updatedStages = [...stages];
    updatedStages[index].stageName = newStageName;
    setStages(updatedStages);
    setIsNotNull(true);
  };

  const toggleExpand = (index: number, stageName: number) => {
    setAddNew(false);
    if (expandedStage !== index) {
      setLoading(true);
      tadGetAllInterviewSubStages(stageName)
        .then((resp) => {
          setLoading(false);
          const filteredSubStages = resp.data.filter(
            (subStage: any) => !stages[index].item?.some((item) => item.name === subStage.id),
          );

          setSubStages(filteredSubStages);
        })
        .catch((_err) => {});
    } else {
      setSubStages([]);
    }
    setExpandedStage(expandedStage === index ? null : index);
  };

  const handleItemsChange = (index: number, updatedItems: Item[]) => {
    const updatedStages = [...stages];
    updatedStages[index].item = updatedItems;
    setStages(updatedStages);
  };

  const moveStageUp = (index: number) => {
    if (index === 0 || stages[index].isLocked || stages[index - 1].isLocked) return;

    const newStages = [...stages];
    [newStages[index], newStages[index - 1]] = [newStages[index - 1], newStages[index]]; // Swap
    setStages(updateStagePositions(newStages));
  };

  const moveStageDown = (index: number) => {
    if (index === stages.length - 1 || stages[index].isLocked || stages[index + 1].isLocked) return;

    const newStages = [...stages];
    [newStages[index], newStages[index + 1]] = [newStages[index + 1], newStages[index]];
    setStages(updateStagePositions(newStages));
  };

  const removeStage = (index: number) => {
    if (stages[index].isLocked) return;

    const newStages = stages.filter((_, i) => i !== index);
    setStages(updateStagePositions(newStages));
  };

  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [draggingElement, setDraggingElement] = useState<HTMLElement | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLElement>, index: number) => {
    setDraggedIndex(index);
    setDraggingElement(event.currentTarget);
    event.currentTarget.classList.add('dragging');
  };

  const handleDragOver = (event: React.DragEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    if (hoveredIndex !== index) {
      setHoveredIndex(index);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLElement>) => {
    event.currentTarget.classList.add('drag-over');
  };

  const handleDrop = (event: React.DragEvent<HTMLElement>, index: number) => {
    if (draggedIndex === null || draggedIndex === index) return;
    const updatedStages = [...stages];
    const draggedStage = updatedStages.splice(draggedIndex, 1)[0];
    updatedStages.splice(index, 0, draggedStage);
    setStages(updateStagePositions(updatedStages));
    setDraggedIndex(null);
    setHoveredIndex(null);
    if (draggingElement) {
      draggingElement.classList.remove('dragging');
    }
    document.querySelectorAll('.drag-over').forEach((el) => el.classList.remove('drag-over'));
  };
  const handleDragEnd = () => {
    if (draggingElement) {
      draggingElement.classList.remove('dragging');
    }
  };

  const DropdownList: React.FC<DropdownListProps> = ({
    stageName,
    initialItems,
    onItemsChange,
    parentColor,
  }) => {
    const [items, setItems] = useState<Item[]>(initialItems);
    const [editedItems, setEditedItems] = useState<Item[]>([...initialItems]);
    const [newItem, setNewItem] = useState<Item>({ name: 0, type: '', note: '', position: 1 });
    const [error, setError] = useState(false);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
    const [draggingItemElement, setDraggingItemElement] = useState<HTMLElement | null>(null);
    const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);

    const updateItemPositions = (items: Item[]) => {
      return items.map((item, index) => ({ ...item, position: index + 1 }));
    };

    const handleItemDragStart = (event: React.DragEvent<HTMLElement>, index: number) => {
      setDraggedItemIndex(index);
      setDraggingItemElement(event.currentTarget);
      event.currentTarget.classList.add('dragging');
    };

    const handleItemDragOver = (event: React.DragEvent<HTMLElement>, index: number) => {
      event.preventDefault();
      if (hoveredItemIndex !== index) {
        setHoveredItemIndex(index);
      }
    };

    const handleItemDragEnter = (event: React.DragEvent<HTMLElement>) => {
      event.currentTarget.classList.add('drag-over');
    };

    const handleItemDrop = (event: React.DragEvent<HTMLElement>, index: number) => {
      event.preventDefault();
      if (draggedItemIndex === null || draggedItemIndex === index) return;
      const updatedItems = [...items];
      const draggedItem = updatedItems.splice(draggedItemIndex, 1)[0];
      updatedItems.splice(index, 0, draggedItem);
      const updatedItemsWithPositions = updateItemPositions(updatedItems);
      const isInvalid = updatedItemsWithPositions.some(
        (item) => item.name === null || item.type === null || item.type === '',
      );
      if (isInvalid) {
        setError(true);
        return;
      }
      setItems(updatedItemsWithPositions);
      setEditedItems(updatedItemsWithPositions);
      onItemsChange(updatedItemsWithPositions);
      setDraggedItemIndex(null);
      setHoveredItemIndex(null);
      if (draggingItemElement) {
        draggingItemElement.classList.remove('dragging');
      }
      document.querySelectorAll('.drag-over').forEach((el) => el.classList.remove('drag-over'));
    };

    const handleItemDragEnd = () => {
      if (draggingItemElement) {
        draggingItemElement.classList.remove('dragging');
      }
    };

    const addItem = () => {
      tadGetAllInterviewSubStages(stageName)
        .then(({ data }) => {
          const filteredSubStages = data.filter(
            (subStage: any) => !items.some((item) => item.name === subStage.id),
          );
          if (filteredSubStages.length === 0) {
            setShowToast(true);
            setToastMsg({
              severity: 'error',
              title: 'No Available Sub-Stages',
              message: 'All sub-stages have already been added. Please select a different stage.',
            });
            return;
          }
          setSubStages(filteredSubStages);
          setAddNew(false);
          if (newItem) {
            const maxPosition =
              items.length > 0 ? Math.max(...items.map((item) => item.position)) : 0;
            const updatedItems = [...items, { ...newItem, position: maxPosition + 1 }];
            setItems(updatedItems);
            setEditedItems(updatedItems);
            onItemsChange(updatedItems);
            setNewItem({ name: 0, type: '', note: '', position: maxPosition + 2 });
          }
        })
        .catch((_err) => {});
    };

    const removeItem = (index: number) => {
      const updatedItems = items.filter((_, i) => i !== index);
      setItems(updateItemPositions(updatedItems));
      setEditedItems(updateItemPositions(updatedItems));
      onItemsChange(updateItemPositions(updatedItems));
    };

    const moveItemUp = (index: number) => {
      if (index === 0) return;
      const updatedItems = [...items];
      [updatedItems[index - 1], updatedItems[index]] = [
        updatedItems[index],
        updatedItems[index - 1],
      ];
      setItems(updateItemPositions(updatedItems));
      setEditedItems(updateItemPositions(updatedItems));
      onItemsChange(updateItemPositions(updatedItems));
    };

    const moveItemDown = (index: number) => {
      if (index === items.length - 1) return;
      const updatedItems = [...items];
      [updatedItems[index], updatedItems[index + 1]] = [
        updatedItems[index + 1],
        updatedItems[index],
      ];
      setItems(updateItemPositions(updatedItems));
      setEditedItems(updateItemPositions(updatedItems));
      onItemsChange(updateItemPositions(updatedItems));
    };

    const handleInputChange = (index: number, field: keyof Item, value: any) => {
      const updatedItems = [...editedItems];
      updatedItems[index] = { ...updatedItems[index], [field]: value };
      setEditedItems(updatedItems);
    };

    const handleSaveItem = (index: number) => {
      const updatedItems = [...items];
      updatedItems[index] = { ...editedItems[index] };

      const isInvalid = updatedItems.some(
        (item) => item.name === null || item.type === null || item.type === '',
      );
      if (isInvalid) {
        setError(true);
        return;
      }
      setItems(updatedItems);
      onItemsChange(updatedItems);
    };

    return (
      <div className='dropdown-list'>
        <div className='dropdown-list-container'>
          {editedItems
            .sort((a, b) => a.position - b.position)
            .map((item, index) => (
              <List
                component='div'
                key={index}
                className='dropdown-list-item'
                draggable
                onDragStart={(e) => handleItemDragStart(e, index)}
                onDragOver={(e) => handleItemDragOver(e, index)}
                onDragEnter={(e) => handleItemDragEnter(e)}
                onDrop={(e) => handleItemDrop(e, index)}
                onDragEnd={handleItemDragEnd}
                style={{
                  cursor: 'grab',
                  marginBottom: '0.5rem', // Optional: Adds space between items
                  transition: 'transform 0.2s ease, opacity 0.2s ease', // Ensures smooth animation
                  position: 'relative',
                }}>
                <div className='item-actions'>
                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
                    <DragIcon className='drag' />
                    <span
                      className='stage-content-rectangle'
                      style={{
                        background: parentColor,
                        margin: '2.5px 2.5px 2.5px 0px',
                      }}></span>
                  </div>
                  <div>
                    <IconButton
                      disabled={index === items.length - 1}
                      onClick={() => moveItemDown(index)}>
                      <ArrowIcons
                        className={`${
                          index === items.length - 1 ? 'locked' : 'unlocked'
                        } arrowLeft`}
                      />
                    </IconButton>
                    <IconButton disabled={index === 0} onClick={() => moveItemUp(index)}>
                      <ArrowIcons className={`${index === 0 ? 'locked' : 'unlocked'} arrowRight`} />
                    </IconButton>
                    <IconButton onClick={() => removeItem(index)}>
                      <img
                        style={{ width: '1rem', height: '1.5rem' }}
                        src={CloseIcon}
                        alt='close'
                      />
                    </IconButton>
                  </div>
                </div>
                <div className='list-field'>
                  <div style={{ width: '100%' }}>
                    <InputLabel htmlFor={`stage-select-${index}`}>Stage Name</InputLabel>
                    <Select
                      fullWidth
                      IconComponent={KeyboardArrowDown}
                      className='input-field'
                      value={item.name}
                      renderValue={(selected) => {
                        return getNameFromId(selected, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE);
                      }}
                      sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '10px',
                        },
                      }}
                      error={error && (!item.name || item.name === null)}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      size='small'
                      labelId={`stage-label-${index}`}
                      id={`stage-select-${index}`}
                      name='stage'>
                      <MenuItem value='default' disabled>
                        Choose...
                      </MenuItem>
                      {subStages.map((type: any) => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (!item.name || item.name === null) && (
                      <span style={{ color: 'red' }}>Please select a valid name.</span>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <InputLabel id='type' sx={{ width: '10.4375rem' }}>
                      Type
                    </InputLabel>
                    <Select
                      className='input-field'
                      sx={{
                        borderRadius: '10px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderRadius: '10px',
                        },
                      }}
                      value={item.type ? item.type : 'default'}
                      onChange={(e) => {
                        setError(false); // reset the error when the user changes the selection
                        handleInputChange(index, 'type', e.target.value as string);
                      }}
                      size='small'
                      labelId='type'
                      name='type'
                      error={error && (!item.type || item.type === 'default')}
                      id='type'>
                      <MenuItem value='default' disabled>
                        Choose...
                      </MenuItem>
                      {globalObject.lookupvalues[LookupTypes.TAD_INTERVIEW_PROCESS_TYPE].map(
                        (type: any, index: number) => (
                          <MenuItem key={index} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                    {error && (!item.type || item.type === 'default') && (
                      <span style={{ color: 'red' }}>Please select a valid type.</span>
                    )}
                  </div>
                </div>

                <div className='list-field'>
                  <div style={{ width: '100%' }}>
                    <InputLabel>Note / URL</InputLabel>
                    <TextField
                      fullWidth
                      size='small'
                      value={item.note}
                      onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <Button>Cancel</Button>
                  <Button onClick={() => handleSaveItem(index)}>Save</Button>
                </div>
              </List>
            ))}
          <div className='add-item'>
            <Button disabled={!isNotNull} onClick={addItem}>
              <Add className='add-icon' />
              <span>Add Secondary Stage</span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getInterviewHeader = (name: number, items: Item[]) => {
    const maxItemsToShow = 2;
    const sortedItems = [...items].sort((a, b) => a.position - b.position);
    const itemNames = sortedItems.map((value) =>
      getNameFromId(value.name, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE),
    );
    const fullItemsString = itemNames.join(', ');
    const displayedItems =
      itemNames.length > maxItemsToShow
        ? `${itemNames.slice(0, maxItemsToShow).join(', ')}...`
        : fullItemsString;
    const header = `${getNameFromId(
      name,
      LookupTypes.TAD_INTERVIEW_MAIN_STAGE,
    )} - ${displayedItems}`;
    const fullHeader = `${getNameFromId(
      name,
      LookupTypes.TAD_INTERVIEW_MAIN_STAGE,
    )} - ${fullItemsString}`;
    return { header, fullHeader };
  };

  return (
    <div className='workflow-main'>
      <div className='new-job-requisition paper' style={{ width: '100%', height: '8.8125rem' }}>
        <div className='workflow-header' style={{ marginLeft: '1.88rem' }}>
          <span className='workflow-header-title'>Interview Process</span>
          <span className='workflow-header-sub_title'>
            Set up hiring stages in the interview process to manage candidates for this job.
          </span>
        </div>
      </div>
      <div className='paper' style={{ width: '100%' }}>
        <div className='workflow-container'>
          <div className='workflow-header'>
            <span className='workflow-header-title'>Workflow</span>
            <span className='workflow-header-sub_title'>
              Set up hiring stages in the interview process to manage candidates for this job.
            </span>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <List className='stage-item' style={{ backgroundColor: '#F1F4F8', cursor: 'default' }}>
              <div className='stage-content'>
                <DragIcon className='drag' />
                <span className='stage-content-rectangle' style={{ background: '#82B1FF' }}></span>
                <>
                  <span className='stage-content-text'>Applied</span>
                  <IconButton disabled={true}>
                    <ArrowIcons className='locked' />
                  </IconButton>
                </>
              </div>
              <div className='stage-actions'>
                <IconButton disabled={true}>
                  <LockIcon className='lockIcon' />
                </IconButton>
              </div>
            </List>
          </div>
          <div style={{ marginTop: '1rem' }}>
            {stages
              .slice()
              .sort((a, b) => a.position - b.position)
              .map((stage, index) => (
                <React.Fragment key={index}>
                  <List
                    component='div'
                    className='stage-item'
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDragEnter={(e) => handleDragEnter(e)}
                    onDrop={(e) => handleDrop(e, index)}
                    onDragEnd={handleDragEnd}>
                    <div className='stage-content'>
                      <DragIcon className='drag' />
                      <span
                        className='stage-content-rectangle'
                        style={{ background: stage.color }}></span>
                      {addNew && index === stages.length - 1 ? (
                        <div style={{ display: 'flex', gap: '1rem' }}>
                          <Select
                            fullWidth
                            sx={{
                              minWidth: '200px',
                              height: 'var(--Font-size-h2, 2.5rem)',
                              marginLeft: '0.5rem',
                              borderRadius: '10px',
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '10px',
                              },
                            }}
                            value={newStageName}
                            renderValue={(_selected) => {
                              const selectedStage = globalObject?.lookupvalues?.[
                                LookupTypes.TAD_INTERVIEW_MAIN_STAGE
                              ]?.find((e: any) => e.id === newStageName);
                              return selectedStage ? selectedStage.name : 'Enter stage name';
                            }}
                            onChange={(e: any) => updateStageName(index, e.target.value)}
                            size='small'>
                            <MenuItem value='' disabled>
                              Enter stage name
                            </MenuItem>
                            {filteredStages.map((stage: any) => (
                              <MenuItem key={stage.id} value={stage.id}>
                                {stage.name}
                              </MenuItem>
                            ))}
                          </Select>

                          <IconButton onClick={() => setAddNew(false)}>
                            <CheckIcon
                              style={{ width: '1.5rem', height: '1.5rem', color: '#1976d2' }}
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <>
                          {(() => {
                            const { header, fullHeader } = getInterviewHeader(
                              stage.stageName,
                              stage.item ? stage.item : [],
                            );
                            return (
                              <Tooltip title={fullHeader} arrow>
                                <span className='stage-content-text'>{header}</span>
                              </Tooltip>
                            );
                          })()}
                          <IconButton
                            onClick={() => toggleExpand(index, stage.stageName)}
                            disabled={stage.isLocked || !isNotNull}>
                            <ArrowIcons
                              className={`${stage.isLocked ? 'locked' : 'unlocked'} ${
                                expandedStage === index ? 'arrowRight' : 'arrowLeft'
                              }`}
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                    <div className='stage-actions'>
                      {stage.isLocked ? (
                        <IconButton disabled={true}>
                          <LockIcon className='lockIcon' />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton
                            onClick={() => {
                              moveStageDown(index);
                              setExpandedStage(null);
                            }}
                            disabled={
                              index === stages.length - 1 ||
                              stage.isLocked ||
                              stages[index + 1]?.isLocked
                            }>
                            <ArrowIcons
                              className={`${
                                index === stages.length - 1 ||
                                stage.isLocked ||
                                stages[index + 1]?.isLocked
                                  ? 'locked'
                                  : 'unlocked'
                              } arrowLeft`}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              moveStageUp(index);
                              setExpandedStage(null);
                            }}
                            disabled={index === 0 || stage.isLocked || stages[index - 1]?.isLocked}>
                            <ArrowIcons
                              className={`${
                                index === 0 || stage.isLocked || stages[index - 1]?.isLocked
                                  ? 'locked'
                                  : 'unlocked'
                              } arrowRight`}
                            />
                          </IconButton>
                          <IconButton onClick={() => removeStage(index)}>
                            <img
                              style={{ width: '1rem', height: '1.5rem' }}
                              src={CloseIcon}
                              alt='close'
                            />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </List>
                  {expandedStage === index && (
                    <DropdownList
                      stageName={stage.stageName}
                      initialItems={stage.item ? stage.item : []}
                      parentColor={stage.color}
                      onItemsChange={(updatedItems) => handleItemsChange(index, updatedItems)}
                    />
                  )}
                </React.Fragment>
              ))}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <List className='stage-item' style={{ backgroundColor: '#F1F4F8', cursor: 'default' }}>
              <div className='stage-content'>
                <DragIcon className='drag' />
                <span className='stage-content-rectangle' style={{ background: '#EFA3B2' }}></span>
                <>
                  <span className='stage-content-text'>Hired</span>
                  <IconButton disabled={true}>
                    <ArrowIcons className='locked' />
                  </IconButton>
                </>
              </div>
              <div className='stage-actions'>
                <IconButton disabled={true}>
                  <LockIcon className='lockIcon' />
                </IconButton>
              </div>
            </List>
          </div>
          <div className='add-item'>
            <Button
              disabled={!isNotNull}
              onClick={() => {
                addStage();
              }}>
              <Add className='add-icon' />
              <span>Add Stage</span>
            </Button>
          </div>
        </div>
        <div className='btn-group'>
          <Button
            className='outline-btn'
            onClick={() => {
              navigate([
                {
                  label: 'JOB DEFINITIONS',
                  link: '/source-job-definition',
                },
              ]);
            }}>
            Cancel
          </Button>
          <div style={{ display: 'flex', gap: '15px' }}>
            <Button
              className='outline-btn'
              onClick={() => {
                previous();
              }}>
              Previous
            </Button>
            <Button
              className='solid-btn'
              onClick={() => {
                handleSave();
              }}>
              Save
            </Button>
          </div>
        </div>
        <TadScreenLoader loading={loading} />
      </div>
      <Notify
        sx={{ width: '29.375rem', height: '15.0625rem' }}
        open={showToast}
        onClose={() => setShowToast(false)}
        severity={toastMsg.severity}
        title={toastMsg.title}
        message={toastMsg.message}
        actionButtons={toastMsg.actionButtons}
      />
    </div>
  );
};

export default InterViewProcess;
