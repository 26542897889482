import {
  Box,
  List,
  Typography,
  FormControl,
  TextField,
  Autocomplete,
  //   InputLabel,
  //   Select,
  //   MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  AlertColor,
  Backdrop,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
// import { ROLES } from '../../constants/common';
import * as Yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { tadAddApprovals, tadUpdateApprovals } from '../../service/tadApprovalService';
import Notify from '../../common/notify';
import { getIdFromName } from '../../../common/commonUtil';
import { TADROLES } from '../../../constants/common';
import { AxiosResponse } from 'axios';
// import Notify from '../../../components/common/notify';
import tadLoader from './../../assets/common-assets/Whole animation.gif';
import { ReactComponent as Add } from './../../assets/sjd/add.svg';

interface Props {
  clientData?: {
    clientId: {
      id: number;
      name: string;
    };
    clientName: string;
    projectTypeId: {
      id: number;
      name: string;
    };
    clientUserId: any;
    approvalRequired: boolean;
    projects: {
      projectId: number;
      clientId: {
        id: number;
        name: string;
      };
      projectName: string;
      hiringManagerId: number;
    }[];
  };

  isEdit: boolean;
  handleClose: (event: any, reason: string) => void;
}

interface OptionType {
  id: number;
  name: string;
}

const AddNewClient = ({ clientData, isEdit, handleClose }: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [toolTip, setToolTip] = useState(false);

  const clientDetailsSchema = Yup.object().shape({
    clientName: isEdit
      ? Yup.string().required('Client name is required')
      : Yup.string().required('Client name is required'),
    projectTypeId: Yup.number().required('Please select a Client type'),

    // clientUserId: Yup.object().required('Please select a Account Manager'),
    clientUserId: Yup.mixed().nullable(),
    approvalRequired: Yup.boolean().required('Approval is required'),
  });

  const formik = useFormik({
    initialValues: {
      clientName: isEdit ? clientData?.clientName : '',
      projectTypeId: isEdit ? clientData?.projectTypeId?.id : undefined,
      clientUserId: isEdit
        ? globalObject.users.find((user: any) => clientData?.clientUserId?.id === user.id) || null
        : null,
      approvalRequired: isEdit ? clientData?.approvalRequired : undefined,
      projects: isEdit
        ? clientData?.projects?.map((project: any) => ({
            projectName: project.projectName,
            hiringManagerId:
              globalObject.users.find((user: any) => project.hiringManagerId?.id === user.id) ||
              null,
          })) || []
        : [
            {
              projectName: '',
              hiringManagerId: null,
            },
          ],
    },
    validationSchema: clientDetailsSchema,
    onSubmit: () => {
      AddClientApproval();
      setSubmitted(true);
    },
  });

  // const { values, setFieldValue } = formik;

  const handleAddProjectClick = () => {
    if (!formik.values.projectTypeId) {
      setToolTip(true);
    } else {
      const projectsList = [...(formik.values.projects || [])];
      projectsList.push({ projectName: '', hiringManagerId: undefined });
      formik.setFieldValue('projects', projectsList);
      setToolTip(false);
    }
  };

  const handleMouseEnter = () => {
    if (!formik.values.projectTypeId) {
      setToolTip(true);
    }
  };

  const handleMouseLeave = () => {
    setToolTip(false);
  };

  const isBillable = formik.values.projectTypeId === 1;
  const isInvestment = formik.values.projectTypeId === 2;

  const AddClientApproval = () => {
    setLoading(true);
    const obj1 = {
      clientName: formik.values.clientName,
      projectTypeId: formik.values.projectTypeId,
      clientUserId: !isBillable ? null : formik.values.clientUserId.id,
      approvalRequired: clientData?.approvalRequired,
      clientId: clientData?.clientId,
      projects: (formik.values.projects || []).map((project, index) => {
        const existingProject =
          (clientData?.projects?.[index] as {
            projectId?: number;
            clientId?: { id: number; name: string };
          }) || {};
        return {
          clientId: clientData?.clientId?.id || null,
          projectId: existingProject.projectId || null,
          projectName: project.projectName?.trim() || null,
          hiringManagerId: !isInvestment ? null : project?.hiringManagerId || null,
        };
      }),
    };
    const obj = {
      clientName: formik.values.clientName,
      projectTypeId: formik.values.projectTypeId,
      clientUserId: !isBillable ? null : formik.values.clientUserId.id,
      approvalRequired: formik.values.approvalRequired,
      projects: formik.values.projects?.some(
        (project) => project.projectName?.trim() || project.hiringManagerId,
      )
        ? formik.values.projects.map((project) => ({
            projectName: project.projectName?.trim() || null,
            hiringManagerId: !isInvestment ? null : project?.hiringManagerId || null,
          }))
        : null,
    };
    console.log('Payload:', obj);
    const apiCall: Promise<AxiosResponse<any, any>> = isEdit
      ? tadUpdateApprovals(obj1)
      : tadAddApprovals(obj);
    apiCall
      .then((_res) => {
        handleClose({}, 'submitted');
        setToastMsg({
          severity: 'success',
          msg: 'Client created/updated successfully.',
        });
        formik.resetForm();
        setSubmitted(!submitted);
        setReload(!reload);
        setTimeout(() => {
          setShowToast(true);
        }, 2000);
      })
      .catch((_err) => {
        setLoading(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Something went wrong!!',
        });
      });
  };

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  // const handleResetForm = () => {
  //   formik.resetForm();
  //   setSubmitted(false);
  //   // onClose();
  // };

  return (
    <>
      <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
        <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
              {isEdit ? 'Update Client' : 'Add Client'}
            </Typography>
            <IconButton
              onClick={() => handleClose({}, 'close')}
              style={{ justifyContent: 'flex-end' }}>
              <CloseIcon />
            </IconButton>
          </div>

          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.17rem',
            }}>
            <hr style={{ background: '#868686', marginTop: '10px' }} />
          </div>

          <form
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}
            onSubmit={(values) => {
              formik.handleSubmit(values);
            }}>
            <table>
              <tbody>
                <Typography>Client Name *</Typography>
                <tr>
                  <td>
                    <FormControl
                      error={!formik.errors.clientName}
                      variant='standard'
                      fullWidth
                      sx={{ marginBottom: '20px' }}>
                      <TextField
                        id='clientName'
                        placeholder='Enter Client Name....'
                        name='clientName'
                        className='input-field'
                        value={formik.values.clientName}
                        onChange={(e) => formik.setFieldValue('clientName', e.target.value)}
                        onBlur={formik.handleBlur}></TextField>
                      <div className='form-err-message'>{getError(formik, 'clientName')}</div>
                    </FormControl>
                  </td>
                </tr>
                <Typography>Select Client Type *</Typography>
                <tr>
                  {' '}
                  <td>
                    <FormControl variant='standard' sx={{ marginBottom: '20px' }} fullWidth>
                      <Autocomplete
                        className='input-field'
                        fullWidth
                        id='user-operations-select'
                        options={
                          globalObject.lookupvalues[
                            LookupTypes.TAD_PROJECT_BILLING_TYPE
                          ] as OptionType[]
                        }
                        value={
                          formik.values.projectTypeId
                            ? globalObject.lookupvalues[LookupTypes.TAD_PROJECT_BILLING_TYPE].find(
                                (option: OptionType) => option.id === formik.values.projectTypeId,
                              )
                            : null
                        }
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        filterSelectedOptions
                        onBlur={() => formik.setFieldTouched('projectTypeId', true, true)}
                        renderInput={(params) => (
                          <TextField
                            className='input-field'
                            placeholder='Choose...'
                            {...params}
                            error={Boolean(
                              formik.errors.projectTypeId && formik.touched.projectTypeId,
                            )}
                          />
                        )}
                        onChange={(e, val) => {
                          formik.setFieldValue('projectTypeId', val ? val.id : undefined);
                        }}
                      />
                      <div className='mt-0 form-err-message'>
                        {getError(formik, 'projectTypeId')}
                      </div>
                    </FormControl>
                  </td>
                </tr>
                {isBillable && (
                  <>
                    <Typography>Select Account Manager *</Typography>
                    <tr>
                      <td>
                        <FormControl sx={{ marginBottom: '20px' }} fullWidth>
                          <div>
                            <Autocomplete
                              className='input-field'
                              fullWidth
                              id='account-manager-select'
                              options={globalObject.users.filter((e: any) =>
                                e.roles.some(
                                  (role: any) =>
                                    role.id ===
                                    getIdFromName(TADROLES.ACCOUNT_MANAGER, LookupTypes.TAD_ROLE),
                                ),
                              )}
                              value={formik.values.clientUserId || null}
                              getOptionLabel={(option: any) =>
                                `${option.firstName} ${option.lastName}`
                              }
                              renderInput={(params) => (
                                <TextField
                                  className='input-field'
                                  placeholder='Choose Account Manager'
                                  {...params}
                                  error={Boolean(
                                    formik.errors.clientUserId && formik.touched.clientUserId,
                                  )}
                                />
                              )}
                              onChange={(_event, newValue) => {
                                formik.setFieldValue('clientUserId', newValue ? newValue : null);
                              }}
                            />
                          </div>

                          <div className='mt-0 form-err-message'>
                            {getError(formik, 'clientUserId')}
                          </div>
                        </FormControl>
                      </td>
                    </tr>
                  </>
                )}

                <Typography>Approval Required *</Typography>
                <tr>
                  <td>
                    <FormControl component='fieldset' sx={{ marginBottom: '20px' }} fullWidth>
                      <RadioGroup
                        row
                        name='approvalRequired'
                        value={
                          formik.values.approvalRequired !== undefined
                            ? formik.values.approvalRequired
                            : null
                        }
                        onChange={(event) =>
                          formik.setFieldValue('approvalRequired', event.target.value === 'true')
                        }
                        onBlur={formik.handleBlur}>
                        <FormControlLabel value={true} control={<Radio />} label='Yes' />
                        <FormControlLabel value={false} control={<Radio />} label='No' />
                      </RadioGroup>
                      <div className='form-err-message'>{getError(formik, 'approvalRequired')}</div>
                    </FormControl>
                  </td>
                </tr>
                {(isBillable || isInvestment) && (
                  <>
                    {(formik.values.projects || []).length > 0 &&
                      (formik.values.projects || []).map((project, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              border: '2px solid #4791db',
                              borderRadius: '0.625rem',
                              width: '590px',
                              marginBottom: '10px',
                            }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <IconButton
                                sx={{
                                  marginBottom: '10px',
                                }}
                                onClick={() => {
                                  const projects = [...(formik.values.projects || [])];
                                  projects.splice(index, 1);
                                  formik.setFieldValue('projects', projects);
                                }}>
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div>
                              <TextField
                                // label={`Project Name ${index + 1}`}
                                // id='clientName'
                                placeholder='Enter Project Name....'
                                className='input-field'
                                name={`projects[${index}].projectName`}
                                value={project.projectName || null}
                                onChange={formik.handleChange}
                                fullWidth
                                style={{
                                  marginBottom: '10px',
                                  width: '410px',
                                  marginLeft: '0.5rem',
                                }}
                              />
                            </div>
                            {isInvestment && (
                              <>
                                <div>
                                  <Autocomplete
                                    // className='input-field'

                                    id={`hiring-manager-select-${index}`}
                                    options={globalObject.users.filter((e: any) =>
                                      e.roles.some(
                                        (role: any) =>
                                          role.id ===
                                          getIdFromName(
                                            TADROLES.HIRING_MANAGER,
                                            LookupTypes.TAD_ROLE,
                                          ),
                                      ),
                                    )}
                                    value={project?.hiringManagerId || null}
                                    // getOptionLabel={(option: any) =>
                                    //   `${option.firstName} ${option.lastName}`
                                    // }
                                    getOptionLabel={(option: any) =>
                                      option && option.firstName && option.lastName
                                        ? `${option.firstName} ${option.lastName}`
                                        : ''
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        className='input-field'
                                        placeholder='Choose Hiring Manager'
                                        {...params}
                                        // error={Boolean(
                                        //   formik.errors.clientUserId &&
                                        //     formik.touched.clientUserId,
                                        // )}
                                        // disabled={!isInvestment}
                                        style={{
                                          marginBottom: '10px',
                                          width: '410px',
                                          marginLeft: '0.5rem',
                                        }}
                                      />
                                    )}
                                    onChange={(_event, newValue) => {
                                      const updateProjects = [...(formik.values.projects || [])];
                                      updateProjects[index].hiringManagerId = newValue || null;
                                      formik.setFieldValue('projects', updateProjects);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                  </>
                )}

                <div>
                  <td colSpan={2}>
                    <div className='add-item' style={{ marginTop: '10px' }}>
                      <Tooltip title='Select a client type' open={toolTip}>
                        <Button
                          onClick={handleAddProjectClick}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}>
                          <Add className='add-icon' />
                          <span>Add Project</span>
                        </Button>
                      </Tooltip>
                    </div>
                  </td>
                </div>
              </tbody>
            </table>
          </form>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
                marginRight: '20px',
              }}
              onClick={() => handleClose({}, 'close')}>
              Cancel
            </Button>
            <Button
              type='submit'
              className='button'
              onClick={() => {
                setSubmitted(true);
                formik.handleSubmit();
              }}
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '7.375rem',
                height: '2.5rem',
                color: 'white !important',
                background: 'rgb(25, 118, 210) !important',
                marginRight: '10px',
              }}>
              Save
            </Button>
          </div>
        </List>
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            alignItems: 'center',
            paddingBottom: '10px',
          }}>
          <img src={tadLoader} alt='Loading' style={{ width: '14rem', height: '7rem' }} />
          <span
            style={{
              color: 'var(--Primary-Dark-grey-500,#333)',
              fontFamily: 'Roboto',
              fontSize: 'var(--Font-size-small, 1.75rem)',
              fontStyle: 'normal',
              fontWeight: '500',
              letterSpacing: '0.42rem',
              textAlign: 'center',
            }}>
            LOADING...
          </span>
        </div>
      </Backdrop>

      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </>
  );
};

export default AddNewClient;
