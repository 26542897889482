import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import './new-dashboard.scss';
import tadLoader from './../../assets/common-assets/Whole animation.gif';
import useNavigate from '../../../common/useNavigate';

interface PreDashboardDialogProps {
  header: any;
  open: boolean;
}

const DashboardOverlay: React.FC<PreDashboardDialogProps> = ({ open, header }) => {
  const [navigate] = useNavigate();

  const handleNavigation = (page: string) => {
    return navigate([
      page === 'jobDefinitions'
        ? { label: 'JOB DEFINITIONS', link: '/source-job-definition' }
        : { label: 'CANDIDATES', link: '/candidate' },
    ]);
  };

  return (
    <>
      {open && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: '0%',
            width: '100%',
            height: '106%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}>
          <div
            className='paper'
            style={{
              height: '25rem',
              width: '40rem',
              zIndex: '10',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              top: header === 'lms' ? '34%' : '5%',
              position: 'absolute',
            }}>
            <div>
              <Box display='flex' justifyContent='center' mb={2}>
                <img
                  src={tadLoader}
                  alt='Loading'
                  style={{
                    width: '9.4rem',
                    height: '5.2rem',
                    marginTop: '2rem',
                  }}
                />
              </Box>
            </div>
            <div>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  variant='body1'
                  paragraph
                  className='dashboard-title'
                  sx={{
                    fontWeight: '400',
                    fontSize: '2.5rem',
                    color: '#333',
                    marginTop: '2rem',
                  }}>
                  Coming Soon!
                </Typography>
              </div>
              <div>
                <Typography variant='body1' paragraph className='dashboard-heading'>
                  {`We’re working hard to bring you an enhanced ${header} experience.`}
                </Typography>
                <div>
                  <Typography variant='body2' paragraph className='dashboard-heading'>
                    In the meantime, you can still explore and access all other features of the
                    <br />
                    platform. Thank you for your patience and understanding!
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <Typography
                gutterBottom
                className='dashboard-heading'
                style={{ marginBottom: '1.1rem' }}>
                Explore
              </Typography>
            </div>
            <div>
              <Box display='flex' justifyContent='center' gap={2}>
                <Button className='left-button' onClick={() => handleNavigation('jobDefinitions')}>
                  Job Definitions
                </Button>
                <Button className='left-button' onClick={() => handleNavigation('candidates')}>
                  Candidates
                </Button>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardOverlay;
