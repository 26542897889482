import { useState, useEffect } from 'react';
import { getCandidateCountByStatus } from '../../service/dashboardService';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ApexCharts from 'react-apexcharts';
import dateFormat from 'dateformat';
import { CANDIDATE_STATUS } from '../../constants/common';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import './dashboard.scss';
interface Props {
  initialData: any;
}

const CandidateCountFunnelChart = ({ initialData }: Props) => {
  const [data, setData] = useState([] as any[]);
  const [data2, setData2] = useState([] as any[]);

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(''));
  const [endDate, setEndDate] = useState(dayjs(''));

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
    } else {
      setLoading(false);
      formatData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (startDate.isValid() && endDate.isValid()) {
      getCandidateCountByStatus(
        startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
        endDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      )
        .then((resp) => {
          formatData(resp.data);
        })
        .catch((_err) => {})
        .finally(() => setLoading(false));
    }
  }, [startDate, endDate]);

  const handleFilter = (days: number | 'today' | 'all') => {
    setLoading(true);
    let startDate;
    let endDate;
    if (days === 'today') {
      startDate = endDate = dateFormat(new Date(), 'isoDateTime');
    } else if (days === 'all') {
      startDate = endDate = null;
    } else {
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - days);

      startDate = dateFormat(start, 'isoDateTime');
      endDate = dateFormat(end, 'isoDateTime');
    }
    getCandidateCountByStatus(startDate, endDate)
      .then((resp) => {
        formatData(resp.data);
      })

      .catch((err) => {
        console.error('API Error:', err);
      })
      .finally(() => setLoading(false));
    setStartDate(dayjs(startDate));
    setEndDate(dayjs(endDate));
  };
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'today' || selectedValue === 'all') {
      handleFilter(selectedValue);
    } else {
      const selectedDays = parseInt(selectedValue);
      handleFilter(selectedDays);
    }
  };

  const formatData = (countData: any) => {
    const d: any[] = [];
    const d2: any[] = [];

    const total = Object.values(countData).reduce(
      (sum: number, value: unknown) => sum + Number(value),
      0,
    );

    const profiled =
      total -
      (countData[CANDIDATE_STATUS.PROFILE_UPLOADED] || 0) -
      (countData[CANDIDATE_STATUS.QC_RATED] || 0);

    const interview = profiled - (countData[CANDIDATE_STATUS.PROFILER_RATED] || 0);

    const offer =
      (countData[CANDIDATE_STATUS.DOCUMENT_VERIFICATION_STARTED] || 0) +
      (countData[CANDIDATE_STATUS.DOCUMENT_VERIFICATION_COMPLETE] || 0) +
      (countData[CANDIDATE_STATUS.OFFER_APPROVED] || 0) +
      (countData[CANDIDATE_STATUS.OFFER_RELEASED] || 0) +
      (countData[CANDIDATE_STATUS.OFFER_ACCEPTED] || 0) +
      (countData[CANDIDATE_STATUS.CANDIDATE_JOINED] || 0);

    const join = countData[CANDIDATE_STATUS.CANDIDATE_JOINED] || 0;

    d.push({
      name: 'Sourced',
      value: total,
    });

    d.push({
      name: 'Profiled',
      value: profiled,
    });

    d.push({
      name: 'Interviewed',
      value: interview,
    });

    d2.push({
      name: 'Interviewed',
      value: interview,
    });

    d2.push({
      name: 'Offered',
      value: offer,
    });

    d2.push({
      name: 'Joined',
      value: join,
    });

    setData(d);
    setData2(d2);
  };

  return (
    <>
      <Stack spacing={1}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            marginRight: '5px',
          }}>
          <Typography variant='h6' fontWeight={700}>
            Candidate Funnel Chart
          </Typography>

          <div
            style={{
              margin: '6px ',
              display: 'flex',
            }}>
            <DatePicker
              sx={{ marginRight: '15px' }}
              className='date-picker-funnel'
              format='DD-MM-YYYY'
              label='From'
              value={dayjs(startDate)}
              onChange={(value) => {
                setStartDate(dayjs(value));
              }}
            />

            <DatePicker
              className='date-picker-funnel'
              format='DD-MM-YYYY'
              label='To'
              value={dayjs(endDate)}
              onChange={(value) => {
                setEndDate(dayjs(value));
              }}
            />

            <select
              className='audit-period-select'
              onChange={handleSelectChange}
              style={{ marginLeft: '10px' }}>
              <option value='all'>All Time</option>
              <option value={60}>Last 60 days</option>
              <option value={30}>Last 30 days</option>
              <option value={15}>Last 15 days</option>
              <option value='today'>Today</option>
            </select>
          </div>
        </div>
      </Stack>
      <div style={{ display: 'flex' }}>
        {!loading && (
          <div className='container' style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='chart-container'>
              <ApexCharts
                type='bar'
                height={300}
                options={{
                  chart: {
                    height: 850,
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: data.map((entry) => entry.name),
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                      isFunnel: true,
                      distributed: true,
                      barHeight: '80%',
                    },
                  },
                  colors: ['#F44F5E', '#D863B1', '#B57BED', '#62ACEA', '#4BC3E6'],
                  dataLabels: {
                    enabled: true,
                    formatter: function (val: any, _opt: any) {
                      return val.toString();
                    },
                    style: {
                      fontSize: '12px',
                      fontWeight: 'bold',
                    },
                  },
                }}
                series={[
                  {
                    name: 'Count',
                    data: data.map((entry) => entry.value),
                  },
                ]}
              />
            </div>
          </div>
        )}

        {!loading && (
          <div className='container' style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='chart-container'>
              <ApexCharts
                type='bar'
                height={300}
                options={{
                  chart: {
                    height: 850,
                    toolbar: {
                      show: false,
                    },
                  },
                  xaxis: {
                    categories: data2.map((entry) => entry.name),
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                      isFunnel: true,
                      distributed: true,
                      barHeight: '80%',
                    },
                  },
                  colors: ['#F44F5E', '#D863B1', '#B57BED', '#62ACEA', '#4BC3E6'],
                  dataLabels: {
                    enabled: true,
                    formatter: function (val: any, _opt: any) {
                      return val.toString();
                    },
                    style: {
                      fontSize: '12px',
                      fontWeight: 'bold',
                    },
                  },
                }}
                series={[
                  {
                    name: 'Count',
                    data: data2.map((entry) => entry.value),
                  },
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CandidateCountFunnelChart;
