import dayjs, { Dayjs } from 'dayjs';
import { Typography, Divider, Box, Paper, Link } from '@mui/material';
import './new-dashboard.scss';
import { ReactComponent as EventIcon } from '../../assets/dashboard-assets/event.svg';
import NoRecordsImg from '../../assets/dashboard-assets/no-records.png';
import useNavigate from '../../../common/useNavigate';
import { DatePicker } from '@mui/x-date-pickers';
import { useState, useMemo } from 'react';

interface Interview {
  startDate: string;
  endDate: string;
  interviewType: boolean;
  candidateName: string;
  candidateId: string;
  sjdName: string;
  sjdId: string;
}

interface UpcomingInterviewProp {
  interviews: Interview[];
}

const UpCommingInterviews = ({ interviews }: UpcomingInterviewProp) => {
  const [navigate] = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const formatDate = (timestamp: Dayjs) => dayjs(timestamp).format('dddd, MMMM DD, YYYY');
  const formatTime = (timestamp: string) => dayjs(timestamp).format('hh:mm A');

  const filteredInterviews = useMemo(
    () => interviews.filter((e) => dayjs(e.startDate).isSame(selectedDate, 'day')),
    [interviews, selectedDate],
  );

  return (
    <Paper sx={{ height: '100%', width: '100%' }}>
      <div className='first-div-header'>
        <div className='first-div-header-left'>
          <EventIcon width='2rem' />
          <span>Upcoming Interviews</span>
        </div>
        <div className='first-div-header-right'>
          <EventIcon width='2rem' />
          <span>{formatDate(selectedDate)}</span>
          <DatePicker
            disablePast
            value={selectedDate}
            className='notification-date'
            onChange={(newValue) => newValue && setSelectedDate(newValue)}
          />
        </div>
      </div>
      <div className='interview-div-container'>
        {filteredInterviews.length > 0 ? (
          filteredInterviews.map((interview, index) => (
            <div key={index}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ minWidth: '15%', textAlign: 'right', paddingRight: '8px' }}>
                  <Typography sx={{ fontSize: 'small', fontWeight: 400, color: '#333' }}>
                    {formatTime(interview.startDate)}
                  </Typography>
                  <Typography sx={{ fontSize: 'small', fontWeight: 400, color: '#9e9e9e' }}>
                    {formatTime(interview.endDate)}
                  </Typography>
                </Box>
                <Divider orientation='vertical' flexItem className='interview-divider' />
                <Box>
                  <Typography sx={{ fontSize: 'small' }} variant='body2' color='textSecondary'>
                    {interview.interviewType ? 'Team Meeting' : 'In Person'}
                  </Typography>
                  <Typography variant='body1' sx={{ fontSize: 'small', color: '#333' }}>
                    Interview with{' '}
                    <Link
                      onClick={() =>
                        navigate([
                          {
                            label: interview.candidateName,
                            link: `/candidate/${btoa(interview.sjdId)}/${btoa(
                              interview.candidateId,
                            )}`,
                          },
                        ])
                      }
                      sx={{ cursor: 'pointer' }}>
                      {interview.candidateName}
                    </Link>{' '}
                    for the{' '}
                    <Link
                      onClick={() =>
                        navigate([
                          {
                            label: interview.sjdName,
                            link: `/job-definition-details/${btoa(interview.sjdId)}/${btoa(
                              'false',
                            )}`,
                          },
                        ])
                      }
                      sx={{ cursor: 'pointer' }}>
                      {interview.sjdName}
                    </Link>{' '}
                    job
                  </Typography>
                </Box>
              </Box>
            </div>
          ))
        ) : (
          <div className='no-records-container'>
            <img src={NoRecordsImg} alt='no-records' />
            No Records Found
          </div>
        )}
      </div>
    </Paper>
  );
};

export default UpCommingInterviews;
