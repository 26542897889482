import React, { CSSProperties, useState } from 'react';
import './tad-slider.scss';
import { Box, Menu } from '@mui/material';
import { getNameFromId } from './../../../common/commonUtil';
import { LookupTypes } from '../../../../common/lookupTypes';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
  selectedIndex?: number;
  currentStatus?: string;
  currentSubStatus?: string | null;
  sjd: any;
}

const SliderComponent = ({ selectedIndex = 1, currentSubStatus, sjd }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSubStatuses, setSelectedSubStatuses] = useState<{
    name: string;
    subStatuses?: { name: string }[];
  } | null>(null);

  const milestoneOrder = [
    'Profile Uploaded',
    'Profiling',
    'Interview Scheduled',
    'HR Discussion',
    'Document Verification',
    'Offer',
    'Joining Status',
    'Employee',
  ];

  const milestoneMapping: Record<string, string> = {
    'Client Interviews': 'Interview Scheduled',
    'Technical Interviews': 'Interview Scheduled',
    'Hr Interviews': 'HR Discussion',
    'Initial Round': 'Profiling',
  };

  const InterviewProcessStatus: {
    subStatuses?: { name: string }[];
    name: string;
  }[] = milestoneOrder.map((name) => ({
    name,
    subStatuses: [],
  }));

  const targetIndexes: string[] = [];

  const getConditionalSubStatuses = (baseName: string) => {
    if (currentSubStatus === 'Rejected') {
      if (baseName === 'Offer') return [{ name: `${baseName} Declined`, subIndex: 3 }];
      else return [{ name: `${baseName} Rejected`, subIndex: 3 }];
    } else if (currentSubStatus === 'On Hold') {
      return [{ name: `${baseName} On Hold`, subIndex: 2 }];
    } else {
      if (baseName === 'Offer') return [{ name: `${baseName} Accepted`, subIndex: 3 }];
      else return [{ name: `${baseName} Cleared`, subIndex: 3 }];
    }
  };

  const predefinedMilestones = [
    {
      index: 4,
      name: 'Profiling',
      subStatuses: [
        { name: 'QC Rated', subIndex: 2 },
        { name: 'Profiler Rated', subIndex: 1 },
      ],
    },
    {
      index: 5,
      name: 'Document Verification',
      subStatuses: [
        { name: 'Verification Started', subIndex: 1 },
        ...getConditionalSubStatuses('Verification'),
      ],
    },
    {
      index: 6,
      name: 'Offer',
      subStatuses: [
        { name: 'Offer Approval Raised', subIndex: 5 },
        { name: 'Offer Released', subIndex: 4 },
        ...getConditionalSubStatuses('Offer'),
      ],
    },
    {
      index: 7,
      name: 'Joining Status',
      subStatuses: [
        { name: 'Joined', subIndex: 3 },
        { name: 'Joining No Show', subIndex: 2 },
        { name: 'Yet to Join', subIndex: 1 },
      ],
    },
  ];

  // Merge predefined milestones into `targetIndexes`**
  predefinedMilestones.forEach((milestone) => {
    const existingMilestone = InterviewProcessStatus.find((m) => m.name === milestone.name);

    if (existingMilestone) {
      existingMilestone.subStatuses = [
        ...milestone.subStatuses,
        ...(existingMilestone.subStatuses || []), // Ensure it's always an array
      ];
    } else {
      InterviewProcessStatus.push(milestone);
    }
  });

  //  Populate `targetIndexes` using `sjd`**
  sjd.interviewProcess?.forEach((stage: any) => {
    const milestoneName =
      milestoneMapping[getNameFromId(stage.stageName, LookupTypes.TAD_INTERVIEW_MAIN_STAGE)];

    if (!milestoneName) return;

    const milestone = InterviewProcessStatus.find((m) => m.name === milestoneName);
    if (!milestone) return;

    const allSubStatuses: { name: string }[] = [];

    stage.item.forEach((item: any) => {
      const subStatusName = getNameFromId(item.name, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE);

      // Push scheduled status if applicable
      if (milestone.name === 'HR Discussion' || milestone.name === 'Interview Scheduled') {
        allSubStatuses.push({
          name: `${subStatusName} Scheduled`,
        });
      } else {
        allSubStatuses.push({
          name: subStatusName,
        });
      }

      // Conditionally push "Rejected" and "On Hold"
      if (
        currentSubStatus === 'Rejected' &&
        `${subStatusName} Scheduled` ===
          getNameFromId(selectedIndex, LookupTypes.TAD_CANDIDATE_STATUS)
      ) {
        allSubStatuses.push({
          name: `${subStatusName} Rejected`,
        });
      } else if (
        currentSubStatus === 'On Hold' &&
        `${subStatusName} Scheduled` ===
          getNameFromId(selectedIndex, LookupTypes.TAD_CANDIDATE_STATUS)
      ) {
        allSubStatuses.push({
          name: `${subStatusName} On Hold`,
        });
      } else {
        allSubStatuses.push({
          name: `${subStatusName} Cleared`,
        });
      }
    });

    milestone.subStatuses = [...(milestone.subStatuses || []), ...allSubStatuses];
  });
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLDivElement>,
    target: { name: string; subStatuses?: { name: string }[] },
  ) => {
    // setSelectedMilestone(target.index);
    if (target.subStatuses && target.subStatuses?.length > 0) {
      setAnchorEl(event.currentTarget);
      setSelectedSubStatuses(target);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSubStatuses(null);
  };

  InterviewProcessStatus.forEach((milestone) => {
    if (milestone.subStatuses && milestone.subStatuses.length > 0) {
      milestone.subStatuses.forEach((subStatus) => {
        targetIndexes.push(subStatus.name);
      });
    } else {
      targetIndexes.push(milestone.name);
    }
  });

  const checkProgress = (target: any) => {
    const candidateStatus = getNameFromId(selectedIndex, LookupTypes.TAD_CANDIDATE_STATUS);
    const candidateStatusIndex = targetIndexes.findIndex((e) => e === candidateStatus);
    let targetIndex = targetIndexes.findIndex((e) => target.name === e);

    if (targetIndex === -1 && target.subStatuses) {
      targetIndex = targetIndexes.findIndex((val: any) =>
        target.subStatuses.some((i: any) => i.name === val),
      );
    }
    return targetIndex <= candidateStatusIndex;
  };

  const checkSubStatusProgress = (subStatus: any) => {
    const candidateStatus = getNameFromId(selectedIndex, LookupTypes.TAD_CANDIDATE_STATUS);
    const candidateStatusIndex = targetIndexes.findIndex((e) => e === candidateStatus);
    const targetIndex = targetIndexes.findIndex((e) => subStatus.name === e);
    return targetIndex <= candidateStatusIndex;
  };

  const calculateProgress = () => {
    const totalSteps = InterviewProcessStatus.length;
    const widthForEachStep = 100 / totalSteps;

    const candidateStatus = getNameFromId(selectedIndex, LookupTypes.TAD_CANDIDATE_STATUS);
    const candidateStatusIndex = InterviewProcessStatus.findIndex(
      (e) =>
        e.name === candidateStatus ||
        (Array.isArray(e.subStatuses) && e.subStatuses.some((sub) => sub.name === candidateStatus)),
    );

    if (candidateStatusIndex === -1) return 0;

    const offset = widthForEachStep / 2;
    return Math.min(candidateStatusIndex * widthForEachStep + offset, 100);
  };

  const circleStyles = (color: string): CSSProperties => ({
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: color,
    display: 'block',
    padding: '0px',
    margin: '0px',
  });

  const iconStyle = (color: string): CSSProperties => ({
    width: '16px',
    height: '16px',
    color: color,
    display: 'block',
    padding: '0.7rem 0rem 0rem 0rem',
    margin: '0px',
  });

  return (
    <div className='slider' style={{ paddingBottom: '2px', marginBottom: '1rem', maxWidth: '80%' }}>
      <div className='progress-bar'>
        <div
          className='completed'
          style={{
            width: `${calculateProgress()}%`,
            height: '3px',
            background: 'var(--Primary-Blue-500, #1976D2)',
          }}></div>
        <div
          className='pending'
          style={{
            width: `${100 - calculateProgress()}%`,
            height: '3px',
          }}></div>
      </div>
      <div
        className='icon-div'
        style={{ marginTop: '21px', display: 'flex', justifyContent: 'space-evenly' }}>
        {InterviewProcessStatus.map((target, index) => {
          // const widthOfEachStep = 100 / InterviewProcessStatus.length;
          const isCompleted = checkProgress(target);
          const iconColor = isCompleted ? 'var(--Primary-Blue-500, #1976D2)' : '#ccc';

          return (
            <div
              key={index}
              style={{ flex: 1, textAlign: 'center', alignItems: 'center', height: '4rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={circleStyles(iconColor)} />
                <Box
                  sx={{
                    width: '2px',
                    height: '10px',
                    backgroundColor: iconColor,
                  }}
                />
              </div>
              <div onClick={(event) => handleMenuOpen(event, target)} style={{ cursor: 'pointer' }}>
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#333',
                  }}>
                  {target.name}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          transform: 'translateX(50px) translateY(5px)', // Adjust based on icon width
        }}
        MenuListProps={{
          sx: { paddingTop: 0 }, // Removes top padding
        }}>
        {selectedSubStatuses?.subStatuses?.map((subStatus, subIndex) => {
          const isRejected = subStatus.name.toLowerCase().includes('rejected');
          const isOnHold = subStatus.name.toLowerCase().includes('on hold');

          const circleIcon = isRejected ? (
            <div style={circleStyles('#DC3857')} />
          ) : isOnHold ? (
            <div style={circleStyles('#ffa800')} />
          ) : (
            <div style={circleStyles(checkSubStatusProgress(subStatus) ? '#1976D2' : '#ccc')} />
          );

          const icon = isRejected ? (
            <CloseIcon sx={iconStyle('#DC3857')} />
          ) : isOnHold ? (
            <PauseIcon style={iconStyle('#ffa800')} />
          ) : checkSubStatusProgress(subStatus) ? (
            <CheckIcon style={iconStyle('#1976D2')} />
          ) : (
            <div style={iconStyle('#FFF')}></div>
          );

          return (
            <div
              key={subIndex}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0rem 1rem',
                alignItems: 'center',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  fontSize: '14px',
                  justifyContent: 'space-between',
                  gap: '1rem',
                  color: isRejected
                    ? '#DC3857'
                    : isOnHold
                    ? '#ffa800'
                    : checkSubStatusProgress(subStatus)
                    ? '#1976D2'
                    : '#000',
                  padding: '0rem 0.7rem',
                }}>
                {subStatus.name}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '-1px',
                  }}>
                  <Box
                    sx={{
                      width: '2px',
                      height: '15px', // Adjust height as needed
                      backgroundColor: isRejected
                        ? '#DC3857'
                        : isOnHold
                        ? '#ffa800'
                        : checkSubStatusProgress(subStatus)
                        ? '#1976D2'
                        : '#ccc',
                    }}
                  />
                  {circleIcon}
                </Box>
              </Box>
              {icon}
            </div>
          );
        })}
      </Menu>
    </div>
  );
};

export default SliderComponent;
