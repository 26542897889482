import React, { useEffect, useMemo, useRef, useState } from 'react';
import './tad-candidate-details-page.scss';
import {
  AlertColor,
  Button,
  Chip,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { getNameFromId, handleCandidateInitials, isTadPermissions } from '../../common/commonUtil';
import CandidateHistory from './add-candidate/candidate-history';
import CandidateJobDefinition from './add-candidate/candidate-job-definition';
import CandidateMatchingJob from './add-candidate/candidate-matchingJob';
import TadCandidateAttachments from './add-candidate/attachments';
import CandidateEmployementDetails from './add-candidate/candidate-employmentdetails';
import CandidateInterviewes from './add-candidate/candidate-interviews';
import InitialScreeningRatings from './initial-screening-ratings';
import TadInterviewFeedback from './tad-interview-feedback';
import TadPageHeader from '../common/page-header/tad-page-header';
import { ReactComponent as LocationIcon } from '../../assets/candidate-assets/location_on.svg';
import { ReactComponent as EmailIcon } from '../../assets/candidate-assets/email.svg';
import { ReactComponent as PhoneNumberIcon } from '../../assets/candidate-assets/call.svg';
import { ReactComponent as TrendingUp } from '../../assets/candidate-assets/trending_up.svg';
import { ReactComponent as TrendingDown } from '../../assets/candidate-assets/trending_up (1).svg';
import { ReactComponent as DotsIcon } from '../../assets/dashboard-assets/more_vert.svg';
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';

// import { ReactComponent as AddIcon } from '../../assets/sjd/add (1).svg';
// import { ReactComponent as DownloadIcon } from '../../assets/candidate-assets/download.svg';
// import { ReactComponent as CopyIcon } from '../../assets/common-assets/content_copy.svg';
// import { ReactComponent as EmployeesIcon } from '../../assets/dashboard-assets/account_box.svg';
// import { ReactComponent as ShareIcon } from '../../assets/candidate-assets/share (1).svg';
import { ReactComponent as EditIcon } from '../../assets/sjd/Edit.svg';
// import { ReactComponent as ArchiveIcon } from '../../assets/sjd/Archive.svg';

import SliderComponent from '../common/slider/tad-slider';

import { getCandidate, tadUpdateResume } from '../../service/tadCandidateService';
import { useParams } from 'react-router-dom';
import CandidateCertificateTable from './add-candidate/candidate-certificate-table';
import { LookupTypes } from '../../../common/lookupTypes';
import { filterSjdInfo, getIdFromName, getInitials } from '../../../common/commonUtil';
import AdditionalDetails from './add-candidate/additional-details';
import CandidateEducationTable from './add-candidate/candidate-education-table';
import CandidatePrimarySkills from './add-candidate/primary-skills';
import CandidateDetails from './add-candidate/candidate-details';
import { TadCandidateDetailsModel } from '../../models/tad-candidate-form-model';
import CandidateResume from './add-candidate/candidate-resume';
import AddCandidateToJobDrawer from './add-candidate/AddCandidateToJobDrawer';
import ContactCard from '../common/contact-card';
import Notify from '../../common/notify';
import useNavigate from '../../../common/useNavigate';
import globalObject from '../../../common/global-variables';
import { getTadSjd } from '../../service/tadSjdService';
import TadCandidateQuestionnaire from './tad-candidate-questionnaire';
import { getUserById } from '../../../service/userService';
import { getTadSkills } from '../../../service/lookupService';
import { TAD_CANDIDATE_SUB_STATUS, TADMODULE, TADROLES } from '../../../constants/common';
import TadScreenLoader from '../common/tad-screen-loader';
import { ReactComponent as DropDownArrow } from '../../assets/sjd/dropdownarrow.svg';

const CandidateDetailsPage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(actionAnchorEl);
  const [editPermission, setEditPermission] = useState(false);
  const [isEvaluator, setIsEvalutor] = useState(
    globalObject.userObject.roles[0].id === getIdFromName(TADROLES.EVALUATOR, LookupTypes.TAD_ROLE),
  );
  let { candidateId, sjdId } = useParams();
  if (sjdId) sjdId = atob(sjdId);
  if (candidateId) candidateId = atob(candidateId);
  const [candidate, setCandidate] = useState({} as any);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactAnchor, setContactAnchor] = useState<null | HTMLElement>(null);
  const [contact, setContact] = useState({} as any);
  const [showToast, setShowToast] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [navigate] = useNavigate();
  const [existingfeedback, setExistingfeedback] = useState();
  const [user, setUser] = useState({} as any);
  const [sjd, setSjd] = useState({} as any);
  const [skills, setSkills] = useState([] as any[]);
  const [resumeList, setResumeList] = useState();
  const [submittedFeedBack, setSubmittedFeedback] = useState(false);
  const [currentStatusIndex, setCurrentStatusIndex] = useState(1);
  const [currentSubStatusIndex, setCurrentSubStatusIndex] = useState<string | null>(null);
  // const [candidateStatusList, setCandidateStatusList] = useState([] as any[]);
  // const [currentStatusLabel, setCurrentStatusLabel] = useState('');
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  const headersWithSjd = [
    { id: 1, label: 'Summary' },
    { id: 2, label: 'Resume' },
    { id: 3, label: 'Skills' },
    { id: 4, label: 'Additional Details' },
    { id: 5, label: 'Initial Screening' },
    { id: 6, label: 'Certifications' },
    { id: 7, label: 'Education' },
    { id: 8, label: 'Interviews' },
    { id: 9, label: 'Employment Details' },
    { id: 10, label: 'Interview Feedback' },
    { id: 11, label: 'Attachments' },
    { id: 12, label: 'Matching Jobs' },
    { id: 13, label: 'Questionnaire' },
    { id: 14, label: 'History' },
    { id: 15, label: 'Job Definitions' },
  ];

  const headersWithOutSjd = [
    { id: 1, label: 'Summary' },
    { id: 2, label: 'Resume' },
    { id: 3, label: 'Skills' },
    { id: 4, label: 'Additional Details' },
    { id: 5, label: 'Certifications' },
    { id: 6, label: 'Education' },
    { id: 7, label: 'Employment Details' },
    { id: 8, label: 'Attachments' },
    { id: 9, label: 'Matching Jobs' },
    { id: 10, label: 'History' },
  ];

  const filteredHeadersWithSjd = useMemo(() => {
    return isEvaluator
      ? [
          { id: 1, label: 'Job Definitions' },
          { id: 2, label: 'Resume' },
          { id: 3, label: 'Interview Feedback' },
        ]
      : headersWithSjd;
  }, [isEvaluator, headersWithSjd]);

  const selectedHeaders = sjdId ? filteredHeadersWithSjd : headersWithOutSjd;
  const references = useRef<(HTMLDivElement | null)[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (references.current.length !== selectedHeaders.length) {
      references.current = selectedHeaders.map((_, index) => references.current[index] || null);
    }

    const element = references.current[newValue];
    if (element) {
      const elementPos = element.getBoundingClientRect().top;
      const tabsPos = document.getElementById('page-tabs')?.getBoundingClientRect().top;
      const offsetPos = elementPos + window.pageYOffset - (!tabsPos ? 0 : tabsPos + 5);
      window.scrollTo({
        top: offsetPos,
        behavior: 'smooth',
      });
      setSelectedTab(newValue);
    }
  };

  const loadCandidate = async (_statusList?: any[]) => {
    try {
      setLoading(true);
      const candidateRes = await getCandidate(sjdId, candidateId);
      setCandidate(candidateRes.data);
      isExist(candidateRes.data?.sjdCandidateInfo, candidateRes.data.createdBy);
      const userPromise = getUserById(candidateRes.data.createdBy, true).catch(() => null);
      const skillsPromise = getTadSkills().catch(() => null);
      const [userRes, skillsRes] = await Promise.all([userPromise, skillsPromise]);
      if (userRes) setUser(userRes.data);
      if (skillsRes) setSkills(skillsRes.data);
      let oldestUnsubmittedFeedback: any = null;
      candidateRes.data.interview?.forEach((val: any) => {
        if (val.evaluator.id === globalObject.userObject.id && !val.feedback) {
          if (
            !oldestUnsubmittedFeedback ||
            new Date(val.startDate) < new Date(oldestUnsubmittedFeedback.startDate)
          ) {
            oldestUnsubmittedFeedback = val;
          }
        }
      });

      if (oldestUnsubmittedFeedback) {
        setExistingfeedback(oldestUnsubmittedFeedback);
      }
      if (sjdId && candidateRes.data.sjdCandidateInfo) {
        const sjdInfo = filterSjdInfo(candidateRes.data.sjdCandidateInfo, sjdId);
        const candidateStatus = sjdInfo.candidateStatusId;
        const candidateSubStatus = sjdInfo.candidateSubStatus;
        setCurrentStatusIndex(candidateStatus);
        setCurrentSubStatusIndex(candidateSubStatus);
      }
    } catch (_err) {
      alert('Something went wrong. Please try later!', false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!sjdId) return;
    setLoading(true);
    getTadSjd(sjdId)
      .then((resp) => {
        setSjd({ ...resp.data });
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sjdId]);

  const alert = (msg: string, success: boolean) => {
    setToastMsg({
      severity: success ? 'success' : 'error',
      msg,
    });
    setShowToast(true);
  };

  useEffect(() => {
    setLoading(true);
    loadCandidate();
  }, [submittedFeedBack]);

  const actionsList = [
    // {
    //   name: 'Add to Job Definition',
    //   icon: AddIcon,
    // },
    // { name: 'Download Resume', icon: DownloadIcon },
    // { name: 'Check for Duplicate', icon: CopyIcon },
    // { name: 'Mark as an employee', icon: EmployeesIcon },
    // { name: 'Share', icon: ShareIcon },
    { name: 'Edit', icon: EditIcon },
    // { name: 'Archive', icon: ArchiveIcon },
  ];

  // const sampleData = {
  //   noOfSteps: 7.5,
  //   selectedIndex: 5,
  //   currentStatus: 'Offer Accepted',
  //   sjd: {
  //     profileUpload: { status: 'Completed', date: '2024-10-01' },
  //     profiling: { status: 'Completed', date: '2024-10-03' },
  //     interviewScheduled: [
  //       { round: 'L1', status: 'Cleared', date: '2024-10-05' },
  //       { round: 'L2', status: 'Cleared', date: '2024-10-08' },
  //       { round: 'Client', status: 'Cleared', date: '2024-10-10' },
  //     ],
  //     hrDiscussion: { status: 'Cleared', date: '2024-10-12' },
  //     documentVerification: { status: 'Cleared', date: '2024-10-15' },
  //     offer: { status: 'Accepted', date: '2024-10-20' },
  //     joiningStatus: { status: 'Joined', date: '2024-10-25' },
  //     employee: { status: 'Active', date: '2024-11-01' },
  //   },
  // };

  const isExist = (sjdCandidateInfo: any, createdBy: any) => {
    const { userObject } = globalObject;
    const { roles, id: currentUserId } = userObject;
    const isEvaluator = roles[0]?.id === getIdFromName(TADROLES.EVALUATOR, LookupTypes.TAD_ROLE);
    const currentSjdCandidateInfo = sjdCandidateInfo?.find((e: any) => e.sjdId === Number(sjdId));
    const isCreatedBy = currentUserId === createdBy;
    if (!currentSjdCandidateInfo) {
      setEditPermission(isCreatedBy);
      return;
    }
    const hiringTeam = currentSjdCandidateInfo.hiringTeam.map((e: any) => e.id);
    setIsEvalutor(isEvaluator);
    if (isEvaluator) {
      setEditPermission(false);
    } else {
      setEditPermission(hiringTeam.includes(currentUserId));
    }
  };

  const resumeUpdate = (): Promise<boolean> => {
    const obj = {
      id: candidate.id,
      name: candidate.name,
      email: candidate.email,
      phoneNumber: candidate.phoneNumber,
      location: candidate.location,
      experience: candidate.experience,
      skills: candidate.skills,
      isMigrated: candidate.isMigrated,
      oldId: candidate.resumeId,
    };
    setLoading(true);
    tadUpdateResume(resumeList, obj)
      .then((_resp) => {
        setLoading(false);
        loadCandidate();
        alert('Details updated successfully', true);
        return new Promise((resolve, _reject) => {
          resolve(true);
        });
      })
      .then(() => {
        window.location.reload();
      })
      .catch((_err) => {
        setLoading(false);
        alert('Something went wrong. Please try later!', false);
        return new Promise((_resolve, reject) => {
          reject(true);
        });
      });
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  };

  return (
    <div className='main-div'>
      <div>
        <TadPageHeader title={'Candidate Details'} />
      </div>
      <div>
        <div className='header'>
          <Paper
            sx={{
              flexDirection: 'column',
              display: 'flex',
              marginBottom: '1.5rem',
            }}>
            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    padding: '1.69rem 0rem 1rem 1.19rem',
                  }}>
                  <div
                    style={{
                      backgroundColor: '#DC3857',
                      color: 'white',
                      padding: '0.5rem',
                      borderRadius: '50%',
                      width: '3rem',
                      height: '3rem',
                      fontSize: '1.5rem',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                    }}>
                    {handleCandidateInitials(candidate?.name)}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='overview' style={{ gap: '0.5rem' }}>
                      <span style={{ fontSize: '1.5rem', width: '11rem', lineHeight: '2rem' }}>
                        {candidate.name}
                        <div className='overview' style={{ marginBottom: '0.87rem' }}>
                          <span style={{ fontSize: '1rem' }}>{candidate.currentRole}</span>
                        </div>
                      </span>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <img
                          src={require('../../assets/candidate-assets/linkedin.png')}
                          style={{
                            borderRadius: '0.3125rem',
                            color: '#333333',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                          }}
                        />
                        {candidate?.sjdCandidateInfo?.length > 0 ? (
                          <Chip
                            label={getNameFromId(
                              candidate?.sjdCandidateInfo?.filter(
                                (sjd: any) => sjd.sjdId == sjdId,
                              )[0].candidateStatusId,
                              LookupTypes.TAD_CANDIDATE_STATUS,
                            )}
                            style={{
                              borderRadius: '0.3125rem',
                              backgroundColor: 'var(--Primary-Blue-100, #B8D5F1)',
                              color: '#333333',
                              fontWeight: '400',
                            }}
                          />
                        ) : (
                          <Chip
                            label={'Profile Uploaded'}
                            style={{
                              borderRadius: '0.3125rem',
                              backgroundColor: 'var(--Primary-Blue-100, #B8D5F1)',
                              color: '#333333',
                              fontWeight: '400',
                            }}
                          />
                        )}

                        {candidate?.sjdCandidateInfo?.length > 0 &&
                          candidate?.sjdCandidateInfo?.filter((sjd: any) => sjd.sjdId == sjdId)[0]
                            .candidateSubStatus && (
                            <Chip
                              label={
                                candidate?.sjdCandidateInfo
                                  ? `${
                                      candidate?.sjdCandidateInfo?.filter(
                                        (sjd: any) => sjd.sjdId == sjdId,
                                      )[0].candidateSubStatus
                                    }`
                                  : ''
                              }
                              style={{
                                borderRadius: '0.3125rem',
                                backgroundColor:
                                  candidate?.sjdCandidateInfo &&
                                  candidate?.sjdCandidateInfo?.filter(
                                    (sjd: any) => sjd.sjdId == sjdId,
                                  )[0].candidateSubStatus === 'Rejected'
                                    ? 'var(--Status-Red-100, #F4C1CB)'
                                    : candidate?.sjdCandidateInfo?.filter(
                                        (sjd: any) => sjd.sjdId == sjdId,
                                      )[0].candidateSubStatus === 'On Hold'
                                    ? 'var(--Status-Yellow-100, #FEE6B8)'
                                    : '',
                                color: '#333333',
                                fontWeight: '400',
                                // textTransform: 'uppercase',
                              }}
                            />
                          )}
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                      <div className='overview'>
                        <LocationIcon style={{ color: '#868686', marginRight: '1rem' }} />
                        <span style={{ fontSize: '1rem' }}>{candidate.location}</span>
                      </div>
                      <div className='overview'>
                        <EmailIcon style={{ marginRight: '1rem' }} />
                        <span style={{ fontSize: '1rem', textTransform: 'lowercase' }}>
                          {candidate.email}
                        </span>
                      </div>
                      <div className='overview'>
                        <PhoneNumberIcon style={{ marginRight: '1rem', color: '#868686' }} />
                        <span style={{ fontSize: '1rem' }}>
                          {candidate.phoneNumber}
                          {/* {parseLocation(sjd.jobLocation).join(', ')} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '1.69rem 0rem 1rem 1.19rem' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Career Growth Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>{candidate.careerGrowthScore}</span>
                          <TrendingUp style={{ color: '#4BB543' }} />
                        </div>
                      </div>
                      <div style={{ marginRight: '3rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Suitability Score
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <span className='text-label'>
                            {candidate?.sjdCandidateInfo &&
                              candidate?.sjdCandidateInfo[0]?.suitabilityScore}
                          </span>
                          <TrendingDown style={{ color: '#DC3857' }} />
                        </div>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Experience
                        </Typography>
                        <span className='text-label'>{`${candidate.totalExperience} ${
                          candidate.totalExperience > 1 ? 'Years' : 'Year'
                        }`}</span>
                      </div>
                      <div style={{ marginRight: '3rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Current Company
                        </Typography>
                        <span className='text-label'>{candidate.lastEmployer}</span>
                      </div>
                    </div>
                    <div style={{ marginRight: '3rem' }}>
                      <div style={{ marginRight: '3rem', marginBottom: '3.06rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Notice Period (Days)
                        </Typography>
                        <span className='text-label'>{`${candidate.noticePeriod} Days`}</span>
                      </div>
                      <div
                        style={{
                          marginRight: '3rem',
                        }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Source
                        </Typography>
                        <span className='text-label'>
                          {getNameFromId(candidate.sourceChannel, LookupTypes.TAD_SOURCE_CHANNEL)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div style={{ marginBottom: '2.5rem' }}>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Uploaded by
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          {user.profilePicture !== null || user?.profilePicture?.length > 0 ? (
                            <img
                              style={{
                                width: '1.89731rem',
                                height: '1.89731rem',
                                borderRadius: '50%',
                              }}
                              src={user.profilePicture}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: '#DC3857',
                                color: 'white',
                                borderRadius: '50%',
                                width: '1.89731rem',
                                height: '1.89731rem',
                                fontWeight: '400',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                              }}>
                              {user && getInitials(user)}
                            </div>
                          )}

                          <span
                            style={{
                              cursor: 'pointer',
                              color: '#1976d2',
                              textDecoration: 'underline',
                            }}
                            onClick={(e) => {
                              setContact(user);
                              setContactAnchor(e.currentTarget);
                            }}>
                            {`${user?.firstName} ${user?.lastName}`}
                          </span>
                          <ContactCard
                            contact={contact}
                            anchorEl={contactAnchor}
                            onClose={() => {
                              setContactAnchor(null);
                              setContact(null);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <Typography
                          className='feildHeader'
                          sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                          Sponsorship Required
                        </Typography>
                        <span className='text-label'>
                          {candidate.sponsorshipRequired ? 'Yes' : 'No'}
                        </span>
                      </div>
                    </div>
                    <div>
                      <IconButton
                        disabled={!editPermission}
                        onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <DotsIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}>
                        {actionsList.map((action, index) => (
                          <MenuItem
                            key={index}
                            disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
                            onClick={() => {
                              setAnchorEl(null);
                              if (action.name === 'Add to Job Definition') {
                                setOpenDrawer(true);
                              } else if (action.name === 'Edit') {
                                navigate([
                                  {
                                    label: 'Candidate Edit',
                                    link: `/candidate-edit/${btoa(candidate.id)}`,
                                  },
                                ]);
                              }
                            }}>
                            <ListItemIcon>
                              <action.icon
                                style={{
                                  color: action.name === 'Check for Duplicate' ? '#1976d2' : '',
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={action.name} />
                          </MenuItem>
                        ))}
                      </Menu>
                      <Drawer
                        sx={{ width: '21.0625rem', flexShrink: 0 }}
                        anchor='right'
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}>
                        <AddCandidateToJobDrawer candidate={candidate} />
                      </Drawer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Divider />
            </div>
            <div
              style={{
                padding: '2.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <SliderComponent
                selectedIndex={currentStatusIndex}
                currentSubStatus={currentSubStatusIndex}
                sjd={sjd}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '1rem',
                  // marginTop: '1rem', // Space between progress bar and buttons
                }}>
                <Button
                  disabled
                  sx={{
                    background: '#fff',
                    color: '#333',
                    borderRadius: '5px',
                    border: '1px solid #C1C0C0',
                    fontSize: '12px',
                    textTransform: 'none',
                    fontWeight: '400',
                    '&:hover': {
                      backgroundColor: '#fafafa',
                      boxShadow: 'none',
                    },
                  }}>
                  Move to Next Stage
                  <DropDownArrow style={{ width: '10px', color: '#333', marginLeft: '3px' }} />
                </Button>
                <Button
                  disabled
                  id='basic-button'
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={(e) => setActionAnchorEl(e.currentTarget)}
                  sx={{
                    background: '#fff',
                    color: '#333',
                    borderRadius: '5px',
                    border: '1px solid #C1C0C0',
                    fontSize: '12px',
                    textTransform: 'none',
                    fontWeight: '400',
                    '&:hover': {
                      backgroundColor: '#fafafa',
                      boxShadow: 'none',
                    },
                  }}>
                  Action{' '}
                  <DropDownArrow style={{ width: '12px', color: '#333', marginLeft: '5px' }} />
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={actionAnchorEl}
                  open={openMenu}
                  onClose={() => setActionAnchorEl(null)}
                  sx={{ padding: '0px', transform: 'translateY(5px)' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  {Object.values(TAD_CANDIDATE_SUB_STATUS).map((status, index) => (
                    <MenuItem
                      style={{ fontSize: '12px', padding: '2px 10px' }}
                      key={index}
                      onClick={() => {
                        setActionAnchorEl(null);
                        // setReason('');
                        // setAlertBoxType(status);
                        // setShowAlert(true);
                      }}>
                      {status}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Paper>
        </div>
        <div style={{ width: '100%' }} id='page-tabs'>
          <Tabs
            className='tab'
            sx={{ margin: '0 !important', borderBottom: '1px solid rgb(193, 192, 192)' }}
            value={selectedTab}
            onChange={handleChange}
            variant='scrollable'>
            {selectedHeaders.map((header, index) => (
              <Tab
                ref={(el) => {
                  references.current[index] = el as HTMLDivElement;
                }}
                className='selected-tab'
                sx={{
                  color: 'var(--Body-P, var(--Primary-Dark-grey-500, #333))',
                  fontWeight: 400,
                  fontSize: '1rem',
                  textTransform: 'none',
                }}
                key={index}
                value={index}
                label={
                  header.label === 'Interviews'
                    ? `${header.label} (${candidate.interview ? candidate.interview?.length : 0})`
                    : header.label === 'Attachments'
                    ? `Attachments (${candidate.documents ? candidate.documents?.length : 0})`
                    : header.label === 'Job Definitions'
                    ? `Job Definitions (${
                        candidate.sjdCandidateInfo ? candidate.sjdCandidateInfo?.length : 0
                      })`
                    : header.label
                }
              />
            ))}
          </Tabs>{' '}
        </div>
        <div className='candidate-content' style={{ marginTop: '30px' }}>
          <div className='candidate-content-left'>
            {!isEvaluator && (
              <>
                <div ref={(el) => sjdId && (references.current[0] = el)}>
                  <CandidateDetails
                    values={candidate}
                    next={(_value: TadCandidateDetailsModel) => {
                      throw new Error('Function not implemented.');
                    }}
                    candidateSkills={skills}
                    mode='details-page'
                  />
                </div>
                {sjdId && (
                  <div ref={(el) => sjdId && (references.current[4] = el)}>
                    <InitialScreeningRatings
                      candidate={candidate}
                      sjdId={sjdId}
                      reloadCandidate={loadCandidate}
                      alert={alert}
                      setLoading={setLoading}
                    />
                  </div>
                )}
                {sjdId && (
                  <div
                    style={{ width: '100%' }}
                    ref={(el) => sjdId && (references.current[7] = el)}>
                    <CandidateInterviewes
                      sjd={sjd != null ? sjd : {}}
                      candidate={candidate}
                      reloadCandidate={loadCandidate}
                      alert={alert}
                      setLoading={setLoading}
                      isEditPermission={editPermission}
                    />
                  </div>
                )}
                <div
                  style={{ width: '100%' }}
                  ref={(el) => {
                    if (sjdId) {
                      references.current[8] = el;
                    } else {
                      references.current[6] = el;
                    }
                  }}>
                  <CandidateEmployementDetails candidate={candidate} />
                </div>
                <div
                  style={{ width: '100%' }}
                  ref={(el) => {
                    if (sjdId) {
                      references.current[10] = el;
                    } else {
                      references.current[7] = el;
                    }
                  }}>
                  <TadCandidateAttachments
                    candidatedocument={candidate}
                    reloadCandidate={loadCandidate}
                    setLoading={setLoading}
                    alert={alert}
                  />
                </div>
                <div
                  style={{ width: '100%' }}
                  ref={(el) => {
                    if (sjdId) {
                      references.current[11] = el;
                    } else {
                      references.current[8] = el;
                    }
                  }}>
                  <CandidateMatchingJob />
                </div>
                <div
                  style={{ width: '100%' }}
                  ref={(el) => {
                    if (sjdId) {
                      references.current[13] = el;
                    } else {
                      references.current[9] = el;
                    }
                  }}>
                  <CandidateHistory _values={candidate.events} />
                </div>
              </>
            )}
            {candidate?.sjdCandidateInfo?.length > 0 && (
              <div
                style={{ width: '100%' }}
                ref={(el) =>
                  sjdId && isEvaluator
                    ? (references.current[0] = el)
                    : (references.current[14] = el)
                }>
                <CandidateJobDefinition
                  values={{ candidate: candidate, isEvaluator: isEvaluator }}
                />
              </div>
            )}
          </div>
          <div className='candidate-content-right'>
            {!isEvaluator && (
              <>
                <div ref={(el) => (references.current[2] = el)}>
                  <CandidatePrimarySkills
                    candidate={candidate}
                    reloadCandidate={loadCandidate}
                    alert={alert}
                    setLoading={setLoading}
                    isEditPermission={editPermission}
                  />
                </div>
                <div ref={(el) => (references.current[3] = el)}>
                  <AdditionalDetails values={candidate} mode='details-page' />
                </div>
                <div
                  ref={(el) => {
                    if (sjdId) {
                      references.current[6] = el;
                    } else {
                      references.current[5] = el;
                    }
                  }}>
                  <CandidateEducationTable education={candidate.educationDetails} />
                </div>
                <div
                  ref={(el) => {
                    if (sjdId) {
                      references.current[5] = el;
                    } else {
                      references.current[4] = el;
                    }
                  }}>
                  <CandidateCertificateTable certificates={candidate.certificationDetails} />
                </div>
              </>
            )}
            <div ref={(el) => (references.current[1] = el)}>
              <CandidateResume
                handleResumeUpload={async (files) => {
                  setResumeList(files);
                }}
                isNewIndex={candidate.isMigrated}
                resumeIds={candidate?.resumeIds}
                candidate={candidate}
                submit={resumeUpdate}
              />
            </div>
            {candidate.interview?.length > 0 && (
              <div
                ref={(el) =>
                  sjdId && isEvaluator ? (references.current[2] = el) : (references.current[9] = el)
                }>
                <TadInterviewFeedback
                  candidate={candidate}
                  details={existingfeedback}
                  alert={alert}
                  setLoading={setLoading}
                  setSubmitted={(value) => setSubmittedFeedback(value)}
                  submittedFlag={submittedFeedBack}
                />
              </div>
            )}

            {sjdId && !isEvaluator && (
              <div ref={(el) => sjdId && (references.current[12] = el)}>
                <TadCandidateQuestionnaire
                  candidate={candidate}
                  isEditPermission={editPermission}
                />
              </div>
            )}
          </div>
          {/* <div>
          </div> */}
          <TadScreenLoader loading={loading} />
          <Notify
            open={showToast}
            severity={toastMsg.severity}
            onClose={() => setShowToast(false)}
            message={toastMsg.msg}></Notify>
        </div>
        {candidate?.id && (
          <div className='move-to-top'>
            <div className='circle' style={{ backgroundColor: 'var(--Primary-Blue-500, #1976d2)' }}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  references.current[0]?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                  setTimeout(() => (window.location.href = '#root'), 10);
                }}>
                <Tooltip title='Move to top'>
                  <KeyboardArrowUpSharpIcon fontSize='large' />
                </Tooltip>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateDetailsPage;
