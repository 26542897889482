import React, { useState, useRef, useEffect } from 'react';
import { Grid, Button, Paper, Typography, IconButton, Radio } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useNavigate from '../../../../common/useNavigate';
import { scrollToTop } from '../../../common/commonUtil';
import './addcandidate.scss';
import { ReactComponent as CloudUploadIcon } from '../../../assets/candidate-assets/cloud_uploadBlack.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/candidate-assets/insert_drive_file.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/common-assets/warning_amber.svg';
import { ReactComponent as Download } from '../../../assets/candidate-assets/download.svg';
import { ReactComponent as Delete } from '../../../assets/candidate-assets/delete_outline.svg';
import tadLoader from './../../../assets/common-assets/Whole animation.gif';
import {
  tadBulkUploaddelete,
  tadGetResume,
  tadUpdateCandidateResume,
} from '../../../service/tadCandidateService';
import { TadResumeIdModel } from '../../../models/tad-candidate-form-model';
import dateFormat from 'dateformat';
import TadScreenLoader from '../../common/tad-screen-loader';

interface Props {
  selectedOption: any;
  progress: any[];
  handleResumeUpload: (file: File) => void;
  mode?: 'edit-page' | 'details-page';
  handleBulkResumeUpload: (file: File[]) => void;
}

interface ResumeData {
  progress: number;
  timeTaken: number;
  resumeName: string;
  resumeSize: number;
  resumes: any[];
  resumeFile: any;
  isDuplicate: boolean;
  duplicateResumes: any[];
}

const UploadResume = ({
  selectedOption,
  handleResumeUpload,
  handleBulkResumeUpload,
  progress,
}: Props) => {
  const [navigate] = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedProgress, setUploadprogress] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      resumes: [] as File[],
    },
    validationSchema: Yup.object({
      resumes: Yup.array().min(1, 'At least one resume file is required'),
    }),
    onSubmit: (values) => {
      handleBulkResumeUpload(values.resumes);
    },
  });

  useEffect(() => {
    setUploadprogress(progress);
  }, [progress]);

  const handleBulkFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);

      formik.setFieldValue('resumes', fileArray);

      handleBulkResumeUpload(fileArray);
    }
  };

  const onDownloadClick = (resumeId: any, resumeName: any) => {
    tadGetResume(resumeId, true)
      .then((resp) => {
        const base64Content = resp.data;
        const fileName = resp.data.fileName || 'document.pdf';

        try {
          const byteCharacters = atob(base64Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const fileType = fileName?.split('.').pop()?.toLowerCase();
          let mimeType = 'application/octet-stream';

          if (fileType === 'pdf') {
            mimeType = 'application/pdf';
          } else if (fileType === 'doc' || fileType === 'docx') {
            mimeType = 'application/msword';
          }

          const blob = new Blob([byteArray], { type: mimeType });

          const element = document.createElement('a');
          element.href = URL.createObjectURL(blob);
          element.download = resumeName?.replace(/ /g, '_') + '_' + Date.now() + `.${fileType}`;
          document.body.appendChild(element);
          element.click();

          document.body.removeChild(element);
          URL.revokeObjectURL(element.href);
        } catch (error) {
          console.error('Error processing file:', error);
        }
      })
      .catch((error) => {
        console.error('Error fetching resume:', error);
      });
  };

  const handleSelectResume = (resumeId: any, candidateId: any) => {
    if (uploadedProgress[0].isDuplicate === true) {
      const progress = uploadedProgress;
      const updatedProgress = progress.map((progress) => {
        if (progress.duplicateResumes) {
          return {
            ...progress,
            duplicateResumes: progress.duplicateResumes?.map((resume: any) => {
              const isMatchingCandidate = resume.candidateId === candidateId;
              if (isMatchingCandidate) {
                return {
                  ...resume,
                  isActive: resume.resumeId === resumeId ? true : false,
                };
              }
              return resume;
            }),
          };
        }
        return progress;
      });
      setLoading(true);
      tadUpdateCandidateResume(
        updatedProgress
          .flatMap((progress) => progress.duplicateResumes)
          .filter((resume: any) => resume.candidateId === candidateId),
      )
        .then((resp) => {
          const resumes = resp.data.result;
          const updatedProgress = uploadedProgress.map((progress) => {
            if (progress.duplicateResumes) {
              const hasMatchingCandidate = progress.duplicateResumes.some((resume: any) =>
                resumes.some((respResume: any) => respResume.candidateId === resume.candidateId),
              );

              if (hasMatchingCandidate) {
                return {
                  ...progress,
                  duplicateResumes: resumes,
                };
              }
            }
            return progress;
          });
          setUploadprogress(updatedProgress);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setUploadprogress(uploadedProgress);
        });
    }
  };

  const handleNext = () => {
    setSubmitted(true);
    if (formik.values.resumes.length > 0) {
      formik.submitForm();
    } else {
      console.log('No resumes selected. Please upload files.');
    }
  };

  // const processSingleFile = (files: FileList | null): File | null => {
  //   if (!files || files.length === 0) return null;
  //   return files[0];
  // };

  const handleDelete = (resume: any) => {
    setLoading(true);
    tadBulkUploaddelete(resume)
      .then((resp) => {
        console.log(progress);
        const resumes = resp.data.result;
        const updatedProgress = uploadedProgress.map((progress) => {
          if (progress.duplicateResumes) {
            const hasMatchingCandidate = progress.duplicateResumes.some((resume: any) =>
              resumes.some((respResume: any) => respResume.candidateId === resume.candidateId),
            );
            if (hasMatchingCandidate) {
              return {
                ...progress,
                duplicateResumes: resumes,
              };
            }
          }
          return progress;
        });
        setUploadprogress(updatedProgress);
        setLoading(false);
      })
      .catch((_err) => {
        // alert('Something went wrong. Please try again later!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);

      files.forEach((file) => {
        handleResumeUpload(file);
        formik.setFieldValue('resumes', [...formik.values.resumes, file]);
      });
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Paper className='paper' sx={{ width: '100%' }}>
        <div style={{ margin: '3.81rem 2rem 0rem 1.94rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <span className='text'>
              The system will try to auto-fill information from the candidates resume. Please
              carefully review the information for accuracy after this initial parsing. Then, follow
              the next steps to complete the entire process.
            </span>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <label
              htmlFor='file-upload'
              style={{
                cursor: 'pointer',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                // padding: '20px',
                borderRadius: '10px',
              }}>
              <div className='upload' style={{ marginTop: '0px' }}>
                <Grid
                  container
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  gap={2}>
                  {selectedOption === 'Candidate Resume' ? (
                    <div
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()} // Prevent default behavior
                      style={{ cursor: 'pointer', textAlign: 'center' }}>
                      <IconButton
                        component='label'
                        className='file-field'
                        sx={{ fontSize: '3rem' }}>
                        <CloudUploadIcon fontSize='inherit' />
                        <input
                          id='file-upload'
                          type='file'
                          accept='.pdf, .docx, .jpg, .png'
                          style={{ display: 'none' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const files = e.target.files;
                            if (files) {
                              Array.from(files).forEach((file) => {
                                handleResumeUpload(file);
                                formik.setFieldValue('resumes', [...formik.values.resumes, file]);
                              });
                            }
                          }}
                        />
                      </IconButton>
                      <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                        Drop candidate resume here
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{ fontWeight: 'bold', textAlign: 'center', p: 2 }}>
                        Select a candidate resume (.doc, .docx, .pdf, .jpg, & .png)
                      </Typography>
                    </div>
                  ) : (
                    <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                      <IconButton
                        component='label'
                        className='file-field'
                        sx={{ fontSize: '3rem' }}>
                        <CloudUploadIcon fontSize='inherit' />
                        <input
                          id='file-upload'
                          type='file'
                          accept='.pdf, .docx'
                          multiple
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          onChange={handleBulkFileChange}
                        />
                      </IconButton>
                      <Typography variant='body2' style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                        Drop candidate resume here
                      </Typography>
                      <Typography
                        variant='body2'
                        style={{ fontWeight: 'bold', textAlign: 'center', padding: '10px' }}>
                        Select multiple resumes (.doc, .docx, .pdf, .jpg, & .png)
                      </Typography>
                    </div>
                  )}

                  {formik.errors.resumes && submitted && (
                    <Typography variant='body2' color='red'></Typography>
                  )}
                </Grid>
              </div>
            </label>

            <div style={{ padding: '1rem' }}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                Uploaded Resumes
              </Typography>
            </div>
            {uploadedProgress &&
              uploadedProgress?.map((file: ResumeData, index: any) =>
                (file.progress === 100
                  ? file.isDuplicate
                    ? file.duplicateResumes
                    : file.resumes
                  : [file]
                ).map((resume: TadResumeIdModel) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      height: '3rem',
                      border: '1px solid rgb(230, 231, 232)',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '0.5rem',
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          marginLeft: '1rem',
                          borderRadius: '1.5rem',
                          backgroundColor: '#E6E7E8',
                          height: '2.3rem',
                          width: '2.3rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '1rem',
                        }}>
                        <DocumentIcon />
                      </div>
                      <div style={{ gap: '0.3rem', display: 'flex', flexDirection: 'column' }}>
                        <span>{file.resumeName}</span>
                        <span className='feildHeader'>{`${file.resumeSize} KB`}</span>
                      </div>
                    </div>
                    {file.isDuplicate && (
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <ErrorIcon />
                        Already Exists
                      </div>
                    )}
                    <div style={{ marginRight: '20%' }}>
                      {file.progress !== 100 ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={tadLoader}
                            alt='Loading'
                            style={{ width: '6rem', height: '3rem' }}
                          />
                          <span className='feildHeader'>{file.progress}%</span>
                        </div>
                      ) : (
                        <div className='feildHeader'>
                          {resume?.uploadedOn ===
                          file.duplicateResumes.reduce((latest, current) => {
                            return new Date(current?.uploadedOn) > new Date(latest?.uploadedOn)
                              ? current
                              : latest;
                          }, file.duplicateResumes[0])?.uploadedOn
                            ? 'Uploaded now'
                            : `Last uploaded on ${dateFormat(resume?.uploadedOn, 'mm/dd/yyyy')}`}
                        </div>
                      )}
                    </div>
                    {file.progress === 100 && (
                      <div style={{ marginRight: '1.5rem', display: 'flex', gap: '1.5rem' }}>
                        <Radio
                          checked={resume.isActive}
                          onChange={() => handleSelectResume(resume.resumeId, resume.candidateId)}
                          size='small'
                          sx={{
                            padding: '0px',
                            color: 'black !important',
                            flexShrink: 0,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1.5rem',
                            },
                          }}
                        />
                        <IconButton
                          sx={{ padding: '0px' }}
                          onClick={() => onDownloadClick(resume.resumeId, file.resumeName)}>
                          <Download style={{ color: '#323232' }} />
                        </IconButton>
                        <IconButton sx={{ padding: '0px' }} onClick={() => handleDelete(resume)}>
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </div>
                )),
              )}

            <div style={{ marginTop: '0.9rem' }}>
              <div style={{ padding: '1.87rem 1.88rem', borderTop: '1px solid #868686' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <Button
                    className='button'
                    onClick={() => {
                      navigate([
                        {
                          label: 'Candidate',
                          link: '/candidate',
                        },
                      ]);
                      scrollToTop();
                    }}
                    sx={{
                      width: '7.375rem',
                      height: '2.5rem',
                      borderRadius: '5px',
                      border: '1px solid #2D7DFE',
                      backgroundColor: '#FFF',
                      color: 'black',
                      textTransform: 'none',
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: '#2D7DFE',
                        color: 'white',
                      },
                    }}>
                    Cancel
                  </Button>
                  <Button
                    className='button'
                    type='submit'
                    onClick={handleNext}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 400,
                      fontSize: '1rem',
                      width: '7.375rem',
                      height: '2.5rem',
                      color: 'white !important',
                      background: '#2D7DFE !important',
                    }}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Paper>
      <TadScreenLoader loading={loading} />
    </div>
  );
};

export default UploadResume;
