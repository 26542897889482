import { Backdrop, CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './app.scss';
import globalObject from './../common/global-variables';
import { getAllUsers, getUser } from '../service/userService';
import { redirectToLogin } from '../common/commonUtil';
import { getLookupValues } from '../service/lookupService';
import AppHeader from './common/app-header/app-header';
import { getProperties } from '../service/commonService';
import { getPermissionsForUser, getRoleActions } from '../service/RolesAndPermissionsService';
import NewAppHeader from '../tad-revamp-ui/components/common/app-header/new-app-header';
import TadScreenLoader from '../tad-revamp-ui/components/common/tad-screen-loader';

const getLoginUrl = () => {
  return `/login?from=${btoa(window.location.pathname + window.location.search)}`;
};

export default function App() {
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const [propsLoading, setPropsLoading] = useState(true);
  const tadSwitch = localStorage.getItem('switch');

  useEffect(() => {
    tadSwitch == null && localStorage.setItem('switch', 'false');
    const promise = !globalObject.properties
      ? getProperties()
      : new Promise((resolve) => {
          resolve({ data: globalObject.properties });
        });

    promise
      .then((resp: any) => {
        globalObject.properties = resp.data;
        setPropsLoading(false);
        if (token)
          (async () => {
            setLoading(true);
            await Promise.all([
              getUser(),
              getLookupValues('all'),
              getPermissionsForUser(tadSwitch ? tadSwitch : false),
              getAllUsers(),
              getRoleActions(),
            ])
              .then(
                ([userResp, lookupResp, permissionsResponse, allUsersResponse, allRoleActions]) => {
                  globalObject.userObject = userResp.data;
                  globalObject.lookupvalues = lookupResp.data;
                  globalObject.permissions = permissionsResponse.data;
                  globalObject.users = allUsersResponse.data;
                  globalObject.roleActions = allRoleActions.data;
                  setLoading(false);
                },
              )
              .catch(() => {
                setLoading(false);
                redirectToLogin();
              });
          })();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {!propsLoading && (
        <React.Fragment>
          {token && (
            <div
              id='detail'
              style={
                tadSwitch == 'true'
                  ? {
                      background: 'var(--Secondary-colors-Grey-3)',
                      // height: '100vh',
                    }
                  : {}
              }>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {!loading && globalObject.userObject.id && (
                  <>
                    {tadSwitch == 'true' ? <NewAppHeader /> : <AppHeader />}
                    {tadSwitch == 'true' ? (
                      <div
                        style={{
                          maxWidth: '1440px',
                          margin: 'auto',
                        }}>
                        <Box sx={{ padding: [] }}>
                          <Outlet />
                        </Box>
                      </div>
                    ) : (
                      <div>
                        <Box sx={{ padding: [0, 1] }}>
                          <Outlet />
                        </Box>
                      </div>
                    )}
                  </>
                )}
                {loading && tadSwitch != 'true' && (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>
                    <CircularProgress color='inherit' />
                  </Backdrop>
                )}
                {tadSwitch == 'true' && <TadScreenLoader loading={loading} />}
              </LocalizationProvider>
            </div>
          )}
          {!token && <Navigate to={getLoginUrl()} />}
        </React.Fragment>
      )}
    </>
  );
}
