import { Box } from '@mui/material';
import newLoader from './../assets/common-assets/Whole animation.gif';
interface Props {
  width?: string;
}

const TadLoader = ({ width }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey.500',
      }}>
      <img style={{ width: width ? width : '20%', height: '20%' }} src={newLoader} alt='Loading' />
      <span
        style={{
          color: 'var(--Primary-Dark-grey-500,#333)',
          fontFamily: 'Roboto',
          fontSize: 'var(--Font-size-small,0.8125rem)',
          fontStyle: '400',
          fontWeight: '1.5623rem',
          letterSpacing: '0.195rem',
          textAlign: 'center',
        }}>
        LOADING...
      </span>
    </Box>
  );
};

export default TadLoader;
